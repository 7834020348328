import FadeIn from 'react-fade-in';
import ThemedButton from '../../../atom/ThemedButton/themedButton';
import DevicesIcon from '@mui/icons-material/Devices';
import NoResultsFound from '../../../atom/noResultsFound';
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from 'react';
import { updateDevice } from '../../../services/api';
import AddSipDevice from '../devices/modal/addSipDevice';
import Notification from '../../../atom/Notification';
import UserTabs from './userTabs';
import AddSpareDevice from './modal/AddSpareDevice';


function Devices(props: any) {
  const {
    devices,
    getUserDetails,
    activeItem,
    addUserStatus,
    setAddUserStatus,
    endNode,
    setEndNode,
    handleUserUpdates,
    deviceChange,
    setDeviceChange
  } = props
  const { t } = useTranslation();
  const [popup, setPopup] = useState<any>("")
  const [modal, setModal] = useState("")
  const [childStatus, setchildStatus] = useState<any>(false)
  const [status, setStatus] = useState<any>(false)
  const [sipDevices, setSipDevices] = useState<any>([])

  const menuRef: any = useRef();


  const handleOutsideClicks = (event: any) => {
    if (popup === "device" && menuRef.current && !menuRef.current.contains(event.target)) {
      setPopup("");
    };
  };

  useEffect(() => {

    // Bind the event listener
    document.addEventListener("mousedown", handleOutsideClicks);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleOutsideClicks);
    };

  }, [popup]);


  useEffect(() => {
    if (endNode === 'Add New User')
      setStatus(!status)
  }, [endNode])



  const handleDeleteDevice = (uuid: string) => {
    if (activeItem.status === "enabled") {
      updateDevice({ user_id: null }, uuid).then((result: any) => {
        if (result?.data?.parameter) {
          let msg = result?.data?.reason
          Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
        }
        else {
          Notification("Success", "Device Unassigned Successfully ", "success");
          getUserDetails()
          setDeviceChange(!deviceChange)
        }
      }).catch((error: any) => {
        alert(error)
      })
    }
  }
  const handleNewDevice = () => {
    setPopup(popup === "" ? "device" : "")
  }

  useEffect(() => {
    const sipDev = devices?.filter((node: any) => {
      return (node.type === 'sip')
    })
    setSipDevices(sipDev)
  }, [activeItem, devices])

  return (
    <>
      {status ? (
        <UserTabs
          endNode={endNode}
          setAddUserStatus={setAddUserStatus}
          addUserStatus={addUserStatus}
          handleUserUpdates={handleUserUpdates}
        />
      ) : (
        <div className="flex  flex-row h-[calc(100vh-210px)]  my-2 overflow-y-auto">
          <div className="flex justify-center  content-center w-[calc(100vw-256px)] h-full">
            {modal === "deviceSIP" ? (
              <AddSipDevice
                getUserDetails={getUserDetails}
                activeItem={activeItem}
                setModal={setModal}
                setchildStatus={setchildStatus}
                childStatus={childStatus}
                deviceChange={deviceChange}
                setDeviceChange={setDeviceChange}
              />
            ) : modal === "AddSpareDevice" ? (
              <AddSpareDevice
                getUserDetails={getUserDetails}
                setModal={setModal}
                activeItem={activeItem}
                childStatus={childStatus}
                setchildStatus={setchildStatus}
                deviceChange={deviceChange}
                setDeviceChange={setDeviceChange} />
            ) : null}

            <div className=" min-h-[400px] min-w-[800px] max-w-[820px] flex-col  p-10 ">
              <FadeIn>
                <>
                  {sipDevices.length === 0 ? (
                    <div className="text-center relative">
                      <NoResultsFound
                        icon={<DevicesIcon />}
                        keyString={t("Control.Users.SIPAccounts")}
                        click={handleNewDevice}
                        setEndNode={setEndNode}
                        activeItem = {activeItem}
                      />
                      {popup == "device" ? (
                        <div
                          ref={menuRef}
                          className="w-[200px]  bg-secondary  z-10 left-[50%] cursor-pointer  -translate-x-[50%] absolute z-5 rounded-md shadow-lg"
                        >
                          <div
                            className="flex w-[200px] h-[40px] justify-center items-center hover:bg-primary-alpha-20 rounded-md"
                            onClick={() => {
                              setModal("deviceSIP")
                            }}
                          >
                            {t("DashBoard.AddSipDevice")}

                          </div>
                          {/* <div
                            className="flex w-[200px] justify-center items-center h-[40px] hover:bg-primary-alpha-20 rounded-md"
                            onClick={() => {
                              setModal("deviceCell")
                            }}
                          >
                            {t("DashBoard.AddCellDevice")}


                          </div> */}
                          <div
                            className="flex w-[200px] justify-center items-center h-[50px] hover:bg-primary-alpha-20 rounded-md"
                            onClick={() => {
                              setModal("AddSpareDevice")
                            }}
                          >
                            {t("Control.Devices.AddFromSpare")}

                            {/* <span className="w-[150px]  mx-5 my-auto cursor-pointer">
                            </span> */}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className=" max-h-[calc(100vh-210px)] my-2 -mx-2 overflow-y-auto ">
                      <div className="my-3 mx-2">
                        <div className="w-max pb-4">
                          <ThemedButton
                            children={"Add SIP Account"}
                            icon="new"
                            theme="primary"
                            disabled={activeItem?.status === "disabled" ? true : false}
                            click={handleNewDevice}
                          />
                        </div>
                        {popup == "device" ? (
                          <div
                            ref={menuRef}
                            className="w-[200px] bg-secondary z-10 absolute left-50 z-5 rounded-md shadow-lg"
                          >
                            <div
                              className="flex w-[200px] h-[40px] hover:bg-primary-alpha-20 rounded-md"
                              onClick={() => {
                                setModal("deviceSIP")
                              }}
                            >
                              <span className="w-[200px]  mx-5 my-auto cursor-pointer">
                                {t("DashBoard.AddSipDevice")}
                              </span>
                            </div>
                            {/* <div
                              className="flex w-[200px] h-[40px] hover:bg-primary-alpha-20 rounded-md"
                              onClick={() => {
                                setModal("deviceCell")
                              }}
                            >
                              <span className="w-[150px]  mx-5 my-auto cursor-pointer">
                                {t("DashBoard.AddCellDevice")}
                              </span>
                            </div> */}
                            <div
                              className="flex w-[200px] h-[40px] hover:bg-primary-alpha-20 rounded-md"
                              onClick={() => {
                                setModal("AddSpareDevice")
                              }}
                            >
                              <span className="w-[150px]  mx-5 my-auto cursor-pointer">
                                {t("Control.Groups.AddfromSpare")}
                              </span>
                            </div>
                          </div>
                        ) : null}
                        <hr />
                      </div>
                      {sipDevices.map((device: any, index: number) => {
                        return (

                          <div key={device?.uuid} className="inline-block m-2 ">
                            {device.status !== "unassigned" && device.type === "sip" ?
                              <>
                                <div className="inline-block px-6 py-[5px] border border-primary border-r-0 rounded-l">
                                  {
                                    device.name

                                  }

                                </div>


                                <div
                                  role="button"
                                  onClick={() => {
                                    handleDeleteDevice(device?.uuid)
                                  }}
                                  className={activeItem.status === "enabled" ? "inline-block cursor-pointer bg-error text-ternary  rounded-r px-4 py-[6px]" : "inline-block cursor-not-allowed bg-[grey] text-ternary  rounded-r px-4 py-[6px]"}
                                >
                                  {t("Unassign")}
                                </div>
                              </>
                              : null
                            }
                          </div>

                        )
                      })}
                    </div>
                  )}
                </>
              </FadeIn>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Devices