import { combineReducers } from "redux";
import AuthReducer from "./auth.reducer";
import NumberManagerReducer from "./numberManager.reducer";

const reducers = combineReducers({
    Auth: AuthReducer,
    NumberManager : NumberManagerReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;