
import ThemedButton from './ThemedButton/themedButton';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import Lottiefy from './lottie/lottie';
import { useTheme } from "../theme/themeContext";
import * as NoData from '../utils/lottie/noData.json';

interface TypeNoResults {
    icon?: JSX.Element;
    keyString: string;
    click: any;
    setEndNode?: any;
    activeItem?: any
}
function NoResultsFound(props: TypeNoResults) {
    const { keyString, click, icon, setEndNode, activeItem } = props;
    const [themeJSON, setTheme] = useState<any>({});
    const { theme, getCurrentThemeJSON } = useTheme();
    const [json, setJson] = useState<any>('')
    
    const { t } = useTranslation();
    function hexToRgb(hex: any) {
        console.log('themeJSON', themeJSON.primary)
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `[${parseInt(result[1], 16) / 255}, ${parseInt(result[2], 16) / 255}, ${parseInt(result[3], 16) / 255}, 1]` : "[1,0,0,1]";
    }
    useEffect(() => {
        getCurrentThemeJSON(theme, setTheme);
       
    }, [theme]);
    useEffect(()=>{
        if(themeJSON?.primary){
            let color = hexToRgb(themeJSON.primary)
            let data:any = JSON.stringify(NoData)
            data = data.replace("[0.2980392156862745,0.49019607843137253,0.8392156862745098,1]", color)
            data = data.replace("[0.3764705882352941,0.596078431372549,1,1]", color)
            setJson(JSON.parse(data))
        }
    },[themeJSON])
    return (
        <div >
            <div className='text-primary'>
                {icon ? icon : json !== '' ? <Lottiefy loop={true} json={json} height={100} width={100} /> : ''}
            </div>
            <h3 className="text-xl p-3">{t('No')} {keyString}</h3>
            <h4 className="text-sm pb-4 text-[gray]">{t('Getstartedbycreatinganew')} {keyString}.</h4>
            <ThemedButton click={click} disabled={activeItem?.status === "disabled" ? true : false} children={t('AddNew_', { key: keyString })} icon="new" theme="primary" />
        </div>
    )
}

export default NoResultsFound

