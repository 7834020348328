import { useState } from 'react'
import ThemedButton from '../../../atom/ThemedButton/themedButton'
import { t } from 'i18next'
import Modal from '../../../atom/modal'
import AddCardIcon from "@mui/icons-material/AddCard"
import ComponentMap from '../../../atom/componentmap'

const BuyCredit = (props  : any) => {
       const { setModal } = props
    const [data, setData] = useState<any>([
      {
        title: t("DashBoard.Amount"),
        name: t("DashBoard.Amount"),
        layout: 4,
        value: "",
        required: true,
        errormessage: "Amount is required",
      },
    ])
  return (
    <div>
      <Modal
        icon={<AddCardIcon />}
        title={t("DashBoard.addCredit")}
        closeEvent={() => {
          setModal("")
        }}
      >
        <div className="w-[500px]">
          <ComponentMap data={data} setData={setData} />
          <div className="text-right ">
            <ThemedButton
              children={"Cancel"}
              icon="cancel"
              theme="secondary"
              click={() => setModal("")}
            />
            <ThemedButton
              children={"Buy"}
              icon="update"
              theme="primary"
              click={() => setModal("")}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BuyCredit