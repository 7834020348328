import { useState } from 'react'
import ComponentMap from '../../../atom/componentmap'
import Modal from '../../../atom/modal'
import BlockIcon from "@mui/icons-material/Block"
import ThemedButton from '../../../atom/ThemedButton/themedButton'

const BlockNumber = (props: any) => {
    const { setModal } = props
    const [data, setData] = useState([
      {
        title: "Block Number",
        name: "Block",
        layout: 1,
        value: "",
        required: true,
        errormessage: "Number is required",
      },
    ])
  return (
    <div>
      <Modal
        icon={<BlockIcon />}
        title={"Block Number"}
        closeEvent={() => {
          setModal("")
        }}
      >
        <ComponentMap data={data} setData={setData} />
        <div className="text-right ">
          <ThemedButton
            children={"Cancel"}
            icon="cancel"
            theme="secondary"
            click={() => setModal("")}
          />
          <ThemedButton
            children={"Block"}
            icon="update"
            theme="primary"
            click={() => setModal("")}
          />
        </div>
      </Modal>
    </div>
  )
}

export default BlockNumber