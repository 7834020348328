export let darkTheme:any = {
    primary: "#425778",
    primaryAlpha5: "",
    primaryAlpha10: "",
    primaryAlpha20: "",
    primaryAlpha30: "",
    primaryAlpha40: "",
    primaryAlpha50: "",
    primaryAlpha60: "",
    primaryAlpha80: "",
    primaryText: "#ffffff",
    secondary: "#333333",
    secondaryText: "#ffffff",
    ternary: '#222222',
    ternaryText: "#aaaaaa",
    hover: "rgba(255,255,255,0.1)",
    error: "rgba(255,0,0,1)",
    tableHeader: "",
    errorAlpha40: "rgba(255,0,0,0.4)",
    disabled: "rgba(0,0,0,0.7)",
};
export function updateKey(key: any, value: any) {
    darkTheme[key] = value
  }
  