import { useEffect, useState } from "react";
import GroupIcon from "@mui/icons-material/Group";
import DialpadIcon from "@mui/icons-material/Dialpad";
import TtyIcon from "@mui/icons-material/Tty";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DevicesIcon from "@mui/icons-material/Devices";
import NumbersIcon from "@mui/icons-material/Numbers";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AddCardIcon from "@mui/icons-material/AddCard";
import AddCellDevice from "../controlManager/devices/modal/addCellDevice";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import BuyCredit from "./Modals/buyCredit";
import AddCallerId from "./Modals/addCallerId";
import BlockNumber from "./Modals/blockNumber";
import HoldMusic from "./Modals/holdMusic";
import { useTheme } from "../../theme/themeContext";
import DoughnutChart from "../../atom/Charts/doughnutChart";
import BarGraph from "../../atom/Charts/BarGraph";
import BrudCrubs from "../../layout/layout1/components/brudcrubs/brudcrubs";
import UserTabs from "../controlManager/users/userTabs";
import Modal from "../../atom/modal";
import AddSipDevice from "../controlManager/devices/modal/addSipDevice";
import AvailableNumbers from "../numberManager/Modal/AssignModal/availableNumbers";
import { useTranslation } from "react-i18next";

const DashBoard = () => {
  const { t } = useTranslation()

  const [Popup, setPopup] = useState<any>("");
  const { theme, getCurrentThemeJSON } = useTheme();
  const [themeJSON, setTheme] = useState<any>({});
  const [modal, setModal] = useState<any>("");
  const telcoData = [
    {
      did: "Caller ID",
      icon: <GroupIcon />,
      data: "1/6",
    },
    {
      did: "Music",
      icon: <DevicesIcon />,
      data: "0/2",
    },
    {
      did: "NUMBERS",
      icon: <NumbersIcon />,
      data: "2/4",
    },
    {
      did: "CREDIT",
      icon: <NumbersIcon />,
      data: "$99",
    },
    {
      did: "CREDIT",
      icon: <NumbersIcon />,
      data: "$99",
    },
    {
      did: "CREDIT",
      icon: <NumbersIcon />,
      data: "$99",
    },
    {
      did: "CREDIT",
      icon: <NumbersIcon />,
      data: "$99",
    },
    {
      did: "CREDIT",
      icon: <NumbersIcon />,
      data: "$99",
    },
  ];
  const [search, setSearch] = useState<string>("");

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    getCurrentThemeJSON(theme === "light" ? "dark" : "light", setTheme);
  }, []);

  return (
    <div
      className="flex flex-col"
      onClick={() => (Popup !== "" ? setPopup("") : null)}
    >
      <div className="p-0 flex h-max justify-between border-b-[1px] bg-primary-alpha-10 border-primary-alpha-30 ">
        <BrudCrubs endNode="" />
      </div>
      {modal === "user" ? (
        <div>
          <Modal
            icon={<GroupIcon />}
            title={t("DashBoard.Addusers")}
            closeEvent={() => {
              setModal("");
            }}
          >
            <div className="w-[800px]">
              <UserTabs dashboard={true} setModal={setModal} />
            </div>
          </Modal>
        </div>
      ) : modal === "deviceSIP" ? (
        <AddSipDevice setModal={setModal} />
      ) : modal === "deviceCell" ? (
        <AddCellDevice setModal={setModal} />
      ) : modal === "number" ? (
        <div>
          <Modal
            icon={<GroupIcon />}
            title={t("DashBoard.BuyNumbers")}
            closeEvent={() => {
              setModal("");
            }}
          >
            <div className="h-[400px] w-[600px]">
              <AvailableNumbers dashboard={true} setDashboardModal={setModal} />
            </div>
          </Modal>
        </div>
      ) : modal === "credit" ? (
        <BuyCredit setModal={setModal} />
      ) : modal === "callerId" ? (
        <AddCallerId setModal={setModal} />
      ) : modal === "blockNumber" ? (
        <BlockNumber setModal={setModal} />
      ) : modal === "holdMusic" ? (
        <HoldMusic setModal={setModal} />
      ) : null}
      <section className="grid md:grid-cols-2 xl:grid-cols-4 gap-1 mx-2 my-1">
        <div className="flex items-center p-8 bg-primary-alpha-10 rounded-md h-[150px]">
          <div className="inline-flex flex-shrink-0 items-center justify-center h-12 w-12 text-[#6a0c6a] bg-[#9d869d] rounded-full mr-6 relative ml-5">
            <GroupIcon />
          </div>
          <div>
            <div className="h-[100px] absolute mt-[-50px] ml-[-98px]">
              <DoughnutChart
                labels={["Active Users", "Total Users"]}
                data={[15, 17]}
                label={"USERS"}
                color={[themeJSON?.primary, "rgba(152,152,152,0.5"]}
              />
            </div>
          </div>
          <div className="ml-[10%]">
            <span className="block text-2xl font-bold">
              {t("DashBoard.Users")}
            </span>
            <span className="block text-gray-500">1 | 6</span>
          </div>
          <div className="ml-auto  relative">
            <div
              className=" hover:bg-[#dedede] p-3 rounded-3xl z-30"
              onClick={(event) => {
                event.stopPropagation();
                setModal("user");
              }}
            >
              <PersonAddAlt1Icon />
            </div>
          </div>
        </div>
        <div className="flex items-center p-8 bg-primary-alpha-10 rounded-md h-[150px]">
          <div className="inline-flex flex-shrink-0 items-center justify-center h-12 w-12 text-[#246e24] bg-[#899d89] rounded-full mr-6 relative ml-5">
            <TtyIcon />
          </div>
          <div>
            <div className="h-[100px] absolute mt-[-50px] ml-[-98px]">
              <DoughnutChart
                labels={["Devices In Use", "Total Devices"]}
                data={[4, 17]}
                label={t("DashBoard.Devices")}
                color={[themeJSON?.primary, "rgba(152,152,152,0.5"]}
              />
            </div>
          </div>
          <div className="ml-[10%]">
            <span className="block text-2xl font-bold">
              {t("DashBoard.Devices")}
            </span>
            <span className="block text-gray-500">1 | 6</span>
          </div>
          <div className="ml-auto  relative">
            <div
              className=" hover:bg-[#dedede] p-3 rounded-3xl z-30"
              onClick={(event) => {
                event.stopPropagation();
                Popup == "device" ? setPopup("") : setPopup("device");
              }}
            >
              <LibraryAddIcon />
            </div>
            {Popup === "device" ? (
              <div className="w-[200px] bg-ternary text-ternary-text absolute right-2 top-10 z-20 rounded-md shadow-lg">
                <div
                  className="flex w-[200px] h-[40px] hover:bg-primary-alpha-20 rounded-md"
                  onClick={() => {
                    setModal("deviceSIP");
                  }}
                >
                  <span className="w-[200px]  mx-5 my-auto">
                    {t("DashBoard.AddSipDevice")}
                  </span>
                </div>
                <div
                  className="flex w-[200px] h-[40px] hover:bg-primary-alpha-20 rounded-md"
                  onClick={() => {
                    setModal("deviceCell");
                  }}
                >
                  <span className="w-[150px]  mx-5 my-auto">
                    {t("DashBoard.AddCellDevice")}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex items-center p-8 bg-primary-alpha-10 rounded-md h-[150px]">
          <div className="inline-flex flex-shrink-0 items-center justify-center h-12 w-12 text-[#8d3f3f] bg-[#c89999] rounded-full mr-6  relative ml-5">
            <DialpadIcon />
          </div>
          <div>
            <div className="h-[100px] absolute mt-[-50px] ml-[-98px]">
              <DoughnutChart
                labels={["Active Numbers", "Total Numbers"]}
                data={[28, 37]}
                label={"Numbers"}
                color={[themeJSON?.primary, "rgba(152,152,152,0.5"]}
              />
            </div>
          </div>
          <div className="ml-[10%]">
            <span className="inline-block text-xl text-gray-500 font-semibold">
              {t("DashBoard.Numbers")}
            </span>
            <span className="block text-gray-500">1 | 6</span>
          </div>
          <div className="ml-auto  relative">
            <div
              className=" hover:bg-[#dedede] p-3 rounded-3xl z-30"
              onClick={(event) => {
                event.stopPropagation();
                setModal("number");
              }}
            >
              <AddIcCallIcon />
            </div>
          </div>
        </div>
        <div className="flex items-center p-8 bg-primary-alpha-10 rounded-md h-[150px]">
          <div className="inline-flex flex-shrink-0 items-center justify-center h-12 w-12 text-[#6161fd] bg-[#c9c9e8] rounded-full mr-6 relative ml-5">
            <CreditCardIcon />
          </div>
          <div>
            <div className="h-[100px] absolute mt-[-50px] ml-[-98px]">
              <DoughnutChart
                labels={["Credit available", "Credit Limit"]}
                data={[45, 47]}
                label={"Credit"}
                color={[themeJSON?.primary, "rgba(152,152,152,0.5"]}
              />
            </div>
          </div>
          <div className="ml-[10%]">
            <span className="block text-2xl font-bold">
              {t("DashBoard.Credits")}
            </span>
            <span className="block text-gray-500">$369</span>
          </div>
          <div className="ml-auto  relative">
            <div
              className=" hover:bg-[#dedede] p-3 rounded-3xl z-30"
              onClick={(event) => {
                event.stopPropagation();
                setModal("credit");
              }}
            >
              <AddCardIcon />
            </div>
          </div>
        </div>
      </section>
      <div className="grid grid-cols-3 gap-1 mx-2 rounded-md ">
        <div className="flex flex-col overflow-hidden rounded-md  bg-primary-alpha-10 h-[calc(100vh-410px)]  ">
          <div className="flex px-6 py-3 font-semibold border-b border-b-primary-alpha-30 bg-primary-alpha-20 rounded-t-md items-center justify-center">
            <span className="my-auto">{t("DashBoard.TotalCalls")}</span>
            <span className="text-2xl mx-10 my-auto">0</span>
          </div>
          <div className="h-[calc(100vh-540px)] mt-6">
            <BarGraph
              labels={[
                t("DashBoard.IncomingCalls"),
                t("DashBoard.OutgoingCalls"),
                t("DashBoard.MissedCalls"),
              ]}
              data={[10, 20, 26]}
              label={"CALLS"}
              color={[
                themeJSON?.primary,
                themeJSON?.primary,
                themeJSON?.primary,
              ]}
              border={0}
            />
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-md bg-primary-alpha-10 h-[calc(100vh-410px)] ">
          <div className="flex px-6 py-[5px] font-semibold border-b border-b-primary-alpha-30 bg-primary-alpha-20 justify-between rounded-t-md">
            <div className="my-auto">{t("DashBoard.Numbers")}</div>
            <div className="relative flex w-[60%]  flex-wrap items-stretch">
              <input
                type="text"
                onChange={(e) => handleSearchChange(e.target.value)}
                value={search}
                className="relative m-0 block w-[1px] min-w-0 flex-auto rounded rounded-r-none bg-hover bg-clip-padding px-3 py-[6px] my-[4px] leading-[1.6]  outline-none transition duration-200 ease-in-out focus:z-[3]"
                placeholder={t("Number.Search")}
              />
              {search === "" ? (
                <span className="input-group-text flex items-center  transition duration-200  whitespace-nowrap rounded rounded-l-none px-2 py-0 text-center  text-base bg-hover font-normal text-secondary my-[4px]">
                  <SearchIcon />
                </span>
              ) : (
                <span
                  onClick={() => setSearch("")}
                  className="input-group-text flex items-center cursor-pointer transition duration-200  whitespace-nowrap rounded rounded-l-none px-2 py-0 text-center  text-base bg-hover font-normal text-primary my-[4px]"
                >
                  <CloseIcon />
                </span>
              )}
            </div>
          </div>
          <div className="table-fix-head  max-w-[100vw-600px] h-[calc(100vh-466px)] rounded-b-lg  shadow ">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
              <thead className="text-xs text-ternary-text bg-hover rounded ">
                <tr>
                  <th scope="col" className="px-6 py-4 text-[15px]">
                    {t("DashBoard.Number")}
                  </th>
                  <th scope="col" className="px-6 py-4 text-[15px]">
                    {t("DashBoard.Who")}
                  </th>
                  <th scope="col" className="px-6 py-4 text-[15px]">
                    {t("DashBoard.Where")}
                  </th>
                </tr>
              </thead>
              <tbody className="overflow-scroll">
                {telcoData.map((node: any) => {
                  return (
                    <tr className="border-b border-hover cursor-pointer hover:bg-primary-alpha-20">
                      <td className="px-6 py-4 w-[50%]">{node.did}</td>
                      <td className="px-6 py-4 w-[50%]">{node.did}</td>
                      <td className="px-6 py-4 w-[50%]">{node.did}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-md bg-primary-alpha-10 h-[calc(100vh-410px)] ">
          <div className="px-6 py-4 font-semibold border-b border-b-primary-alpha-30 bg-primary-alpha-20">
            {t("DashBoard.ActivityLog")}
          </div>
        </div>
      </div>
      <section className="grid md:grid-cols-2 xl:grid-cols-3 gap-1 mx-2  my-1">
        <div className="flex items-center p-10 bg-primary-alpha-20 rounded-lg col-span-1 h-[130px] justify-between">
          <div>
            <span className="block text-gray-500 text-2xl">
              {t("DashBoard.CallCharges")}
            </span>
          </div>
          <div className="inline-flex flex-shrink-0 items-center justify-center text-3xl rounded-full mr-6">
            57
          </div>
        </div>
        <div className="flex items-center p-8 bg-primary-alpha-20 rounded-lg col-span-1 justify-between">
          <div>
            <span className="block text-gray-500 text-2xl">
              {t("DashBoard.MinutesUsed")}
            </span>
          </div>
          <div className="inline-flex flex-shrink-0 items-center justify-center text-3xl rounded-full mr-6">
            18
          </div>
        </div>
        <div className="flex items-center p-8 bg-primary-alpha-20  rounded-lg col-span-1 justify-between">
          <div>
            <span className="block text-gray-500 text-2xl">
              {t("DashBoard.Numbers")}
            </span>
          </div>
          <div className="inline-flex flex-shrink-0 items-center text-3xl justify-center rounded-full mr-6">
            0.000
          </div>
        </div>
      </section>
    </div>
  );
};

export default DashBoard;
