const ServicePlanConfig = {
    defaults: {
        navbar: {
            display: true,
            folded: true,
            position: 'left',
        },
        toolbar: {
            display: true,
            style: 'fixed',
        },
        footer: {
            display: false,
            style: 'fixed',
        },
        leftSidePanel: {
            display: false,
        },
        rightSidePanel: {
            display: false,
        },
    }
}
export default ServicePlanConfig