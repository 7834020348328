/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Menu, Transition } from "@headlessui/react";
import { useTheme } from "../../../../theme/themeContext";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import { useApp } from "../../../../appContext";
import { DashboardItems } from "../../../../config/dashboard"
import VoifinityBlack from "../../../../utils/icons/voifinity.black.svg"
import VoifinityWhite from "../../../../utils/icons/voifinity.white.svg"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";
import TenantTree from "./TenantTree";
import { useDispatch } from 'react-redux'
import { actionCreators } from '../../../../store'
import { getRoles, getUserSettings, updateUserSettings } from "../../../../services/api";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}
const ToolBar = () => {
  const { theme, toggleTheme } = useTheme();
  const appContext = useApp();
  const navigation = useLocation();
  const { routes } = appContext;
  const [currentLanguage, setCurrentLanguage] = useState<any>({
    name: "English",
    key: "en"
  });
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [activeAccount, setActiveAccount] = useState('');
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [user, setUser] = useState<any>({});
  const languages = [{
    name: "English",
    key: "en"
  }, {
    name: "French",
    key: "fr"
  }, {
    name: "Spanish",
    key: "sp"
  }]





  const changeTheme = () => {
    const currentPathConfiguration = routes.find((route: any) => route.path === '/' + navigation.pathname.split('/')[2]);
    let parent = currentPathConfiguration?.path.split('/')[1]
    let Board = DashboardItems.findIndex((node: any) => {
      return node.item === parent
    })
    const primary = DashboardItems[Board]?.primary || ''
    if (primary) {
      toggleTheme(theme === "light" ? "dark" : "light", () => { }, primary);

    } else {
      toggleTheme(theme === "light" ? "dark" : "light", () => { });

    }
    editUserPreferences("theme")
  };

  const handleUserPreferences = () => {
    if (keycloak?.token) {
      const token: any = jwtDecode(keycloak?.token);
      if (token?.sub) {
        getUserSettings(token?.sub).then((result: any) => {
          const currentlanguage = languages?.filter((node) => node?.name?.toLowerCase().trim() === result?.language?.toLowerCase().trim())[0]
          i18n.changeLanguage(currentlanguage.key);
          setCurrentLanguage(currentlanguage);
          toggleTheme(result?.theme ?? "light", () => { })
        }).catch((error) => {
          console.log("error", error)
        })
      }
    }
  }


  const editUserPreferences = (args: { name: string; key: string } | "theme") => {
    if (keycloak?.token) {
      const token: any = jwtDecode(keycloak?.token);
      if (token.sub) {
        let props;
        if (typeof args === "object") {
          props = { language: args?.name, theme: theme }
        } else {
          props = { language: currentLanguage?.name, theme: theme === "light" ? "dark" : "light" }
        }

        updateUserSettings(token?.sub, props).then((result: any) => {
          console.log("updatedUserSettings ", result)
        }).catch((error) => {
          console.log("error", error)
        })

      }
    }

  }




  const navigateTo = (nav: string) => {
    navigate(`/${nav}`, { replace: true });
  };
  const handleSwitchLanguage = (language: any) => {
    i18n.changeLanguage(language.key);
    setCurrentLanguage(language);
    editUserPreferences(language)
  };
  const handleRoles = (userRoles: any) => {
    console.log("suerroles", userRoles)
    setTimeout(() => {
      let payload: any = {}
      if (userRoles.length === 1) {
        payload = { uuid: userRoles[0].uuid ? userRoles[0].uuid : userRoles[0] }
      }
      getRoles(payload, { skipHeader: false }).then((result) => {
        if (result.length > 0) {
          // alert("jjjj")

          dispatch(actionCreators.setUserRoles(result))
        } else {
          dispatch(actionCreators.setUserRoles(false))
          console.error("ERROR: No user roles Found")
        }
      })
    }, 200);
  }
  const handleLogOut = async () => {
    window.localStorage.removeItem("currentAccount");
    await keycloak.logout()
    navigate(`/app`, { replace: false });
  }
  useEffect(() => {
    if (keycloak?.token) {
      const token: any = jwtDecode(keycloak?.token);
      setUser(token);
      const currentAccount: any = window.localStorage.getItem("currentAccount");
      console.log("currentAccount", token)
      const token_display_name = token?.account_type === "super_telco" ? token.azp : token?.azp ? token?.azp : token?.name;
      if (currentAccount && !token?.realm_settings?.roles) {
        const data: any = JSON.parse(currentAccount)
        console.log("dataname", data)
        let acc = data.account_type ? data.account_type : data.type
        const display_name = acc === "super_telco" ? data.azp : data?.azp ? data?.azp : data?.name;
        setActiveAccount(display_name)
        acc = acc === "super_telco" ? "super" : acc ? acc : 'tenant';
        let payload = { name: `${acc}_admin` }
        if (data?.email === "voifinity@netstratum.com") {
          payload = { name: `super_admin` }
          setActiveAccount(display_name)
        }
        setTimeout(() => {
          getRoles(payload).then((result) => {
            // alert("ijij")
            dispatch(actionCreators.setUserRoles(result))
          })
        }, (200));
      } else if (token?.email === "voifinity@netstratum.com") {
        let payload = { name: `super_admin` }
        setActiveAccount(token_display_name)
        setTimeout(() => {
          getRoles(payload).then((result) => {
            dispatch(actionCreators.setUserRoles(result))
          })
        }, (200));
      } else if (token?.realm_settings?.roles) {
        setActiveAccount(token_display_name)
        // alert("lom")
        handleRoles(token.realm_settings.roles)
      } else {
        setActiveAccount(token_display_name)
        dispatch(actionCreators.setUserRoles(false))
        console.error("ERROR: No user roles Found")
      }

    }
    setTimeout(() => {
      handleUserPreferences()
    }, 200);
  }, [keycloak]);


  const initClientToken = () => {
    if (keycloak?.token) {
      const token: any = jwtDecode(keycloak?.token);
      const currentAccount: any = window.localStorage.getItem("currentAccount");

      if (!currentAccount) {
        localStorage.setItem("currentAccount", JSON.stringify(token));
      }
    }
  }




  useEffect(() => {
    initClientToken()


  }, [])
  return (
    <div>
      <div className="bg-primary-alpha-50 dark:bg-ternary text-ternary-text flex  h-[60px] transition-colors border-b border-b-primary-alpha-60">
        <div className="inline-block  w-64 justify-center items-center text-center ">
          {theme === 'light' ? <img onClick={() => navigateTo("app/home")} src={VoifinityBlack} alt="icon" className="h-[48px] mx-auto m-[6px] cursor-pointer " />
            : <img onClick={() => navigateTo("app/home")} src={VoifinityWhite} alt="icon" className="h-[48px] mx-auto m-[6px] cursor-pointer " />}
        </div>
        <div className="p-2 flex justify-between w-[calc(100%-256px)]">
          <div></div>
          <Menu as="div" className="relative text-left flex flex-row ">
            <button
              type="button"
              className="inline-block capitalize rounded border-r border-r-hover px-6 pb-2 pt-1 mr-4 leading-normal transition duration-150 ease-in-out hover:text-primary"
              onClick={() => user.email === 'voifinity@netstratum.com' && setShowModal(true)}>
              {activeAccount}
            </button>
            <div>
              <Menu.Button className="inline-flex w-full capitalize justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 ttext-gray-900 hover:bg-gray-50">
                {user?.name}
                <KeyboardArrowDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95" >
              <Menu.Items className="absolute bg-ternary right-0 z-[10000] mt-[54px] w-56 origin-top-right rounded-[3px] bg-white shadow-lg  focus:outline-none">
                <div className="py-1">
                  <div
                    className={classNames(
                      "text-gray-700 flex justify-between cursor-pointer  px-4 py-2 text-sm hover:bg-hover"
                    )}>
                    {t("Theme")}
                    <div className="flex justify-center items-center">
                      <span className="">
                        <svg
                          className="h-4 w-4 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                          />
                        </svg>
                      </span>
                      <div onClick={() => { changeTheme() }} className="w-8 h-4 flex items-center bg-hover rounded-full mx-3 px-1 bg-blue-700">
                        <div className={`bg-ternary-text w-3 h-3 rounded-full shadow-md transform transition-transform ${theme === "light"
                          ? "translate-x-0"
                          : "translate-x-3"
                          }`}>

                        </div>
                      </div>
                      <span className="">
                        <svg className="h-4 w-4 text-gray-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "text-gray-700 flex justify-between cursor-pointer  px-4 py-2 text-sm hover:bg-hover"
                    )}
                  >
                    {t("Language")}
                    <Menu as="div" className="relative inline-block text-left ">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center  rounded-md bg-white text-gray-900 hover:bg-gray-50">
                          {currentLanguage.name}
                          <KeyboardArrowDownIcon
                            className="-mr-1 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute bg-ternary right-0 z-[10000]  w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 ">
                          <div className="py-1">
                            {languages.map((language: any, index: number) => {
                              return <Menu.Item key={index}>
                                <div
                                  onClick={() => handleSwitchLanguage(language)}
                                  className={classNames(
                                    currentLanguage.key === language.key
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700 focus:outline-none hover:bg-hover",
                                    "px-4 py-2 flex justify-between text-sm"
                                  )}
                                >
                                  {language.name}
                                  {currentLanguage.key === language.key && (
                                    <span className="mt-[6px] h-2 w-2 rounded-full bg-[green] " />
                                  )}
                                </div>
                              </Menu.Item>
                            })}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <hr />
                  <Menu.Item>
                    <div
                      onClick={() => handleLogOut()}
                      className={classNames(
                        "text-gray-700 cursor-pointer block px-4 py-2 text-sm hover:bg-hover"
                      )}
                    >
                      {t("SignOut")}
                    </div>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {showModal && <TenantTree user={user} activeAccount={activeAccount} setActiveAccount={setActiveAccount} setShowModal={setShowModal} />}
    </div>
  );
};
export default ToolBar;
