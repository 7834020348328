const transalation = {
  AddressBook: "Carnet d'adresses",
  CarrierManager: "Gestionnaire de transporteur",
  ControlManager: "Gestionnaire de contrôle",
  Dashboard: "Tableau de bord",
  Receptionist: "Réceptionniste",
  SystemManager: "Gestionnaire de système",
  AccountManager: "Gestionnaire de compte",
  RatesManager: "Gestionnaire des tarifs",
  NumberManager: "Gestionnaire de numéros",
  Branding: "l'image de marque",
  Users: "Utilisateurs",
  Groups: "Groupes",
  MainNumber: "Nombres",
  CallLogs: "Journaux d'appels",
  Devices: "Devices",
  VoiceMail: "Messagerie vocale",
  IVR: "RVI",
  NotificationManager: "Administrador de notificaciones",
  Settings: "Paramètres",
  Theme: "Thème",
  Language: "Langue",
  SignOut: "Se déconnecter",
  CompanyName: "Nom de l'entreprise",
  AddressLine1: "Adresse 1",
  AddressLine2: "Adresse 2",
  EMail: "Correo electrónico",
  Cancel: "Annuler",
  Save: "Sauvegarder",
  Ok: "d'accord",
  AddNew_: "Ajouter nouveau {{key}}",
  No_Found: "Non {{key}}",
  AccountName: "Nombre de la cuenta",
  Enable: "Activer",
  Disable: "Désactiver",
  Actions: "Actions",
  Invoices: "Factures",
  AssignNumber: "Attribuer un numéro",
  AddCredit: "Ajouter un crédit",
  RemoveUser: "Supprimer l'utilisateur",
  AddNewExtension: "Ajouter une nouvelle extension",
  Unassign: "Désattribuer",
  Getstartedbycreatinganew: "Comienza creando una nueva",
  PhoneNumbers: "Les numéros de téléphone",
  No: "Non",
  Label: "Étiqueter",
  search: "recherche",
  Update: "Mise à jour",
  Assign: "Attribuer",
  Date: "Date",
  Time: "Temps",
  From: "Depuis",
  To: "Pour",
  Duration: "Durée",
  Search: "Recherche",
  Numbers: "Nombre",
  UpdatedSuccessfully: "{{key}} Updated Successfully",
  SomethingWentWrong: "Une erreur s'est produite. Veuillez réessayer",
  Remove: "Retirer",
  Name: "Nom",
  Email: "E-mail",
  Route1: "Itinéraire 1*",
  Route2: "Itinéraire 2*",

  Control: {
    SomethingWentWrongPleaseTryAgain:
      "Une erreur s'est produite. Veuillez réessayer",

    Users: {
      FirstName: "Prénom",
      LastName: "Nom de famille",
      UserRole: "Rôles d'utilisateur",
      MainExtensionNumber: "Numéro de poste principal",
      Numbers: "Nombre",
      PhoneNumbers: "Les numéros de téléphone",
      AutoProvision: "Provisionnement automatique",
      Devices: "Dispositifs",
      SIPAccounts: "Comptes SIP",
      Features: "Caractéristiques",
      AddToCompanyDirectory: "Ajouter au répertoire de l'entreprise",
      CallerIDNumber: "Numéro d'identification de l'appelant",
      CallForwarding: "Renvoi d'appel",
      HotDesking: "Bureau partagé",
      Voicemails: "Messages vocaux",
      MusicOnHold: "Musique en attente",
      FindMeFollowMe: "Trouve-moi, suis-moi",
      FaxBox: "Boîte de télécopie",
      MissedCallAlert: "Alerte d'appel manqué",
      LocalDialing: "Numérotation locale",
      SelectCallerId: "Sélectionnez l'identification de l'appelant",
      ActiveFeatures: "Fonctionnalités actives",
      Password: "Mot de passe",
      AddNewDevice: "Ajouter un nouvel appareil",
      Email: "E-mail",
      PleaseAssignNumberFirst: "Veuillez d'abord attribuer un numéro",
      AddToCountryDirectory: "Ajouter au répertoire de l'entreprise",
      CreateDeviceAutomatically: "Créer un appareil automatiquement",
      ExtensionAddedSuccessfully: "Extension ajoutée avec succès",
      ErrorWhileAddingExtensionPleaseTryAgain:
        "Erreur lors de l'ajout de l'extension, veuillez réessayer",
      ExtensionAlreadyExist: "L'extension existe déjà",
      ExtensionUpdated: "Extension mise à jour",
      ExtensionRemovedSuccessfully: "Extension supprimée avec succès",
      ErrorWhileRemovingExtensionPleaseTryAgain:
        "Erreur lors de la suppression de l'extension, veuillez réessayer",
      ExtensionsMustContainExactlyDigits:
        "Les extensions doivent contenir exactement 4 chiffres.",
      InvalidFormat: "Format invalide",
    },
    Groups: {
      Members: "Membres",
      Extensions: "Rallonges",
      PhoneNumbers: "Les numéros de téléphone",
      Features: "Caractéristiques",
      RingBack: "Rappeler",
      NextCall: "Prochain appel",
      CallerIDPrepend: "Préfixe de l'ID de l'appelant",
      PleaseSelectCallerId: "Veuillez sélectionner l'identifiant de l'appelant",
      AddNewGroup: "Ajouter un nouveau groupe",
      Distribute: "Distribuer",
      ManageUser: "Gérer l'utilisateur",
      Group: "Groupe",
      GroupName: "Nom de groupe",
      Unassign: "Désattribuer",
      AddfromSpare: "Ajouter à partir de la réserve",
      Spare: "De rechange",
      RemoveGroup: "Supprimer le groupe",
      Search: "Recherche",
      MembersUpdatedMsg: "Les membres ont mis à jour avec succès",
      NumberAssignedMsg: "Numéro attribué avec succès",
      GroupCreatedMsg: "Groupe créé avec succès",
      FourDigitErrorMsg: "Extensions à quatre chiffres seulement",
      NameError: "Veuillez saisir le nom",
      ExtensionNumber: "Numéro de poste",
      InvalidExtension: "Extension non valide",
      Users: "Utilisatrices",
      UsersError: "Les utilisateurs sont requis.",
      CallerIdNamePrefix: "Préfixe de nom d'identification de l'appelant *",
      ErrorCallerId:
        "Le préfixe du nom d'identification de l'appelant est requis",
      CalleridNumberPrefix:
        "Préfixe du numéro d'identification de l'appelant *",
      ErrorCallerIdNumber:
        "Le numéro d'identification de l'appelant est requis",
      FillFieldMsg: "Veuillez remplir l'un des champsd",
      Ifnooneanswers: "Si personne ne répond à l'appel, passez l'appel à:",
      RingBackMedia: "SONT Back Media",
      UploadMusic: "Télécharger de la musique",
      ChooseFile: "Choisir le fichier",
      ExtensionUpdate: "Mise à jour de l'extension",
      ExtensionUnassignedSuccesfully: "Extension attribuée avec succès",
      ExtensionAlreadyExist: "La prolongation existe déjà",
      AddNewExtension: "Ajouter une nouvelle extension",
      FailedtoupdateGroupNames: "Échec de la mise à jour des noms de groupe",
      GroupNameUpdatedsuccessfully: "Nom du groupe mis à jour avec succès.",
      Groupdeletedsuccessfully: "Groupe supprimé avec succès.",
      FailedtodeleteGroup: "Échec de la suppression du groupe",
      GroupStatusUpdatedsuccessfully: "Statut de groupe mis à jour avec succès",
      FailedtoupdateGroupStatus: "Échec de la mise à jour du statut de groupe",
      NoExtensionsFound: "Aucune extension trouvée",
      GroupFeature: "Caractéristique de groupe",
      Info: "info",
      IsDisabledClicktheButtontoenable:
        "est désactivé.Cliquez sur le bouton pour activer",
      AreYouSureWantTo: "Êtes-vous sûr de vouloir",
      AreYouSureYouWantToRemove: "Êtes-vous sûr de vouloir désinstaller ",
      MembersUpdatedSuccessfully: "Les membres ont mis à jour avec succès",
      FailedToUpdate: "Échec de mise à jour",
      ManageUsers: "gérer les utilisateurs",
      NumberUnassignedsuccessfully: "Numéro non assisté avec succès",
      AddFromSpare: "Ajouter de la rechange",
      GroupFeatures: {
        callerIdPrependUpdatedSuccesfully:
          "L'identifiant de l'appelant a été mis à jour avec succès",
        ErrorInUpdatingCallerIdPrepend:
          "Erreur lors de la mise à jour de l'identifiant de l'appelant",
      },
    },
    Ivr: {
      FallBackUpdated: "Retour en arrière mis à jour avec succès.",
      IVRRemoved: "IVR supprimé avec succès",
      ErrorRemovingIVR: "Erreur lors de la suppression de l'IVR",
      StatusNotChanged: "Statut inchangé",
      RemoveIVR: "Supprimer l'IVR",
      ConfigureIVR: "Configurez votre SVI",
      VisualGraph: "Graphique Visuel",
      GraphicalView: "Vue Graphique",
      FallBack: "Secours",
      ConfigureFallback: "Configurez votre secours",
      EnableMessage: "est désactivé. Cliquez sur le bouton pour activer",
      Confirmation: "Êtes-vous sûr de vouloir",
      IVRName: "Nom de l'IVR",
      SelectAudioFile: "Sélectionner un fichier audio",
      PleaseSelectAudio: "Veuillez sélectionner un fichier audio",
      PriorityIsRequired: "La priorité est requise",
      SelectWavFiles:
        "Veuillez sélectionner des fichiers avec l'extension .wav",
      IVRUpdatedSuccessfully: "IVR mis à jour avec succès",
      IVRAddedSuccessfully: "IVR ajouté avec succès",
      Upload: "Télécharger",
      FallbackIsRequired: "Le Fallback est requis",
    },
    OfficeHours: {
      NumberUpdatedSuccessfully: "Numéro mis à jour avec succès",
      SomethingWentWrong: "Oups, quelque chose s'est mal passé",
      StartHourLessThanEndHour:
        "L'heure de début doit être inférieure à l'heure de fin",
      HoursMissing: "Les heures sont manquantes",
      AddOfficeHoursFirst:
        "Ajoutez des heures de bureau avant d'ajouter des heures de déjeuner",
      ValuesMissing: "Les valeurs sont manquantes",
      NameAlreadyExists: "Le nom existe déjà",
      NameIsMissing: "Le nom est manquant",
      HoursRemovedSuccessfully: "Heures supprimées avec succès",
      UserStatusChangedSuccessfully:
        "Statut de l'utilisateur changé avec succès",
      ErrorInChangingUserStatus:
        "Erreur lors du changement du statut de l'utilisateur",
      AddNewOfficeHours: "Ajouter de nouvelles heures de bureau",
      OfficeHoursStrategy: "Stratégie des Heures de Bureau",
      OfficeHolidays: "Jours fériés au bureau",
      IncomingCallHandling: "Traitement des appels entrants",
      IsDisabledClickToEnable:
        "est désactivé. Cliquez sur le bouton pour l'activer",
      NoOfficeHoursStrategyAvailable:
        "Il n'y a pas de stratégie des heures de bureau disponible pour vous",
      GetStartedByAddingNew: "Commencez par ajouter du neuf en Français",
      AddNewOfficeHoursStrategy:
        "Ajouter une nouvelle stratégie d'heures de bureau",
      ConfirmDisable: "Êtes-vous sûr de vouloir désactiver ?",
      TwentyFourHoursOpenOffice: "Bureau ouvert 24 heures sur 24",
      Monday: "lundi",
      Tuesday: "mardi",
      Wednesday: "mercredi",
      Thursday: "jeudi",
      Friday: "vendredi",
      Saturday: "samedi",
      Sunday: "dimanche",
      ClosedForLunchHours: "Êtes-vous fermés pendant l'heure du déjeuner ?",
      OopsMessage:
        "Oups ! Vous ne pouvez pas définir de jours fériés pour la stratégie de bureau ouvert 24 heures sur 24",
      ConfirmRemoveHoliday:
        "Êtes-vous sûr de vouloir supprimer ce jour férié ?",
      AddNewHolidays: "Ajouter de nouvelles vacances",
      ClosedForHolidays: "Êtes-vous fermés pour les vacances ?",
      HolidayName: "Nom du jour férié",
      Month: "Mois",
      Week: "Semaine",
      Day: "Jour",
      DataUpdatedSuccessfully: "Données mises à jour avec succès",
    },
    CallLogs: {
      All: "Toute",
      Incoming: "Entrante",
      Outgoing: "Sortante",
      MissedCall: "Appel manqué",
      Extension: "Interne",
      Duration: "Durée",
      Status: "Statut",
      CallRecordings: "Enregistrements",
      Start: "Commencer",
      End: "Fin",
      Filter: "Filtre",
      ErrorEncountered: "Erreur rencontrée",
      NameIsRequired: "Le nom est requis",
      Copied: "copié",
      ClickToCopy: "cliquez pour copier",
      ID: "IDENTIFIANT",
      CallRecording: "Enregistrement d'appel",
      Download: "Télécharger",
    },
    Devices: {
      AddCellDevice: "Ajouter un appareil cellulaire",
      ErrorInAddingDevice: "Erreur dans l'ajout de périphérique",
      DeviceAddedSuccessfully: "Appareil ajouté avec succès ",
      NumberisRequired: "Le numéro est requis",
      PleaseSelectaPerson: "Veuillez sélectionner une personne",
      DeviceNameisrequired: "Le nom du compte SIP est requis",
      DeviceCellNameisrequired: "Le nom du téléphone portable est requis",
      UseSystemVoicemail: "Utiliser la messagerie vocale du système",
      AssignedTo: "Assigné à",
      DeviceName: "Nom du compte SIP",
      DeviceCellName: "Nom du téléphone portable",
      Type: "Taper",
      Enable: "Activer",
      Name: "Nom",
      User: "Utilisatrice",
      Number: "Nombre",
      AddFromSpare: "Ajouter à partir de rechange",
      ChooseDevice: "Choisir l'appareil",
      PleaseSelectADevice: "Veuillez sélectionner un appareil",
      DeviceUpdatedSuccessfully: "Appareil mis à jour avec succès ",
      ErrorInUpdatingDevice: "Erreur de mise à jour de l'appareil",
      NameIsRequired: "Le nom est requis",
      Username: "Nom d'utilisateur",
      UserNameIsRequired: "Nom d'utilisateur est nécessaire",
      NotifyOnUnregister:
        "Notifier sur non enregistré (non recommandé pour les utilisateurs d'applications mobiles)",
      AutoProvision: "Disposition automatique",
      MacAddress: "Adresse Mac",
      MacAddressIsRequired: "Une adresse MAC est requise",
      DeviceMake: "Faire de l'appareil",
      PleaseSelectADeviceMake: "Veuillez sélectionner un appareil à faire",
      DeviceModel: "Modèle d'appareil",
      PleaseSelectADeviceModal: "Veuillez sélectionner un appareil modal",
      Line: "Doubler",
      PleaseSelectALine: "Veuillez sélectionner une ligne",
      AddSIPDevice: "Ajouter un périphérique SIP",
      EditCellDevice: "Modifier l'appareil cellulaire",
      Password: "Mot de passe",
      ErrorPassword: "Mot de passe requis",
      Realm: "Royaume",
      RealmIsRequired: "Le royaume est requis",
      EditSIPDevice: "Modifier le périphérique SIP",
      StatusNotChanged: "Le statut n'a pas changé",
      MobileAppProvisioning: "Provisioning d'applications mobiles",
      QRCodeText:
        "Veuillez utiliser le scanner de code QR dans votre application mobile et scanner le code QR affiché pour provisionner votre softphone.",
    },

    VoiceMail: {
      Name: "Nom",
      User: "Utilisatrice",
      Number: "Nombre",
      Numbers: "Numéro.",
      ExtensionIsRequired: "Une extension est requise",
      NameIsRequired: "Le nom est requis",
      Pin: "épingle",
      PinIsRequired: "La broche est requise",
      VoiceMailUpdatedSuccessfully: "Mail vocal mis à jour avec succès",
      ErrorInUpdatingVoiceMail: "Erreur de mise à jour de la messagerie vocale",
      EditVoicemail: "Modifier la messagerie vocale",
    },
    Number: {
      labelUpdateSuccessMsg: "Étiqueter à jour avec succès",
      TwentyFourHours: "Bureau ouvert de 24 heures",
      CustomHours: "Heures de bureau personnalisées",
      SuccsesAssign: "Numéro attribué avec succès",
      SuccsesHoursUpdate: "Heures de bureau mise à jour avec succès",
      SuccsesHoursAdd: "Les heures de bureau ont ajouté avec succès",
      SuccsesHolidaysUpdate:
        "Les vacances de bureau ont mis à jour avec succès",
      SuccsesHolidaysAdd: "Les vacances de bureau ont ajouté avec succès",
      OfficeHours: "Stratégie des heures de bureau",
      OfficeHolidays: "Vacances au bureau",
      IncomingCallHandling: "Gestion des appels entrants",
      Open: "Ouvrir",
      Closed: "Fermée",
      AddHolidays: "Ajouter des vacances",
      Single: "Seule journée",
      Range: "Plage de dates",
      Advanced: "Avancée",
      Month: "Mois",
      Day: "Jour",
      Week: "Semaine",
      HolidayName: "Nom de vacances",
      From: "Depuis",
      To: "Pour",
      OpenHours: "Heures d'ouverture *",
      AfterHours: "Après des heures *",
      HolidayHours: "Heures de vacances *",
      ClosedHours: "Heures fermées *",
      Error: "Erreur",
      AlreadyExist: "Le nombre existe déjà.",
      NumberAssignedSuccsessfully: "Numéro attribué avec succès",
      Assign: "Attribuer",
      AssignIsRequired: "L'attribution est requise",
      Label: "Étiquette",
      NumberRemovedSuccessfully: "Numéro supprimé avec succès",
      ThereIsNoNumberAvailable:
        "Oops!Il n'y a aucun numéro disponible pour vous.",
    },
    PhoneNumbers: {
      AddFromSpare: "Ajouter à partir de la réserve",
    },
    Features: {
      RequireKeyPress: "Nécessite d'appuyer sur une touche",
      ForwardDirectCallOnly: "Transférer l'appel direct uniquement",
      KeepYourCallerId: "Conservez votre identification de l'appelant",
      HotdeskId: "Identifiant de bureau partagé",
      DoesItRequireAPin: "Nécessite-t-il un code PIN?",
      AllowLoginToMultipleDevices:
        "Autoriser la connexion à plusieurs appareils",
      PleaseAssignNumberFirst: "Veuillez d'abord attribuer un numéro",
      EnableVoiceMailToEmail: "Activer la messagerie vocale par e-mail",
      CallerIdNumberUpdatedSuccessfully:
        "Numéro d'identification de l'appelant mis à jour avec succès",
      ErrorInUpdatingCallerIdNumber:
        "Erreur lors de la mise à jour du numéro d'identification de l'appelant",
      Failed: "Échoué",
      UpdatedSuccessfully: "Mis à jour avec succés",
      OnOff: "Allumé /éteint",
      FaxToEmail: "Fax vers e-mail",
      EmailToFax: "E-mail vers fax",
      FaxToEmailIsRequired: "Fax vers e-mail est requis",
      EmailToFaxIsRequired: "Courriel à télécopier est requis",
      CallForwardingUpdatedSuccessfully:
        "Transfert d'appel mis à jour avec succès",
    },
  },
  Number: {
    NoNumberAvailable: "Aucun numéro n'est disponible pour vous",
    StartByAddingNew: "Commencez par ajouter de nouveaux",
    UnassignedSuccessfully: "Numéro non attribué avec succès.",
    AlreadyAssigned: "Est-ce déjà affecté à un autre",
    NumberRemovedSuccessfully: "Numéro supprimé avec succès.",
    DidRemovedSuccessfully: "Est-ce déjà affecté à un autre",

    All: "Tout",
    Number: "Nombre",
    Country: "Pays",
    Resellers: "Revendeurs",
    Client: "Client",
    Status: "Statut",
    AddNewNumber: "Ajouter un nouveau numéro",
    Search: "Recherche",
    Unassign: "Désattribuer",
    Remove: "Retirer",
    AvailableNumber: "Numéro disponible",
    SpareNumber: "numéro de rechange",
    SelectCountry: "Choisissez le pays",
    Assign: "Attribuer",
    Cancel: "Annuler",
    Add: "Ajouter",
    Buy: "Acheter",
    Error: "Erreur",
    EnterNumber: "Entrer un nombre",
    ErrorCountryRequired: "Le pays est requis.",
    ErrorNumberRequired: "Le numéro est requis",
    Success: "Succès",
    NotificationNumberAdd: "Numéro ajouté avec succès.",
    NotifiactionNumberbought: "Nombre acheté avec succès",
    AssignNumber: "Attribuer un numéro",
    UnassignModalData:
      "Êtes-vous sûr de vouloir annuler l'attribution du numéro ?",
    RemoveModalData: "Voulez-vous vraiment supprimer le numéro ?",
  },
  Brand: {
    CompanyName: "Nom de l'entreprise",
    AddressLine1: "Adresse 1",
    AddressLine2: "Adresse 2",
    Email: "Email",
    Cancel: "Annuler",
    Save: "Sauvegarder",
    Logo: "Logo",
    FileTooltip: "La taille du fichier doit être inférieure à 5 Mo.",
    ErrorUpload: "Veuillez télécharger une image",
    ErrorCompanyName: "Veuillez saisir le nom de l'entreprise.",
    ErrorAddress: "Veuillez saisir l'adresse.",
    ErrorEmail: "Veuillez saisir un e-mail.",
  },
  Notification: {
    Subject: "Sujet",
    Message: "Message",
    Clear: "Clair",
    Submit: "Soumettre",
  },
  Rates: {
    UploadRates: "Taux de téléchargement",
    AddNewCountry: "AJOUTER UN NOUVEAU PAYS",
    CountryName: "Nom du pays",
    CountryCode: "Code postal",
    Rate: "Taux",
    Actions: "Actions",
    ADD: "AJOUTER",
    SelectCountry: "Choisissez le pays",
    Uploadordropafilerighthere: "Téléchargez ou déposez un fichier ici",
    Successfullyuploaded: "Téléversé avec succès",
    UnsupportedFileError:
      "Fichier non pris en charge, veuillez télécharger un fichier avec l'extension .CSV ",
    DownloadsampleSheet: "Télécharger la feuille d'exemple",
    AddRateSheet: "Ajouter une nouvelle feuille de tarifs",
    EnterNewRatesheet: "Saisissez le nom de la feuille de tarifs",
    InputKeyword: "Entrez le mot-clé et appuyez sur Entrée",
    RateDelete: "Le tarif a été supprimé avec succès",
    AddRate: "Le tarif a été ajouté avec succès",
    UpdateRate: "Le taux a été mis à jour avec succès",
    AddedRateSheet: "La feuille de tarifs a été ajoutée avec succès",
    DownloadRateSheet: "Télécharger la feuille de tarifs",
    DeleteRateSheet: " Supprimer la feuille de tarifs",
    RateSheetDeleted: "La feuille de tarifs a été supprimée avec succès",
    DeleteRate: "Taux de suppression",
    deleteRateModalData: "Voulez-vous vraiment supprimer Rate ?",
    DeleteRatesheetModalData:
      "Voulez-vous vraiment supprimer la feuille de taux ?",
    Delete: "Supprimer",
  },
  Fax: {
    RecipientNumber: "Número del destinatario",
    RecipientNumberIsRequired: "Recipient Number is required",
    PleaseUploadAFileWithPdfExtension:
      "Por favor, sube un archivo con extensión .pdf",
    SelectPerson: "Please Select a Person",
    SearchInboundFaxes: "Buscar faxes entrantes",
    Received: "Recibido",
    ReceivingNumber: "Número de recepción",
    Pages: "Páginas",
    SendingNumber: "Número de envío",
    SearchOutboundFaxes: "Buscar faxes salientes",
  },
  Carrier: {
    Name: "Nom",
    IPAddressDomain: "AdresseIp / Domaine",
    Prefix: "Préfixe",
    Port: "Port",
    Priority: "Priorité",
    UserName: "Nom d'utilisateur",
    Password: "Mot de passe",
    ChooseCountries: "Choisissez des pays",
    AllCountries: "Tous les pays",
    EnableFax: "Activer le fax",
    SetSIPCustomHeaders: "Définir les en-têtes personnalisés SIP",
    CallerIdType: "Type d'identification de l'appelant",
    ErrorPriority: "La priorité est requise",
    ErrorPassword: "Mot de passe requis",
    ErrorUserName: "Nom d'utilisateur est nécessaire",
    ErrorPort: "Le port est requis",
    ErrorPrefix: "Le préfixe est requis",
    ErrorIPAddressDomain: "L'adresse / domaine IP valide est requis",
    ErrorName: "Le nom est requis",
    SuccessfullMsg: "Carrier a ajouté avec succès",
    ErrorMsg: "Oups, quelque chose s'est mal passé!",
    UpdateMsg: "Carrier mis à jour avec succès",
    Add: "Ajouter un nouveau transporteur",
    Remove: "Retirer",
    Search: "Recherche",
    Cancel: "Annuler",
    Update: "Mise à jour",
    Save: "Sauvegarder",
    EmptyMsg: "Pas de transporteurs",
    GetStartedMsg: "Commencez par créer un nouveau transporteur.",
    TryAgainMsg: "Veuillez réessayer plus tard.",
    RemoveMsg: "Êtes-vous sûr de vouloir supprimer",
    NameExistMsg: "Le nom existe déjà",
    Inbound: "Transporteur entrant",
    Outbound: "Transporteur sortant",
  },
  DashBoard: {
    Users: "Utilisateurs",
    Devices: "Dispositifs",
    Numbers: "Nombres",
    Credits: "Crédits",
    TotalCalls: "Appels totaux",
    ActivityLog: "Journaux d'activité",
    Number: "Nombre",
    Who: "OMS",
    Where: "Où",
    CallCharges: "Total des frais d'appel",
    MinutesUsed: "Nombre total de minutes utilisées",
    IncomingCalls: "Les appels entrants",
    MissedCalls: "Appels manqués",
    OutgoingCalls: "Les appels sortants",
    Calls: "Appels",
    Addusers: "Ajouter des utilisateurs",
    FirstName: "Prénom",
    LastName: "Nom de famille",
    Email: "E-mail",
    Password: "Mot de passe",
    MainExtensionNumber: "Numéro de poste principal",
    CreateDeviceAutomatically: "Créer un appareil automatiquement",
    AddSipDevice: "Ajouter un compte SIP",
    AddCellDevice: "Ajouter un téléphone portableL",
    Name: "Nom",
    AssignedTo: "Assigné à",
    notifyUnregister:
      "Notifier lors de la désinscription (non recommandé pour les utilisateurs d'applications mobiles).",
    autoProvision: "Provisionnement automatique",
    DeviceName: "Nom de l'appareil",
    useSystemVoicemail: "Utiliser la messagerie vocale du système",
    BuyNumbers: "Acheter des numéros",
    addCredit: "Ajouter un crédit",
    Amount: "Montant",
  },
  Account: {
    AccountName: "Nom du compte",
    workspaceURL: "URL de l'espace de travail",
    workspaceInfo:
      "Vous pouvez modifier l'URL (direction du Web) de votre espace de travail, mais gracieuseté de vos collègues utilisateurs de Voifinity, ne le faites pas souvent",
    workspaceNote:
      "Si vous changez l'ur de votre espace de travail, Voifinity redirigera automatiquement de la direction avant le nouveau. Cependant, vous devez toujours vous assurer que tout le monde dans son espace de travail connaît le changement car l'ancien nom sera à nouveau placé dans la piscine et pourrait être utilisé par un autre espace de travail à l'avenir.",
    ErrorName: "Veuillez saisir le nom",
    Email: "Email",
    ErrorEmail: "Veuillez saisir le courrier électronique",
    FirstName: "Prénom",
    ErrorFirstName: "Veuillez entrer le prénom",
    LastName: "Nom de famille",
    ErrorLastName: "Veuillez entrer le nom de famille",
    ContactNumber: "Numéro de contact",
    ErrorContact: "Veuillez saisir le numéro de contact",
    ChooseTimezone: "Choisissez le fuseau horaire",
    ErrorTimeZone: "Veuillez choisir un fuseau horaire",
    Address: "Adresse",
    ErrorAddress: "Veuillez saisir l'adresse",
    SuccessfullMsg: "Le compte ajouté avec succès",
    ErrorMsg: "Oups, quelque chose s'est mal passé!",
    UpdateMsg: "Compte mis à jour avec succès",
    AddNewAccount: "Ajouter un nouveau compte",
    Actions: "Actions",
    RemoveAccount: "Supprimer le compte",
    Reseller: "Revendeuse",
    Account: "Compte",
    Plan: "Plan",
    Features: "Caractéristiques",
    GetStartedMsg: "Commencez par créer un nouveau compte.",
    TryAgainMsg: "Veuillez réessayer plus tard.",
    EmptyMsg: "Pas de comptes",
    ErrorCarrier: "Veuillez sélectionner un transporteur",
    HelperText: "Cliquez ici pour mettre à jour l'espace de travail",
    Carriers: "Transporteuses",
    ErrorCarriers: "Veuillez sélectionner un transporteur",
    EmailExists: "Email existe déjà",
    URLExists: "L'URL de l'espace de travail existe déjà",
    AccountExists: "Nom de compte déjà utilisé",
    DeleteMsg: "Le compte a été supprimé successivement",
    Disable: "Êtes-vous sûr de désactiver",
    UrlUpdateMsg: "L'URL de l'espace de travail est mise à jour.",
    GoMsg: "Aller dans un nouvel espace de travail",
  },
  Address: {
    EnterAddressBookName: "Entrez le nom du carnet d'adresses",
    NameIsRequired: "Le nom est requis",
    Company: "Entreprise",
    SpeedDial: "Numérotation abrégée",
    Add: "Ajouter",
    AddNew: "Ajouter un nouveau",
    ADD: "AJOUTER",
    NumbersIsRequired: "Les chiffres sont requis",
    EmailIsRequired: "Un e-mail est requis",
    Number: "Nombre",
    NumberAddedSuccsesfully: "Numéro Ajout avec succès",
    NumberEditedSuccsesfully: "Numéro édité avec succès",
    NumberDeletedSuccsesfully: "Numéro supprimé avec succès",
    AddressBookUploadedSuccessfully:
      "Carnet d'adresses téléchargée avec succès",
    AddContact: "Ajouter un contact",
    Import: "Importer",
    UploadBook: "Livre de téléchargement",
    Extension: "Extension",
    NoCompanyContactsAvailable: "Aucun contacts d'entreprise disponible",
    PhoneNumber: "numéro de téléphone",
    SpeedDialIsRequired: "La numérotation de vitesse est requise",
    SpeedDialAddedSuccsesfully: "Speed Dial a ajouté avec succès",
    SpeedDialEditedSuccsesfully: "Speed Dial modifié avec succès",
    SpeedDialDeletedSuccsesfully: "Dial à la vitesse supprimée avec succès",
    SpeedDialNumber: "Numéro de numérotation de vitesse",
    NoSpeedDialsAvailable: "Aucun cadran de vitesse disponible",
    GetStartedByCreatingANewSpeedDial:
      "Commencez par créer un nouveau cadran de vitesse.",
  },
};

export default transalation;