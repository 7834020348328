
export let baseTheme: any = {
  primary: "#425778",
  primaryAlpha5: "",
  primaryAlpha10: "",
  primaryAlpha20: "",
  primaryAlpha30: "",
  primaryAlpha40: "",
  primaryAlpha50: "",
  primaryAlpha60: "",
  primaryAlpha80: "",
  primaryText: "#ffffff",
  secondary: "#ffffff",
  secondaryText: "#222222",
  ternary: '#eeeeee',
  ternaryText: "#222222",
  hover: "rgba(0,0,0,0.1)",
  error: "rgba(255,0,0,1)",
  tableHeader: "",
  errorAlpha40: "rgba(255,0,0,0.4)",
  disabled: "rgba(255,255,255,0.2)",
};
export function updateKey(key: any, value: any) {
  baseTheme[key] = value
}
