import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../navigation/auth/useAuth";
import Typography from "@mui/material/Typography";
import { useTheme } from "../../../theme/themeContext";
import Keycloak from "keycloak-js";
import { getENVData, paths } from "../../../config/environment";
import FadeIn from "react-fade-in";
import SignUp from "./signUp";
import SignIn from "./signIn";
import { verifyClient } from "../../../services/api";
import Loader from "../../loader";

const Login = (props: any) => {
  const { setKeyCloak, setKeyCloakState, setAuthState } = props;
  const navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  const { toggleTheme } = useTheme();
  const environment = getENVData();

  //@ts-ignore
  let from = location.state?.from?.pathname || "/home";

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;


    auth.signin(username, () => {
      navigate(from, { replace: true });
    });
  }

  const [signin, setSignin] = useState("");
  const [clientInfo, setClientInfo] = useState<any>(null);
  const [signinloader, setSigninloader] = useState(false);
  const [tenantvalidation, settenantvalidation] = useState(false);
  const [signup, setSignup] = useState(true);
  let full = window.location.host
  // let full  = 'supertelco.us3-test.voifinity.in'
  let temp = ''
  Object.keys(paths).forEach((key: any) => {
    if(full.includes(paths[key]) && full !== (paths[key])){
      temp = full.split('.')[0];
    }
  })
  const subDomain = temp 
  // const subDomain = environment.env === '' ? 'localhost' : environment.env === 'testing' || environment.env === 'develop' || environment.env === 'staging' ? full.split('.')[1] === 'us3-test' || full.split('.')[1] === 'us5-dev' || full.split('.')[1] === 'staging' ? full.split('.')[0] : '' : full.split('.')[1] === 'us6' ? full.split('.')[0] : ''
  console.log("subDomain - ", subDomain)
  const Signup = () => {
    setSignup(!signup);
  };
  const handleLogin = () => {
    const keycloak_config = window.localStorage.getItem("keycloak_config");
    if (keycloak_config) {
      const key = Keycloak(JSON.parse(keycloak_config));
      setKeyCloak(key)
      setAuthState('loading');
      setKeyCloakState(true)
    }
  }
  const SigninSubmit = (name: string = '') => {
    setSigninloader(true);
    let realm = name;
    if (name === '') {
      realm = signin.toLowerCase().trim().replace(/ /g, "");
    }
    verifyClient(realm).then((res: any) => {
      console.log("resultresultresultresultresultresult",res)
      if (res?.client_name) {
        realm = res.client_name
        if (subDomain === realm || environment.env === 'localhost') {
          setClientInfo(res)
          const keycloak_config = {
            url: environment.keyCloak,
            realm: realm,
            clientId: realm,
          };

          localStorage.setItem("keycloak_config", JSON.stringify(keycloak_config));
          localStorage.setItem("tenant", realm);
          const key = Keycloak(keycloak_config);
          setKeyCloak(key)
          setKeyCloakState(true)
        }

        if (environment.env !== 'localhost' && realm !== subDomain) {
          window.location.href = "https://" + realm + '.' + environment.domain;
        } else {
          setTimeout(() => {
            handleLogin()
          }, 2000);
        }
      } else {
        settenantvalidation(true);
        setSigninloader(false);
      }
    })
      .catch((err: any) => {
        console.log("qwerty Success")
        settenantvalidation(true);
        setSigninloader(false);
      });
  };
  const navigateTo = () => {
    navigate(signup ? 'app/signIn' : 'app/signUp', { replace: true });
    Signup()
  }
  const handleNavigation = () => {
    if (signup && subDomain !== '') {
      SigninSubmit(subDomain)
    } else {
      navigateTo()
    }
  }
  useEffect(() => {
    toggleTheme('lite', () => {
    })
    if (subDomain !== '' || environment.env === 'localhost') {
      const keycloak_config = window.localStorage.getItem("keycloak_config");
      console.log("subDomain subDomain", subDomain)
      console.log("subDomain keycloak_config", keycloak_config)
      if (subDomain !== '') {
        verifyClient(subDomain).then((res: any) => {
          // debugger
          setClientInfo(res)
          if (keycloak_config) {
            localStorage.setItem("tenant", subDomain);
            let Config = JSON.parse(keycloak_config);
            if (res.client_name === Config.realm) {
              console.log("subDomain Config", Config)
              console.log("subDomain env", environment.env)
              console.log("subDomain Config.realm === subDomain", Config.realm, subDomain, Config.realm === subDomain)
              if (Config.realm === subDomain || environment.env === 'localhost') {
                const key = Keycloak(Config);
                setKeyCloak(key)
                setAuthState('loading');
                setKeyCloakState(true)
                console.log("subDomain setAuthState('loading')")
              }
            } else {
              console.log("subDomain Client Not found")
              if (environment.env !== 'localhost') {
                window.location.href = "https://" + environment.domain;
              }
            }
          } else {
            setClientInfo(res)
            const keycloak_config = {
              url: environment.keyCloak,
              realm: subDomain,
              clientId: subDomain,
            };

            localStorage.setItem("keycloak_config", JSON.stringify(keycloak_config));
            localStorage.setItem("tenant", subDomain);
            const key = Keycloak(keycloak_config);
            setKeyCloak(key)
            setKeyCloakState(true)
            setSignin(subDomain);
            SigninSubmit(subDomain);
          }
        })
      } else {
        if (keycloak_config) {
          let Config = JSON.parse(keycloak_config);
          console.log("subDomain Config", Config)
          console.log("subDomain env", environment.env)
          console.log("subDomain domain", subDomain)
          if (Config.realm === subDomain || environment.env === 'localhost') {
            const key = Keycloak(Config);
            setKeyCloak(key)
            setAuthState('loading');
            setKeyCloakState(true)
            console.log("subDomain Success")
          }
        } else {
          console.log("subDomain Client Not found")
          if (environment.env !== 'localhost') {
            window.location.href = "https://" + environment.domain;
          }
        }
      }
    }
  }, []);

  return (
    <div className="w-full h-full flex bg-ternary">
      {subDomain === '' ?
        <>
          <div className="w-1/2 max-md:w-full text-xl relative sm:flex items-center justify-end max-md:justify-center">
            <div className=" right-0 w-full md:w-[520px] max-sm:p-10 p-20 ">
              <div className="  max-w-full  w-full">
                <div className=" max-w-full w-full mx-auto sm:mx-0">

                  <FadeIn>
                    {clientInfo === null ?
                      <img className="h-16"
                        src="https://us5-dev.voifinity.in/auth/resources/jv3uc/login/voifinity/img/keycloak-logo-text.png"
                        alt="logo"
                      />
                      : clientInfo?.logo !== null ? <img className=""
                        src={clientInfo?.logo}
                        alt="logo"
                      /> : <div className="uppercase font-thik text-[28px] text-primary">
                        {clientInfo?.display_name}
                      </div>
                    }
                    <div className={`flex  flex-col justify-center  w-full mt-2 ${signup ? 'h-[400px] max-sm:h-[480px]' : 'h-[200px]'} transition-height duration-500 ease-in-out`}>
                      {signup ?
                        <SignUp />
                        : <SignIn subDomain={subDomain} signinloader={signinloader} setSigninloader={setSigninloader} tenantvalidation={tenantvalidation} settenantvalidation={settenantvalidation} signin={signin} setSignin={setSignin} SigninSubmit={SigninSubmit} setKeyCloak={setKeyCloak} setKeyCloakState={setKeyCloakState} setAuthState={setAuthState} />
                      }
                    </div>
                    <div className="flex items-baseline mt-6 px-2 font-medium">
                      <Typography>
                        {signup
                          ? "Already have an account?"
                          : "Don't have an account?"}
                      </Typography>
                      <span className="ml-4 text-[16px] cursor-pointer text-primary"
                        onClick={() => handleNavigation()}>
                        {signup ? "Sign in" : "Sign up"}
                      </span>
                    </div>
                  </FadeIn>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 max-md:hidden p-24 relative md:flex flex-auto items-center justify-center h-full bg-[#1e293b]">
            <svg className="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg"><g className="opacity-20" fill="none" stroke="currentColor" strokeWidth="100"><circle r="234" cx="196" cy="23"></circle><circle r="234" cx="790" cy="491"></circle></g></svg>
            <svg className="absolute top-0 right-0 w-64 opacity-20" viewBox="0 0 220 192" fill="none"><defs><pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse"><rect x="0" y="0" width="4" height="4" fill="currentColor"></rect></pattern></defs><rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect></svg>
            <div className="z-10 relative w-full max-w-2xl text-[#FFFFFF]">
              <FadeIn>
                <div className="text-4xl font-bold leading-none text-white">
                  <div>Welcome to</div>
                  <div>{clientInfo?.display_name ? clientInfo?.display_name : "Voifinity"}</div>
                </div>
                <div className="mt-10 text-md tracking-tight leading-6 text-gray-400">
                  {clientInfo?.display_name ? clientInfo?.display_name : "Voifinity"} cloud based business services help businesses transform their customer and employee experience. With one system of engagement for voice, fax, video, messaging and contact center and one system of intelligent reports to improve performances; businesses can now communicate faster and smarter to exceed the speed of customer expectations.
                </div>
              </FadeIn>
            </div>

          </div>
        </> :
        <div className="w-full max-md:w-full text-xl relative sm:flex items-center justify-center max-md:justify-center">
          <Loader />
        </div>}
    </div>

  )
}
export default Login;

