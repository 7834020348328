import Close from "@mui/icons-material/Close";
import FadeIn from "react-fade-in/lib/FadeIn";

const Modal = (props) => {
  const { icon, title, closeEvent } = props;
  return (
    <div
      className="relative  z-[100]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-[#00000040] dark:bg-[#ffffff90] backdrop-blur-sm "></div>
      <div className="fixed inset-0 z-[100] overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
          <FadeIn>
            <div className="relative animate transition-transform transform overflow-hidden  rounded-[3px] bg-ternary text-left shadow-xl   min-w-[400px] min-h-[200px]">
              {title && (
                <div className=" bg-primary-alpha-20 p-3 flex justify-between">
                  <span className="flex">
                    <div className="pr-1 mr-2 text-primary">
                      {icon ? icon : ""}
                    </div>
                    <div className="pt-[2px]">{title}</div>
                  </span>
                  {closeEvent && (
                    <span
                      onClick={() => closeEvent()}
                      className="cursor-pointer"
                    >
                      <Close />
                    </span>
                  )}
                </div>
              )}
              <div className="m-4">{props.children}</div>
            </div>
          </FadeIn>
        </div>
      </div>
    </div>
  );
};
export default Modal;
