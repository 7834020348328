import { useEffect, useState } from 'react'
import Modal from '../../../../atom/modal';
import ComponentMap from '../../../../atom/componentmap';
import ThemedButton from '../../../../atom/ThemedButton/themedButton';
import DevicesIcon from "@mui/icons-material/Devices"
import { actualDataToMapData, addAKeyValueFromRefListItems, addChildrensToMapData } from '../../../../services/utilities';
import Notification from "../../../../atom/Notification";
import { getUsers, updateDevice } from '../../../../services/api';
import { useTranslation } from 'react-i18next';
import DeleteIcon from "@mui/icons-material/Delete";

function EditCellDevice(props: any) {
    const { t } = useTranslation()
    const { setModal, activeItem, childStatus, setchildStatus, autoProv = false, activeUser, change, setChange, activeState, setUpdatedPhone } = props;
    const [status, setStatus] = useState<any>(false)
    const [buttonLoader, setButtonLoader] = useState<any>(false)
    const [deleteButtonLoader, setDeleteButtonLoader] = useState<any>(false)
    const [removeDialogueBox, setRemoveDialogueBox] = useState<boolean>(false)

    console.log('aqaq', activeItem)
    const [data, setData] = useState([
        {
            name: 'uuid',
            title: 'uuid',
            value: '',
            hidden: true
        },

        {
            title: t("Control.Devices.Name"),
            name: "name",
            layout: 3,
            value: "",
            required: true,
            errormessage: t("Control.Devices.DeviceCellNameisrequired"),
        },
        {
            title: "Number",
            name: "login",
            type: "phonenumber",
            co_code: "",
            layout: 3,
            value: "",
            required: true,
            errormessage: t("Control.Devices.NameIsRequired"),
        },
        {
            name: "user_id.uuid",
            title: t("Control.Devices.AssignedTo"),
            type: "dropdown",
            displayProps: { "label": "label", "value": "uuid" },
            childrens: [

            ],
            layout: 3,
            value: "",
            hidden: false,
            required: true,
            errormessage: t("Control.Devices.PleaseSelectaPerson"),
        }
        // {
        //     title: t("Control.Devices.UseSystemVoicemail"),
        //     name: "systemvoicemail",
        //     layout: 4,
        //     type: "checkbox",
        //     value: false,
        // },
    ])

    const editDeviceDetails = () => {


        if (activeItem?.user_id === null || activeItem?.user_id.length === 0 || activeItem?.user_id === "none") {
            console.log("987", activeItem)
            setData(actualDataToMapData([...data], { ...activeItem, user_id: { uuid: "none" } }));

        }
        else {

            console.log("123", activeItem)
            setData(actualDataToMapData([...data], activeItem));

        }

    }


    const getAssignedValues = () => {
        getUsers({}).then((result: any) => {
            console.log("dropres", result)
            let UpdatedResult = addAKeyValueFromRefListItems(result, "label", ["first_name", "last_name"])
            UpdatedResult = UpdatedResult.filter((node: any) => { return node.status !== "disabled" })

            UpdatedResult.unshift({ label: 'None', uuid: 'none' })
            setData(addChildrensToMapData([...data], UpdatedResult, "user_id.uuid"));
        })
    }
    const onSave = (result: any) => {
        console.log("eresultt", result)
        const name = {
            "login": result.login,
            "name": result.name,
            "systemvoicemail": result.systemvoicemail,
            "uuid": result.uuid,
            "user_id": result['user_id.uuid']
        }
        updateDevice(name, result.uuid
        ).then((result: any) => {
            if (result?.data?.parameter) {
                let msg = result?.data?.reason
                Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
            } else {
                Notification("Success", t("Control.Devices.DeviceUpdatedSuccessfully"), "success");

            }
            setStatus(!status)
            setchildStatus(!childStatus)
        })
            .catch((error: any) => {
                Notification("Error", t("Control.Devices.ErrorInUpdatingDevice"), "danger");
            })
        setModal("")
    }

    const onSaveProv = (result: any) => {

        let payLoad = { ...result, 'user_id': activeUser?.uuid }
        setButtonLoader(true)
        updateDevice(payLoad, result.uuid
        ).then((result: any) => {
            if (result?.data?.parameter) {
                let msg = result?.data?.reason
                Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
                setButtonLoader(false)

            } else {
                Notification("Success", "Device Details Updated Successfully ", "success");
                setButtonLoader(false)
                setChange(!change)
                setUpdatedPhone(payLoad?.uuid)
            }
        })
            .catch((error: any) => {
                Notification("Error", "Error In Updating Device", "danger");
                setButtonLoader(false)

            })

    }

    const onHandleDelete = () => {
        setRemoveDialogueBox(true)
    }

    const onCancelModal = () => {
        setRemoveDialogueBox(false)
    }

    const handleDeleteDevice = (uuid: string) => {
        // console.log("sdasdsdfd", devices)
        setDeleteButtonLoader(true)
        // if (activeItem.status === "enabled") {
        updateDevice({ user_id: null }, activeItem?.uuid).then((result: any) => {
            if (result?.data?.parameter) {
                let msg = result?.data?.reason
                Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
                setDeleteButtonLoader(false)
            }
            else {
                Notification("Success", "Device Removed Successfully ", "success");
                setDeleteButtonLoader(false)
                setChange(!change)
            }
        }).catch((error: any) => {
            Notification("Error", "Oops! Something Went Wrong", "danger")
            setDeleteButtonLoader(false)
        })
        // }
    }

    const onCancel = () => {
        setModal("")
    }

    useEffect(() => {
        editDeviceDetails()
        if (autoProv) {
            let copyState = [...data]
            console.log(copyState, 'copystate')
            const filteredData = (copyState?.filter((node: any) => {
                return (node.name !== 'user_id.uuid')
            }))
            setData(filteredData)
        }
        else {
            getAssignedValues()
        }

    }, [activeItem])
    return (
        <div>
            {autoProv ?
                <div className='flex justify-center items-center mt-12'>
                    <div className="w-[700px] ">
                        <hr className='mb-3' />

                        <ComponentMap data={data} setData={setData} />
                        <div className="text-right ">

                            <ThemedButton
                                children={"Remove"}
                                icon="remove"
                                theme="error"
                                valiData={data}
                                change={setData}
                                // loading={deleteButtonLoader}
                                disabled={activeState?.status === "disabled" ? true : false}
                                click={onHandleDelete}
                            />

                            <ThemedButton
                                children={t("Save")}
                                icon="update"
                                theme="primary"
                                valiData={data}
                                change={setData}
                                disabled={activeState?.status === "disabled" ? true : false}
                                loading={buttonLoader}
                                click={onSaveProv}
                            />

                        </div>
                    </div>
                </div >
                :
                <Modal
                    icon={<DevicesIcon />}
                    title={t("Control.Devices.EditCellDevice")}
                    closeEvent={() => {
                        setModal("")
                    }}
                >
                    <div className="w-[700px]">
                        <ComponentMap data={data} setData={setData} />
                        <div className="text-right ">
                            <ThemedButton
                                children={"Cancel"}
                                icon="cancel"
                                theme="secondary"
                                click={onCancel}
                            />
                            <ThemedButton
                                children={"Save"}
                                icon="update"
                                theme="primary"
                                valiData={data}
                                change={setData}
                                click={onSave}
                            />
                        </div>
                    </div>
                </Modal>
            }

            {
                removeDialogueBox && (
                    <Modal
                        icon={<DeleteIcon />}
                        title={'Remove'}
                        closeEvent={onCancelModal}
                    >
                        <div className="h-16">
                            Are you sure you want to remove{" "}
                            <b>
                                {activeItem?.name}
                            </b>{" "}
                            ?
                        </div>
                        <hr className="my-3" />
                        <div className="text-right ">
                            <ThemedButton
                                children={"Cancel"}
                                icon="cancel"
                                theme="secondary"
                                click={onCancelModal}
                            />
                            <ThemedButton
                                children={"Remove"}
                                icon="remove"
                                theme="error"
                                click={handleDeleteDevice}
                                loading={deleteButtonLoader}
                            />
                        </div>
                    </Modal>
                )
            }
        </div>
    )
}

export default EditCellDevice