import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import timezones from "timezones-list";
import { useTranslation } from "react-i18next";

import "./timezone.css";

const Timezone = (props) => {
  const { node, data, change } = props;
  const { t } = useTranslation();

  const setChange = (value) => {
    let updateData = [...data];
    let filter;

    filter = updateData.filter((n) => {
      return n.name === node.name;
    });

    if (filter[0]) {
      filter[0].value = value;
    }
    change([...updateData]);
  };

  const handleChange = (value) => {
    setChange(value);
  };

  return (
    <div className="input">
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-label">
          {t(node.title) + (node.required ? "* " : " ")}
        </InputLabel>
        <Select
          size="medium"
          style={{ width: "100%" }}
          labelId="demo-simple-select-label"
          id={node.name}
          value={node.value || ""}
          label={t(node.title)}
          disabled={node.disabled}
          error={node.error}
        >
          {timezones.map((node, index) => {
            return (
              <MenuItem
                key={node?.label + index}
                onClick={() => handleChange(node.label.split(" ")[0])}
                value={node.label.split(" ")[0]}
              >
                {node.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default Timezone;
