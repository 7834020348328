import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import TtyIcon from '@mui/icons-material/Tty';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CallSplitIcon from '@mui/icons-material/CallSplit';
import ServerStatusIcon from '@mui/icons-material/Dns';
import SuccsesIcon from '@mui/icons-material/Call';
import FailedIcon from '@mui/icons-material/CallEnd';
import CarrierCallsIcon from '@mui/icons-material/AddIcCall';
import ActiveCallsIcon from '@mui/icons-material/PermPhoneMsg';
import SendIcon from '@mui/icons-material/Send';
import InboxIcon from '@mui/icons-material/Inbox';
import EmailIcon from '@mui/icons-material/Email';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
export const LeftMenuItems = {
    control: [
        {
            item: 'users',
            path: "control/users",
            displayName: 'Users',
            icon: <ManageAccountsIcon />,
            permission: ''
        },
        {
            item: 'groups',
            path: "control/groups/id/tab",
            displayName: 'Groups',
            icon: <Diversity3Icon />,
            permission: ''
        },
        // {
        //     item: 'queues',
        //     path: "control/queues/id/tab",
        //     displayName: 'Queues',
        //     icon: <QueuePlayNextIcon />,
        //     permission: ''
        // },
        {
            item: 'ivr',
            path: "control/ivr/edit",
            displayName: 'IVR',
            icon: <CallSplitIcon />,
            permission: ''
        },
        {
            item: 'officehours',
            path: "control/office_hours/id",
            displayName: 'Office Hours',
            icon: <AccessTimeIcon />,
            permission: ''
        },
        {
            item: 'number',
            path: "control/number/edit",
            displayName: 'MainNumber',
            icon: <RecordVoiceOverIcon />,
            permission: ''
        },
        {
            item: 'calllogs',
            path: "control/calllogs/edit",
            displayName: 'CallLogs',
            icon: <TtyIcon />,
            permission: ''
        },
        {
            item: 'Phones',
            path: "control/Phones",
            displayName: 'Phones',
            icon: <DevicesOtherIcon />,
            permission: ''
        },
        {
            item: 'voicemail',
            path: "control/voicemail",
            displayName: 'VoiceMail',
            icon: <PermPhoneMsgIcon />,
            permission: ''
        },
        // {
        //     item: 'paging',
        //     path: "control/paging",
        //     displayName: 'Paging',
        //     icon: <AutoStoriesOutlinedIcon />,
        //     permission: ''

        // }

    ],
    system: [
        {
            item: 'server',
            path: "system/server/:key",
            displayName: 'Server Status',
            icon: <ServerStatusIcon />,
            permission: ''
        },
        {
            item: 'success_calls',
            path: "system/success_calls",
            displayName: 'Success Calls',
            icon: <SuccsesIcon />,
            permission: ''
        },
        {
            item: 'carrier_calls',
            path: "system/carrier_calls",
            displayName: 'Carrier Calls',
            icon: <CarrierCallsIcon />,
            permission: ''
        },
        {
            item: 'failed_calls',
            path: "system/failed_calls",
            displayName: 'Failed Calls',
            icon: <FailedIcon />,
            permission: ''
        },
        {
            item: 'active_calls',
            path: "system/active_calls",
            displayName: 'Active Calls',
            icon: <ActiveCallsIcon />,
            permission: ''
        }
    ],
    fax: [
        {
            item: 'composefax',
            path: "fax/compose_fax",
            displayName: 'Compose Fax',
            icon: <SendIcon />,
            permission: ''
        },
        {
            item: 'inboundfax',
            path: "fax/inbound_fax",
            displayName: 'Inbound Fax',
            icon: <InboxIcon />,
            permission: ''
        },
        {
            item: 'outboundfax',
            path: "fax/outbound_fax",
            displayName: 'Outbound Fax',
            icon: <EmailIcon />,
            permission: ''
        },
        // {
        //     item: 'emailtofaxes',
        //     path: "fax/emailtofaxes",
        //     displayName: 'Email To Faxes',
        //     icon: <OutboxIcon />,
        //     permission: ''
        // }
    ],
    settings: [
        {
            item: 'general',
            path: "settings/general",
            displayName: 'General Settings',
            icon: <ToggleOnIcon />,
            permission: ''
        },
        // {
        //     item: 'featurecode',
        //     path: "settings/feature_code",
        //     displayName: 'Feature Code ',
        //     icon: <GradingOutlinedIcon />,
        //     permission: ''
        // },
        // {
        //     item: 'audiolibrary',
        //     path: "settings/audio_library",
        //     displayName: 'Audio Library',
        //     icon: <AudioFileIcon />,
        //     permission: ''
        // },
        // {
        //     item: 'notifications',
        //     path: "settings/notifications",
        //     displayName: 'Notifications',
        //     icon: <NotificationsActiveIcon />,
        //     permission: ''
        // },
        // {
        //     item: 'blockednumbers',
        //     path: "settings/blocked_numbers",
        //     displayName: 'Blocked Numbers',
        //     icon: <AppBlockingIcon />,
        //     permission: ''
        // }







    ]
}