import { ActionType } from "../action-types";
import { Action } from '../actions';
interface AuthRepoState {
    loggedUser: {};
    loggedUserRoles: any[];
    accountActiveStep: number;
    authState: boolean
}

const initialState = {
    loggedUser: {},
    loggedUserRoles: [],
    accountActiveStep: 1,
    authState: true
}

const AuthReducer = (
    state: AuthRepoState = initialState,
    action: Action
): AuthRepoState => {

    switch (action.type) {
        case ActionType.SET_USER:
            return {
                ...state,
                loggedUser: action.payload
            };
        case ActionType.SET_USER_ROLE:
            return {
                ...state,
                loggedUserRoles: action.payload
            };
        case ActionType.SET_ACCOUNT_ACTIVE_STATE:
            return {
                ...state,
                accountActiveStep: action.payload
            };
        case ActionType.SET_AUTH_STATE:
            return {
                ...state,
                authState: action.payload
            };
        default:
            return state;
    }
};
export default AuthReducer;
