import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import FadeIn from "react-fade-in";
import Loader from "../../loader";

const SignIn = (props: any) => {
    const { subDomain,signinloader, SigninSubmit, signin, setSignin, tenantvalidation, settenantvalidation } = props;
    const handleKeypress = (e:any) => {
        //it triggers by pressing the enter key
        if (e.key.toLowerCase() === "enter" && signin !== null) {
          if (signin.length >= 3) {
            SigninSubmit();
          }
        }
      };
      const handleSignIn = (value:string) => {
        setSignin(value === ' ' ? '' : value.replace(/\s\s+/g, ' '))
      }
    const tenantvalidationtext = "Invalid Company name";
    return <div className="w-full ">
        <FadeIn>
            <div className="my-6 text-xl tracking-tight leading-tight">
                {subDomain ? "Sign in" : "Company login"}
            </div>
            <div className="mb-4">
                <TextField
                    className="mb-4"
                    label="Company Name"
                    name="company_name"
                    value={signin}
                    onChange={(e) => { handleSignIn(e.target.value); settenantvalidation(false) }}
                    error={tenantvalidation}
                    helperText={
                        tenantvalidation ? tenantvalidationtext : null
                    }
                    autoFocus
                    variant="outlined"
                    required
                    fullWidth
                    onKeyPress={(e) => handleKeypress(e)}
                />
            </div>
            <div >
                <LoadingButton
                    variant="contained"
                    color="primary"
                    className=" w-full mt-10 rounded-[5px] bg-primary"
                    aria-label={subDomain ? "Sign in" : "Submit"}
                    onClick={() => SigninSubmit()}
                    disabled={signin.length <= 2}
                    type="submit"
                    size="large">
                    {!signinloader ? subDomain ? "Sign in" : "Submit" : <Loader size={'sm'} />}
                </LoadingButton>

            </div>
        </FadeIn>
    </div>
}
export default SignIn