import { useState } from 'react'
import Modal from '../../../atom/modal'
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic"
import ComponentMap from '../../../atom/componentmap'
import { t } from 'i18next'
import ThemedButton from '../../../atom/ThemedButton/themedButton'

const HoldMusic = (props: any) => {
    const { setModal } = props
    const [data, setData] = useState([
      {
        name: "holdMusic",
        title: "Music On Hold",
        // step: 4,
        type: "dropdown",
        displayProps: { label: "label", value: "id" },
        childrens: [
          {
            id: 123,
            label: "beep",
          },
          {
            id: 125,
            label: "rubaruu",
          },
          {
            id: 126,
            label: "dududu",
          },
        ],
        layout: 1,
        value: "",
        required: true,
        errormessage: "Please Select Music",
      },
      {
        name: t("File"),
        title: t("File"),
        value: "",
        required: true,
        type: "input",
        errormessage: t("File"),
      },
    ])
  return (
    <div>
      <Modal
        icon={<LibraryMusicIcon />}
        title={"Hold Music"}
        closeEvent={() => {
          setModal("")
        }}
      >
        <ComponentMap data={data} setData={setData} />
        <ThemedButton
          children={"Cancel"}
          icon="cancel"
          theme="secondary"
          click={() => setModal("")}
        />
        <ThemedButton
          children={"Save"}
          icon="update"
          theme="primary"
          click={() => setModal("")}
        />
      </Modal>
    </div>
  )
}

export default HoldMusic