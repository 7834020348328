
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
const BlockHeader = (props: any) => {
    const { title } = props
    const [status, setStatus] = useState(false);

    const onHandleClick = () => {
        navigator.clipboard.writeText(title)
        setStatus(true)
    }
    const onHandleMouseLeave = () => {
        setStatus(false)
    }

    return (
        <div className='flex w-[1000px]'>
            <div className="  p-4"  >
                Realm : {title}
            </div>
            <Tooltip title={status ? "copied!" : "click to copy"}>
                <div className='mt-3.5 cursor-pointer underline' onMouseLeave={onHandleMouseLeave} onClick={onHandleClick}>
                    <ContentCopyIcon sx={{ width: "20px" }} />
                </div>
            </Tooltip>

        </div >
    )
}
export default BlockHeader