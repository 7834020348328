import { useEffect, useState } from 'react'
import Modal from '../../../../atom/modal'
import ComponentMap from '../../../../atom/componentmap';
import ThemedButton from '../../../../atom/ThemedButton/themedButton';
import DevicesIcon from "@mui/icons-material/Devices"
import { actualDataToMapData, addAKeyValueFromRefListItems, addChildrensToMapData } from '../../../../services/utilities';
import Notification from "../../../../atom/Notification";
import { getDeviceMake, getUsers, updateDevice } from '../../../../services/api';
import { useTranslation } from 'react-i18next';
function EditDevices(props: any) {
    const { t } = useTranslation()
    const { setModal, activeItem, childStatus, setchildStatus } = props;
    const [status, setStatus] = useState<any>(false)
    const handleDeviceMakeChange = (make: string, model = '') => {
       // handleDropdown({ make: make }, 'model', model)
        console.log("result")

    }
    const handleModelChange = (value: string) => {
        const result: any = data[10]?.childrens?.find((node: any) => {
            return node.model === value
        })
        setData(addChildrensToMapData([...data], result.line, 'line_no'));
    }

    const [data, setData] = useState([
        {
            name: 'uuid',
            title: 'uuid',
            value: '',
            hidden: true
        },
        {
            title: t("Control.Devices.Name"),
            name: "name",
            layout: 3,
            value: "",
            required: true,
            errormessage: t("Control.Devices.DeviceNameisrequired"),
        },
        {
            title: t("Control.Devices.Username"),
            name: "login",
            layout: 3,
            value: "",
            required: true,
            errormessage: t("Control.Devices.UserNameIsRequired"),
        },
        {
            title: t("Control.Devices.Password"),
            name: "password",
            type: "password",
            layout: 3,
            value: "",
            required: true,
            errormessage: t("Control.Devices.ErrorPassword"),
        },
        {
            name: "user_id.uuid",
            title: t("Control.Devices.AssignedTo"),
            type: "dropdown",
            displayProps: { "label": "label", "value": "uuid" },
            childrens: [],
            layout: 3,
            value: "",
            required: false,
            errormessage: t("Control.Devices.PleaseSelectaPerson"),
        },
        {
            title: t("Control.Devices.Realm"),
            name: "tenant_id.realm",
            layout: 3,
            type: 'label',
            disabled: true,
            value: "",
            required: true,
            errormessage: t("Control.Devices.RealmIsRequired"),
        },
        {
            title: t("Control.Devices.NotifyOnUnregister"),
            name: "notify_on_unregister",
            layout: 6,
            type: "checkbox",
            value: false,
        },
        {
            title: t("Control.Devices.MacAddress"),
            name: "mac_id",
            layout: 3,
            value: "",
            hidden: true,
            required: true,
            errormessage: t("Control.Devices.MacAddressIsRequired"),
        },
        {
            title: t("Control.Devices.DeviceMake"),
            name: "make",
            type: "dropdown",
            displayProps: { "label": "make", "value": "make" },
            childrens: [],
            layout: 3,
            value: "",
            Callback: handleDeviceMakeChange,
            hidden: true,
            required: false,
            errormessage: t("Control.Devices.PleaseSelectADeviceMake"),
        },
        {
            title: t("Control.Devices.DeviceModel"),
            name: "model",
            type: "dropdown",
            displayProps: { "label": "model", "value": "model" },
            childrens: [],
            layout: 3,
            value: "",
            Callback: handleModelChange,
            required: false,
            hidden: true,
            errormessage: t("Control.Devices.PleaseSelectADeviceModal"),
        },
        {
            title: t("Control.Devices.Line"),
            name: "line_no",
            type: "dropdown",
            childrens: [],
            layout: 3,
            value: "",
            required: false,
            hidden: true,
            errormessage: t("Control.Devices.PleaseSelectALine"),
        }
    ])
    const editDeviceDetails = () => {
        if (activeItem?.user_id === null) {
            setData(actualDataToMapData([...data], { ...activeItem, user_id: { uuid: "" } }));
            handleDeviceMakeChange(activeItem.make, activeItem.model)
        }
        else {
            console.log("activeiten", activeItem)
            let copyActiveItem = { ...activeItem }
            if (copyActiveItem.user_id.length === 0 || copyActiveItem.user_id === "none" || copyActiveItem.user_id === null) {
                copyActiveItem.user_id = { uuid: "none" }
            }
            setData(actualDataToMapData([...data], copyActiveItem));
            handleDeviceMakeChange(activeItem.make, activeItem.model)
        }
    }

    const getAssignedValues = () => {
        getUsers({}).then((result: any) => {
            let UpdatedResult = addAKeyValueFromRefListItems(result, "label", ["first_name", "last_name"])
            UpdatedResult = UpdatedResult.filter((node: any) => { return node.status !== "disabled" })
            console.log("resultt", UpdatedResult)

            UpdatedResult.unshift({ label: 'None', uuid: 'none' })
            setData(addChildrensToMapData([...data], UpdatedResult, "user_id.uuid"));


        })
    }
    const handleDeviceMake = () => {
        getDeviceMake({}).then((result: any) => {
            setData(addChildrensToMapData([...data], result, 'make'));

        })
    }


    const onSave = (result: any) => {
        console.log("resultt", result)
        result['user_id'] = result['user_id.uuid']
        delete result['user_id.uuid'];


        if (result.login.includes(" ")) {
            Notification(t("Control.Features.Failed"), "Username Should not contain Whitespace", "danger")

        } else {
            updateDevice(result, result.uuid).then((result: any) => {
                if (result?.data?.parameter) {
                    let msg = result?.data?.reason
                    Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
                } else {
                    Notification("Success", t("Control.Devices.DeviceUpdatedSuccessfully"), "success");

                }
                setStatus(!status)
                setchildStatus(!childStatus)
            })
                .catch((error: any) => {
                    Notification("Error", t("Control.Devices.ErrorInUpdatingDevice"), "danger");
                })
            setModal("")

        }

    }
    const onCancel = () => {
        setModal("")
    }
    useEffect(() => {
        editDeviceDetails()
        getAssignedValues()
        handleDeviceMake()
    }, [activeItem])

    console.log("data", data)
    return (
        <div>
            <Modal
                icon={<DevicesIcon />}
                title={t("Control.Devices.EditSIPDevice")}
                closeEvent={() => {
                    setModal("")
                }}>
                <div className="w-[700px]">
                    <ComponentMap data={data} setData={setData} />
                    <hr className='m-3' />
                    <div className="text-right p-1">
                        <ThemedButton
                            children={t("Cancel")}
                            icon="cancel"
                            theme="secondary"
                            click={onCancel}
                        />
                        <ThemedButton
                            children={t("Save")}
                            icon="update"
                            theme="primary"
                            valiData={data}
                            change={setData}
                            click={onSave}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default EditDevices