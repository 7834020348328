/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useApp } from "../../../../appContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LeftMenuItems } from "../../../../config/leftMenuItems";
import { DashboardItems } from "../../../../config/dashboard";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useSelector } from "react-redux";

const BrudCrubs = (props: any) => {
  const { endNode } = props;
  const appContext = useApp();
  const { t } = useTranslation();
  type MenuItem = {
    item: string;
    path: string;
    displayName: string;
    icon: Element;
    permission: string;
  };

  type LeftMenuItemsType = {
    control: MenuItem[];
    system: MenuItem[];
    fax: MenuItem[];
    settings: MenuItem[];
  };

  const navigate = useNavigate();
  const navigation = useLocation();
  const [menuItems, setMenuItems] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [brudcrubs, setBrudcrubs] = useState<any>([
    { display: "home", path: "home" },
  ]);
  const { routes } = appContext;
  const { loggedUserRoles } = useSelector((state: any) => state.Auth);

  const navigateTo = (nav: any, index: number) => {
    let path = nav;
    if (nav?.basePath) {
      path = nav?.basePath;
    } else if (nav?.path) {
      path = nav?.path;
    } else if (nav?.item) {
      path = nav?.item;
    }
    let result: any = [];
    if (index === 0) {
      navigate(`/app/${path}`, { replace: true });
      setMenuItems([]);
      setActiveIndex(-1);
    } else if (index === 1) {
      if (loggedUserRoles.length) {
        loggedUserRoles.forEach((role: any) => {
          DashboardItems.forEach((node: any) => {
            if (node?.permissions?.includes(role.name)) {
              result.push(node);
            }
          });
        });
      }
      setMenuItems(result);
      setActiveIndex(index);
    }
    // else if (index === 2 && LeftMenuItems[brudcrubs[1].path as "control" || 'system' || 'fax' || "settings"]) {
    //     setMenuItems(LeftMenuItems[brudcrubs[1].path as "control" || 'system' || 'fax' || "settings"])
    //     setActiveIndex(index)
    // }
    else if (
      index === 2 &&
      ["control", "system", "fax", "settings"].includes(brudcrubs[1].path)
    ) {
      const selectedPath = brudcrubs[1].path as keyof LeftMenuItemsType;
      setMenuItems(LeftMenuItems[selectedPath]);
      setActiveIndex(index);
    }
  };

  function contains(target: any, pattern: any) {
    let value = 0;
    pattern.forEach(function (word: any) {
      value = value + target.includes(word);
    });
    return value === 1;
  }
  useEffect(() => {
    let parent: any = navigation.pathname.split("/");
    parent = parent.filter((path: any) => path !== "");
    let path = [{ display: "home", path: "home" }];
    let key: string = "";
    // eslint-disable-next-line array-callback-return
    parent.map((node: any, index: number) => {
      key = key + "/" + node;
      key = key.replace("/app", "");
      const item = routes.find((route) => route.path === key);
      if (item) {
        path.push({ display: item.title, path: item.path.slice(1) });
      } else if (
        !contains(node, ["-", "new", "edit", "app", "id", "tab", "add"])
      ) {
        if (index <= 2) {
          node = node.includes("_") ? node.replace(/_/g, " ") : node;

          path.push({ display: node, path: "" });
        } else {
          node = node.includes("_") ? node.replace(/_/g, " ") : node;
          path.push({ display: node, path: "" });
        }
      }
    });
    setBrudcrubs([...path]);
  }, [navigation.pathname]);

  const wrapperRef: any = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMenuItems([]);
        setActiveIndex(-1);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  return (
    <div ref={wrapperRef}>
      <ol className="max-md:pl-10 flex p-4 items-center w-full space-x-3 text-sm font-medium text-center text-gray-500 bg-white ">
        {brudcrubs.map((brudcrub: any, index: number) => {
          return (
            <li
              key={index}
              onClick={() => navigateTo(brudcrub.path, index)}
              className={`flex relative capitalize items-center ${brudcrubs.length - 1 !== index || endNode !== ""
                ? "max-md:hidden text-primary"
                : "text-ternary-text"
                } cursor-pointer`}
            >
              {brudcrub.display}
              {(brudcrubs.length - 1 !== index || endNode !== "") && (
                <svg
                  aria-hidden="true"
                  className=" w-4 h-4 ml-2 sm:ml-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  ></path>
                </svg>
              )}
              {index === activeIndex && (
                <div className="absolute h-auto w-[200px] text-primary border border-primary-alpha-20 bg-ternary text-left p-0 -ml-6 top-[28px] z-10">
                  <FadeIn>
                    {menuItems.map((item: any, index: number) => {
                      return (
                        <div
                          key={item.item}
                          onClick={() => navigateTo(item, 0)}
                          className="px-4 py-2 cursor-pointer hover:bg-primary-alpha-20"
                        >
                          {t(item.displayName)}
                        </div>
                      );
                    })}
                  </FadeIn>
                </div>
              )}
            </li>
          );
        })}
        {endNode
          ? Array.isArray(endNode)
            ? endNode.map((node, idx) => {
              return (
                <>
                  <li
                    className={`flex capitalize items-center max-w-[240px] text-ellipsis whitespace-nowrap overflow-hidden text-ternary-text`}
                  >
                    {t(node)}
                  </li>
                  {endNode.length - 1 !== idx && (
                    <svg
                      aria-hidden="true"
                      className=" w-4 h-4 ml-2 sm:ml-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  )}
                </>
              );
            })
            : endNode !== "" && (
              <li
                className={`flex capitalize items-center max-w-[240px] text-ellipsis whitespace-nowrap overflow-hidden text-ternary-text`}
              >
                {t(endNode)}
              </li>
            )
          : ""}
      </ol>
    </div>
  );
};
export default BrudCrubs;
