
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "./textbox.css";

const DatePickers = (props) => {
  const { node, data, change } = props;

  const handleChange = async (value) => {
    const Time = new Date().getTimezoneOffset() * 60000;

    const Timestamp = value !== null ? value.$d.getTime() - Time : "";

    let updateData = [...data];
    let filter;
    console.log("node", node);

    if (node.filter === "true") {
      filter = updateData.map((element) => {
        console.log("JKL", element);
        return {
          ...element,
          childrens: element.childrens.filter(
            (subElement) =>
              subElement.name === node.name && subElement.type === node.type
          ),
        };
      });

      console.log("HHK", filter);
      filter.forEach((node) => {
        if (node.childrens.length !== 0) {
          node.childrens[0].value = Timestamp;
        }
      });

      change([...updateData]);
    } else {
      filter = updateData.filter((n) => {
        return n.name === node.name;
      });

      if (filter[0]) {
        filter[0].value = Timestamp;
      }
      change([...updateData]);
    }
  };

  return (
    <div style={node?.position ? null : { position: "relative" }}>
      <div
        className="input datePickerset"
        style={
          node?.position ? null : { position: "absolute", marginTop: "-22px" }
        }
      >
        <div className="w-full">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label={node.title}
                minDate={node.date ? new Date(node.date) : null}
                inputFormat="DD/MM/YYYY"
                views={["year", "month", "day"]}
                disabled={node.disabled ? node.disabled : null}
                value={node.value}
                maxDate={node.maxdate ? new Date(node.maxdate) : null}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField {...params} error={node.error} />
                )}
                PopperProps={{ placement: "bottom" }}
              />
            </Stack>
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};
export default DatePickers;
