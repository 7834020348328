
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import FadeIn from "react-fade-in";
import { useTranslation } from "react-i18next";
import { LeftMenuItems } from "../../../../config/leftMenuItems";

const LeftSideBar = (props: any) => {
  const { currentPathConfiguration } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigation = useLocation();
  const item: any = currentPathConfiguration.path.replace('/', '');
  let SubItem: any = navigation.pathname.split('/')[3] || '';
  SubItem = SubItem?.includes("_") ? SubItem?.replace(/_/g, "") : SubItem //To enable selected highlight for items with "_" in url
  console.log("Subitem=>", SubItem)
  const navigateTo = (nav: string) => {
    navigate(`app/${nav}`, { replace: true });
  };

  return (
    <div className="h-full max-xl:w-[48px] w-[220px] duration-200 ease-out  transition-all flex flex-row bg-primary-alpha-20 dark:bg-ternary text-ternary-text ">
      <div className="flex flex-col w-full bg-white  overflow-hidden">
        <div className='pt-4'>
          {item &&
            <FadeIn>
              {
                LeftMenuItems[item as "control" | "system" | "settings" | "fax"] && LeftMenuItems[item as "control" | "system" | "settings" | "fax"].map((node: any, index: number) => {
                  return <div onClick={() => navigateTo(node.path)} key={node?.item} className={`${SubItem === node.item ? 'bg-primary-alpha-40' : 'hover:bg-primary-alpha-20'} p-2 flex max-xl:pl-3 pl-6 transition:1 relative cursor-pointer`}>
                    <div className='text-primary'>{node.icon}</div>
                    <div className="pl-4  max-xl:hidden overflow-hidden">
                      {t(node.displayName)}
                    </div>
                  </div>
                })
              }
            </FadeIn>
          }
        </div>
      </div>
    </div>
  );
};
export default LeftSideBar;
