/*
 * ENVIRONMENT SETUP VOIFINITY
 */
export const paths: any = {
  production: `voifinity.com`,
  kanimango: `kanimango.com`,
  staging: `staging.voifinity.com`,
  us5: `us5-dev.voifinity.in`,
  us3: `us3-test.voifinity.in`,
  local: `us3-test.voifinity.in`,
  // local: `us5-dev.voifinity.in`,
  // local:`us7-dev.voifinity.in`,
  // local: `us6.voifinity.com`,
  // local: `staging.voifinity.com`,
  // local:`us7-dev.voifinity.in`,
  // local:`us9-dev.voifinity.in`,
  us1: `us1-dev.voifinity.in`,
  us7: `us7-dev.voifinity.in`,
  us9: `us9-dev.voifinity.in`,
};

const us9 = {
  env: "develop",
  baseURL: `https://${paths.us9}/v1/`,
  domain: `${paths.us9}/app`,
  keyCloak: `https://${paths.us5}/auth/`,
  webSocket: `wss://${paths.us9}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.us9}/v1/api/download/`,
};

const us7 = {
  env: "develop",
  baseURL: `https://${paths.us7}/v1/`,
  domain: `${paths.us7}/app`,
  keyCloak: `https://${paths.us5}/auth/`,
  webSocket: `wss://${paths.us7}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.us7}/v1/api/download/`,
};

const us5 = {
  env: "develop",
  baseURL: `https://${paths.us5}/v1/`,
  domain: `${paths.us5}/app`,
  keyCloak: `https://${paths.us5}/auth/`,
  webSocket: `wss://${paths.us5}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.us5}/v1/api/download/`,
};

const us1 = {
  env: "develop",
  baseURL: `https://${paths.us1}/v1/`,
  domain: `${paths.us1}/app`,
  keyCloak: `https://${paths.us5}/auth/`,
  webSocket: `wss://${paths.us1}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.us1}/v1/api/download/`,
};
const us3 = {
  env: "testing",
  baseURL: `https://${paths.us3}/v1/`,
  domain: `${paths.us3}/app`,
  keyCloak: `https://${paths.us5}/auth/`,
  webSocket: `wss://${paths.us3}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.us3}/v1/api/download/`,
};

const local = {
  env: "localhost",
  baseURL: `https://${paths.local}/v1/`,
  domain: `${paths.local}/app`,
  keyCloak: `https://${paths.us5}/auth/`,
  webSocket: `wss://${paths.local}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.local}/v1/api/download/`,
};

const production = {
  env: "production",
  baseURL: `https://${paths.production}/v1/`,
  domain: `${paths.production}/app`,
  keyCloak: `https://${paths.production}/auth/`,
  webSocket: `wss://${paths.production}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.production}/v1/api/download/`,
};
const kanimango = {
  env: "production",
  baseURL: `https://${paths.kanimango}/v1/`,
  domain: `${paths.kanimango}/app`,
  keyCloak: `https://${paths.kanimango}/auth/`,
  webSocket: `wss://${paths.kanimango}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.kanimango}/v1/api/download/`,
};

const staging = {
  env: "staging",
  baseURL: `https://${paths.staging}/v1/`,
  domain: `${paths.staging}/app`,
  keyCloak: `https://${paths.staging}/auth/`,
  webSocket: `wss://${paths.staging}/ws`,
  wssProtocol: `pbx-protocol`,
  download: `https://${paths.staging}/v1/api/download/`,
};

export function getENVData() {
  let text = window.location.hostname;
  if (text.includes(paths.us5)) {
    return us5;
  } else if (text.includes(paths.us3)) {
    return us3;
  } else if (text.includes(paths.us1)) {
    return us1;
  } else if (text.includes(paths.production)) {
    return production;
  } else if (text.includes(paths.staging)) {
    return staging;
  } else if (text.includes(paths.us7)) {
    return us7;
  } else if (text.includes(paths.us9)) {
    return us9;
  } else if (text.includes(paths.kanimango)) {
    return kanimango;
  } else if (text.includes("localhost")) {
    return local;
  } else {
    return production;
  }
}
