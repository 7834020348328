import { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { buyLiveDid, getLiveDid } from '../../../../services/api'
import ComponentMap from '../../../../atom/componentmap'
import ThemedButton from '../../../../atom/ThemedButton/themedButton'
import { t } from 'i18next'
import Notification from '../../../../atom/Notification'

const AvailableNumbers = (props: any) => {
  const { navigation, setModalState ,dashboard,setDashboardModal} = props
  const [search, setSearch] = useState<string>("")
  const [availableNumbers, setAvailableNumbers]: any = useState()
  const [data, setData]: any = useState<any>([])
  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  useEffect(() => {
    if(search.length > 2){
      getLiveDid({ prefix: search }).then((res: any) => {
        if (res.length > -1) {
          setAvailableNumbers(res)
        } else if (res?.reason === "api_error") {
          setAvailableNumbers([])
        }
       })
      .catch((error: any) => {
        console.log("error", error)
      });
    }
  }, [search])

  useEffect(() => {
    if (availableNumbers !== undefined || availableNumbers ) {
      let items: any = []
      availableNumbers.map((item: any) => {
        items.push({ did: item })
      })
      setData([{
        name: "availableNumber",
        displayProps: { "label": "did", "value": "did" },
        layout: 3,
        type: "checkbox",
        childrens: items,
        value: [],
      }])
    }
  }, [availableNumbers])

  const onBuy = () => {
    console.log("onBuy", data[0]?.value)
    buyLiveDid({ numbers: data[0]?.value }).then((res) => {
      Notification(t("Number.Success"), t("Number.NotifiactionNumberbought"), "success")
    })
    if(dashboard){
      setDashboardModal(false)
    }else{
      navigation("") 
      setModalState('') 
    }
  }

  return (
    <div>
      <div className="relative flex w-[100%] mx-auto flex-wrap items-stretch">
        <input
          type="text"
          onChange={(e) => handleSearchChange(e.target.value)}
          value={search}
          className="relative m-0 block w-[1px] min-w-0 flex-auto rounded rounded-r-none bg-hover bg-clip-padding px-3 py-[8px] my-[4px] leading-[1.6]  outline-none transition duration-200 ease-in-out focus:z-[3]"
          placeholder={t("Number.Search")}
        />
        {search === "" ? (
          <span className="input-group-text flex items-center  transition duration-200  whitespace-nowrap rounded rounded-l-none px-2 py-0 text-center  text-base bg-hover font-normal text-secondary my-[4px]">
            <SearchIcon />
          </span>
        ) : (
          <span
            onClick={() => setSearch("")}
            className="input-group-text flex items-center cursor-pointer transition duration-200  whitespace-nowrap rounded rounded-l-none px-2 py-0 text-center  text-base bg-hover font-normal text-primary my-[4px]"
          >
            <CloseIcon />
          </span>
        )}
      </div>
      <div className="max-h-[250px]  overflow-y-scroll ml-5 ">
        <ComponentMap data={data} setData={setData} />
      </div>

      <div className="text-right bg-gray-50 px-4 py-3  sm:px-6 absolute inset-x-0 bottom-0 ">
        <hr className="my-3" />
        <ThemedButton
          children={t("Number.Buy")}
          icon="update"
          theme="primary"
          click={onBuy}
        />
      </div>
    </div>
  )
}

export default AvailableNumbers