import { useEffect, useState } from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn'
import ComponentMap from '../../../atom/componentmap'
import ThemedButton from '../../../atom/ThemedButton/themedButton'
import {
    actualDataToMapData,
    addAKeyValueInListItems,
    addChildrensToMapData,
    updateObjectKeyValue,
} from "../../../services/utilities";
import { getRoles, updateUser, addUser } from '../../../services/api'
import Notification from "../../../atom/Notification"
import Loader from '../../loader'
import { useTranslation } from 'react-i18next';
import Devices from './device';


function UserTabs(props: any) {
    const { t } = useTranslation()
    const [loader, setLoader] = useState(false)

    const { userData,
        handleUserUpdates,
        activeItem,
        dashboard,
        setModal,
        devices,
        getUserDetails,
        addUserStatus,
        setAddUserStatus,
        endNode,
        deviceChange,
        setDeviceChange,
        setEndNode,
        handleActive,
    } = props

    const [activeTab, setActiveTab] = useState<number>(0)

    const defaultValue = [
        {
            name: 'uuid',
            title: 'uuid',
            value: '',
            hidden: true
        },

        {
            title: 'Control.Users.FirstName',
            name: 'first_name',
            value: '',
            layout: 3,
            required: true,
            errormessage: "First Name is required"
        },
        {
            title: 'Control.Users.LastName',
            name: 'last_name',
            value: '',
            layout: 3,
            required: true,
            errormessage: "Last Name is required"
        },
        {
            title: 'Control.Users.UserRole',
            name: 'roles_l',
            multiple: true,
            result: {
                key: "uuid",
            },
            type: 'multiselectdropdown',
            childrens: [
            ],
            value: [],
            hidden: true,
            required: true,
            errormessage: "User Roles is required"
        },
        {
            title: 'Control.Users.Email',
            name: 'email',
            value: '',
            layout: 3,
            inputType: 'float',
            required: true,
            errormessage: "Email is required"
        },
        {
            title: 'Control.Users.AddToCountryDirectory',
            name: "add_to_directory",
            type: "checkbox",
            hidden: true,
            value: true,
        },
    ]


    const [data, setData] = useState(JSON.parse(JSON.stringify(defaultValue)))
    const [loading, setLoading] = useState(true)
    const onSave = (result: any) => {
        setLoader(true)
        let flag = true;
        let flag2 = true;
        let mailFormat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (result.email.match(mailFormat)) {
            flag2 = true;
        }
        else
            flag2 = false;

        if (!flag) {
            Notification("Error", "Extension must be 4 digits", "danger");
            let dummy = [...data]
            dummy[3].error = true;
            setData(dummy)

        }
        if (!flag2) {
            Notification("Error", "Enter a proper email address", "danger");
            let dummy = [...data]
            dummy[5].error = true;
            setData(dummy)
            setLoader(false)

        }
        else if (result.uuid) {

            updateUser(result, result?.uuid).then((res: any) => {
                if (res?.data?.parameter || res?.data?.reason) {
                    let msg = res?.data?.reason
                    Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
                    setLoader(false)
                } else {
                    Notification("Success", "User Details Updated Successfully ", "success");
                    setLoader(false)
                    handleUserUpdates(result?.uuid)
                }
            })
                .catch((error: any) => {
                    Notification("Error", "Error In Updating User", "danger");
                    setLoader(false)
                })
        } else {
            const parameter = {
                "extensions_l": result.extensions_l,
                "first_name": result.first_name,
                "last_name": result.last_name,
                "create_device_auto": true,
                "email": result.email,
                roles_l: ["a6d76d3f-347e-4943-891b-793051551217"]
            }
            addUser(parameter).then((result: any) => {
                if (result?.data?.parameter || result?.data?.reason) {
                    let msg = result?.data.reason
                    Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
                    setLoader(false)
                } else {
                    Notification("Success", "User Added Successfully ", "success");
                    setLoader(false)
                    handleUserUpdates(result.uuid)
                }
            }).catch((error: any) => {
                Notification("Error", "Error In Adding User", "danger");
                setLoader(false)

            })
        }
    }
    const onCancel = () => {
        dashboard ? setModal("") : handleUserUpdates("cancel");
    }
    const handleRole = (mapData: any) => {
        // setLoading(true)
        getRoles({}).then((result: any) => {
            if (result?.data?.parameter || result?.data?.reason) {
                let msg = result.data.reason
                Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
            }
            else {
                const res = addAKeyValueInListItems([...result], 'label', 'name');
                setData(addChildrensToMapData([...mapData], res, 'roles_l'));
                setTimeout(() => {
                    setLoading(false)
                }, 200);
            }
        })
    }

    const handleActiveTab = (tab: number) => {
        setActiveTab(tab)
    }




    useEffect(() => {
        setLoading(true)

        if (userData?.uuid && endNode !== "Add New User") {
            let result = updateObjectKeyValue([...data], 'roles_l', 'hidden', false)
            result = updateObjectKeyValue([...result], 'add_to_directory', 'hidden', false)
            setData(actualDataToMapData([...result], userData));
            handleRole(data);
            console.log("testing=>1", result)

        } else {
            let result = updateObjectKeyValue([...defaultValue], 'roles_l', 'hidden', true)
            result = updateObjectKeyValue([...result], 'extensions_l', 'value', [])
            result = updateObjectKeyValue([...result], 'add_to_directory', 'hidden', true)
            setData(JSON.parse(JSON.stringify(result)))
            handleRole(result)

            console.log("testing=>2", result, data)


            // setLoading(false)
        }


    }, [userData, activeItem, endNode])
    console.log("userData", data, endNode)

    return (
        <div className={`${activeItem?.status === 'disabled' ? 'mt-12' : 'mt-3'}`}>
            {userData?.uuid &&

                <FadeIn>
                    <ul className="flex justify-between w-[100%] h-[46px] text-center transition-all ease-in-out text-gray-500 border-primary-alpha-50 border-[1px] rounded-lg p-1">

                        <li className={`inline-block text-center cursor-pointer w-[100%] border-r  border-r-primary-alpha-50 rounded-md ${activeTab === 0 ? 'bg-primary-alpha-50 dark:bg-hover' : 'hover:bg-primary-alpha-10'}  `}
                            onClick={() => handleActiveTab(0)}>
                            <div className='mt-1'>
                                <a>Basic</a>
                            </div>
                        </li>

                        <li className={`inline-block cursor-pointer w-[100%]  rounded-md ${activeTab === 1 ? 'bg-primary-alpha-50 dark:bg-hover' : 'hover:bg-primary-alpha-10'} `}
                            onClick={() => handleActiveTab(1)}>
                            <div className='mt-1'>
                                <a>SIP Accounts</a>
                            </div>

                        </li>
                    </ul>
                </FadeIn>
            }

            {(activeTab === 0 || !userData?.uuid) ?
                <div className={`${dashboard ? "" : "flex flex-row h-[calc(100vh-210px)] my-2 overflow-y-auto"}`}>
                    <div className={`${dashboard ? "" : "flex justify-center   w-full h-full"}`}>
                        <div className={`${dashboard ? "" : "flex md:w-full xl:w-[800px] flex-col pt-10 "}`}>
                            {loading ?


                                <Loader />
                                :
                                <FadeIn>
                                    <div>
                                        <ComponentMap data={data} setData={setData} />
                                        <hr className=" text-[#0000004d] my-3" />
                                        <div className="text-right  " >
                                            <ThemedButton
                                                children={"Cancel"}
                                                icon="cancel"
                                                theme="secondary"
                                                click={onCancel}
                                            />
                                            <ThemedButton
                                                children={"Save"}
                                                icon="update"
                                                theme="primary"
                                                valiData={data}
                                                disabled={activeItem?.status === "disabled" ? true : false}
                                                loading={loader}
                                                change={setData}
                                                click={onSave}
                                            />
                                        </div>
                                    </div>
                                </FadeIn>
                            }
                        </div>
                    </div>
                </div>
                :
                <Devices
                    devices={devices}
                    endNode={endNode}
                    addUserStatus={addUserStatus}
                    setAddUserStatus={setAddUserStatus}
                    getUserDetails={getUserDetails}
                    activeItem={activeItem}
                    handleUserUpdates={handleUserUpdates}
                    deviceChange={deviceChange}
                    setDeviceChange={setDeviceChange}
                />}
        </div>

    )
}

export default UserTabs