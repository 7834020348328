import {useRef } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const BarGraph = (props) => {
  const chartRef = useRef();

  const handleClick = (event) => {
    console.log("handleClick")
  };
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: props.label,
        data: props.data,
        backgroundColor: props.color,
        hoverOffset: 4,
        borderWidth: props.border,
        barPercentage: 5,
        barThickness: 30,
        maxBarThickness: 30,
        minBarLength: 0,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  return (
    <Bar data={data} ref={chartRef} onClick={handleClick} options={options} />
  );
};
export default BarGraph;
