import { useEffect, useState } from 'react'
import Modal from '../../../../atom/modal';
import ComponentMap from '../../../../atom/componentmap';
import ThemedButton from '../../../../atom/ThemedButton/themedButton';
import DevicesIcon from "@mui/icons-material/Devices"
import { addDevice, getUsers } from '../../../../services/api';
import { addAKeyValueFromRefListItems, addChildrensToMapData } from '../../../../services/utilities';
import Notification from "../../../../atom/Notification";
import { useTranslation } from 'react-i18next';

function AddCellDevice(props: any) {
    const { t } = useTranslation()
    const { setModal, childStatus, setchildStatus, activeItem = {}, getUserDetails, autoProv = false, change, setChange, setUpdatedPhone } = props;
    const [buttonLoader, setButtonLoader] = useState<any>(false)
    console.log("asdfg")
    const [data, setData] = useState([
        {
            name: 'type',
            title: t("Control.Devices.Type"),
            value: 'cell phone',
            hidden: true
        },
        {
            title: t("Control.Devices.Name"),
            name: "name",
            layout: 3,
            value: "",
            required: true,
            errormessage: t("Control.Devices.NameIsRequired"),
        },

        {
            title: t("Control.Devices.Number"),
            name: "login",
            type: "phonenumber",
            co_code: "",
            value: "",
            layout: 3,
            required: true,
            errormessage: t("Control.Devices.NumberisRequired"),
        },
        {
            name: "user_id",
            value: activeItem?.uuid,
            required: false,
            hidden: true,
            errormessage: t("Control.Devices.PleaseSelectaPerson"),
        },
        {
            name: "user_id.uuid",
            title: t("Control.Devices.AssignedTo"),
            type: "dropdown",
            displayProps: { "label": "label", "value": "uuid" },
            childrens: [

            ],
            layout: 3,
            value: "none",
            hidden: false,
            required: true,
            errormessage: t("Control.Devices.PleaseSelectaPerson"),
        }
        // {
        //     title: t("Control.Devices.UseSystemVoicemail"),
        //     name: "systemvoicemail",
        //     layout: 4,
        //     type: "checkbox",
        //     value: false,
        // },
    ])

    const onSave = (result: any) => {

        result['user_id'] = result['user_id.uuid']
        delete result['user_id.uuid'];
        setButtonLoader(true)
        addDevice(result).then((result: any) => {
            if (result?.data?.parameter) {
                let msg = result?.data?.reason
                Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
                setButtonLoader(false)
                setModal("")
            }
            else {
                Notification("Success", t("Control.Devices.DeviceAddedSuccessfully"), "success");
                setButtonLoader(false)
                setModal("")
            }
            setchildStatus(!childStatus)
            if (Object.keys(activeItem).length !== 0) {
                getUserDetails()
                setButtonLoader(false)
                setModal("")
            }
        })
            .catch((error: any) => {
                Notification("Error", t("Control.Devices.ErrorInAddingDevice"), "danger");
                setchildStatus(!childStatus)
                setButtonLoader(false)
                setModal("")
            })
    }

    const onSaveProv = (result: any) => {

        setButtonLoader(true)
        addDevice(result).then((result: any) => {
            if (result?.data?.parameter) {
                let msg = result?.data?.reason
                Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
                setButtonLoader(false)
                // setModal("")
            }
            else {
                Notification("Success", t("Control.Devices.DeviceAddedSuccessfully"), "success");
                setButtonLoader(false)
                setChange(!change)
                setUpdatedPhone(result?.uuid)
            }
            // setchildStatus(!childStatus)
            // if (Object.keys(activeItem).length !== 0) {
            //     getUserDetails()
            setButtonLoader(false)
            //     setModal("")
            // }
        })
            .catch((error: any) => {
                Notification("Error", t("Control.Devices.ErrorInAddingDevice"), "danger");
                // setchildStatus(!childStatus)
                setButtonLoader(false)
                // setModal("")
            })
    }

    const onCancel = () => {
        setModal("")
    }

    const getAssignedValues = () => {
        getUsers({}).then((result: any) => {
            let UpdatedResult = addAKeyValueFromRefListItems(result, "label", ["first_name", "last_name"])
            UpdatedResult = UpdatedResult.filter((node: any) => { return node.status !== "disabled" })
            UpdatedResult.unshift({ label: 'None', uuid: 'none' })
            setData(addChildrensToMapData([...data], UpdatedResult, "user_id.uuid"));
        })
    }

    useEffect(() => {
        if (autoProv) {
            let copyState = [...data]
            console.log(copyState, 'copystate')
            const filteredData = (copyState?.filter((node: any) => {
                return (node.name !== 'user_id.uuid')
            }))
            setData(filteredData)
        }
        else {
            getAssignedValues();

        }
    }, [])

    return (
        <div>
            {autoProv ?
                <div className='flex justify-center items-center mt-1'>
                    <div className="w-[700px] ">
                        <hr className='mb-3' />

                        <ComponentMap data={data} setData={setData} />
                        <div className="text-right ">
                            {/* <ThemedButton
                     children={t("Cancel")}
                     icon="cancel"
                     theme="secondary"
                     click={onCancel}
                 /> */}
                            <ThemedButton
                                children={t("Save")}
                                icon="update"
                                theme="primary"
                                valiData={data}
                                change={setData}
                                disabled={activeItem?.status === "disabled" ? true : false}
                                loading={buttonLoader}
                                click={onSaveProv}
                            />
                        </div>
                    </div>
                </div >
                :

                <Modal
                    icon={<DevicesIcon />}
                    title={t("DashBoard.AddCellDevice")}
                    closeEvent={onCancel}
                >
                    <div className="w-[700px]">
                        <ComponentMap data={data} setData={setData} />
                        <div className="text-right ">
                            <ThemedButton
                                children={t("Cancel")}
                                icon="cancel"
                                theme="secondary"
                                click={onCancel}
                            />
                            <ThemedButton
                                children={t("Save")}
                                icon="update"
                                theme="primary"
                                valiData={data}
                                disabled={activeItem?.status === "disabled" ? true : false}
                                change={setData}
                                loading={buttonLoader}
                                click={onSave}
                            />
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}

export default AddCellDevice;
