import { NumberManagerActionType } from "../action-types";
import { NumberManagerAction } from "../actions";

interface NumberManagerRepoState {
    updateList: boolean;
    telcoData: any;
    telcoId: any;
    clientData: any;
}

const initialState = {
    updateList: false,
    telcoData: [],
    telcoId: "",
    clientData : []
}


const NumberManagerReducer = (
    state: NumberManagerRepoState = initialState,
    action: NumberManagerAction
): NumberManagerRepoState => {
    switch (action.type) {
        case NumberManagerActionType.SET_UPDATE_LIST:
            return {
                ...state,
                updateList: action.payload
            };
        case NumberManagerActionType.SET_TELCO_DATA:
            return {
                ...state,
                telcoData: action.payload
            };
        case NumberManagerActionType.SET_TELCO_ID:
            return {
                ...state,
                telcoId: action.payload
            };
        case NumberManagerActionType.SET_CLIENT_DATA:
            return {
                ...state,
                clientData: action.payload
            };
        default:
            return state;
    }
};
export default NumberManagerReducer;
