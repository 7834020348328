import React from "react"
import { Routes, Route } from "react-router-dom"
import { useApp } from "../appContext"
import Layout1 from "../layout/layout1/layout1"
import Layout2 from "../layout/layout2/layout2"
import FadeIn from "react-fade-in"
import DashBoard from "../containers/dashboard/dashBoard"
const HomePage = React.lazy(() => import("../containers/homePage/homepage"))
const Login = React.lazy(() => import("../containers/auth/login/login"))
const ControlManager = React.lazy(
  () => import("../containers/controlManager/ControlManager")
)
const CarrierManager = React.lazy(
  () => import("../containers/carrierManager/carrierManager")
)
const Branding = React.lazy(() => import("../containers/branding/branding"))
const AccountManager = React.lazy(
  () => import("../containers/accountManager/accountManager")
)
const RatesManager = React.lazy(
  () => import("../containers/ratesManager/RatesManager")
)
const Receptionist = React.lazy(
  () => import("../containers/receptionist/receptionist")
)
const AddressBook = React.lazy(
  () => import("../containers/addressBook/addressBook")
)
const SystemManager = React.lazy(
  () => import("../containers/systemManager/systemManager")
)
const NumberManager = React.lazy(
  () => import("../containers/numberManager/numberManager")
)
const NotificationManager = React.lazy(
  () => import("../containers/notificationManager/notificationManager")
)
const Analytics = React.lazy(
  () => import("../containers/analytics/analytics")
)
const Messages = React.lazy(
  () => import("../containers/messages/messeges")
)

const FaxManager = React.lazy(
  () => import("../containers/faxManager/FaxManager")
)

const GeneralSettings = React.lazy(
  () => import("../containers/generalSettings/generalSettings")
)
const ServicePlan = React.lazy(
  () => import("../containers/servicePlan/ServicePlan")
)


const RouterConfig = () => {
  const appContext = useApp()
  const { layout } = appContext
  const Layout = layout === "layout1" ? <Layout1 /> : <Layout2 />
  return (
    <>
      <Routes>
        <Route element={Layout}>
          <Route
            path="app/auth/login"
            element={
              <React.Suspense fallback={<></>}>
                <Login />
              </React.Suspense>
            }
          />
          <Route
            path="app/home"
            element={
              <React.Suspense fallback={<></>}>
                <HomePage />
              </React.Suspense>
            }
          />
          <Route
            path="app/address/:key"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <AddressBook />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/branding"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <Branding />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/carrier/:key"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <CarrierManager />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/control/*"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <ControlManager />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/dashboard"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <DashBoard />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/reception"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <Receptionist />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/account/:key"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <AccountManager />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/rates"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <RatesManager />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/rates/:key/*"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <RatesManager />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/system/*"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <SystemManager />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/number/:key/*"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <NumberManager />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/notification"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <NotificationManager />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/analytics"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <Analytics />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/settings/*"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <GeneralSettings />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/analytics/*"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <Analytics />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/fax/*"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <FaxManager />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/sms/"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <Messages />
                </FadeIn>
              </React.Suspense>
            }
          />
          <Route
            path="app/"
            element={
              <React.Suspense fallback={<></>}>
                <HomePage />
              </React.Suspense>
            }
          />
          <Route
            path="app/service"
            element={
              <React.Suspense fallback={<></>}>
                <FadeIn>
                  <ServicePlan />
                </FadeIn>
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  )
}
export default RouterConfig
