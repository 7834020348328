
import StoreIcon from '@mui/icons-material/Done';
import ThemedButton from '../../../atom/ThemedButton/themedButton';
import { useEffect, useState } from 'react';

const CookiePolicy = (props: any) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const cPolicy = window.localStorage.getItem("cPolicy");
        if (!(cPolicy && cPolicy === "true")) {
            setShowModal(true)
        }
    }, [])
    const handleCookiePolicy = () => {
        localStorage.setItem("cPolicy", "true");
        setShowModal(false)
    }
    return <>
        {showModal &&
            <section className="text-ternary-text fixed bottom-1 z-[999] w-[100%]">
                <div className="container mx-auto bg-primary rounded-lg border border-primary">
                    <div className="xs:px-10 flex flex-wrap items-center rounded-lg  justify-between bg-[#ffffffcc] py-1 px-2 md:px-8 lg:px-10">
                        <div className="w-full md:w-6/12 lg:w-6/12">
                            <div className="mb-6 md:mb-0">
                                <h4
                                    className="xs:text-xl mb-1 text-xl  text-ternary-text md:text-xl lg:text-xl"
                                >
                                    We use cookies
                                </h4>
                                <p className="text-ternary-text text-base  -mt-2">
                                    Please, accept these sweeties to continue enjoying our App!
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:w-5/12 lg:w-1/3">
                            <div className="flex items-center space-x-3 md:justify-end">

                                <div className="text-right " >
                                    {/* <button
              type="button"
              className="inline-block rounded border-r border-r-hover px-6 pb-2 pt-1 mr-4 leading-normal transition duration-150 ease-in-out hover:text-primary"
              onClick={() => setShowModal(true)}
            >
              Super Telco
            </button> */}
                                    <ThemedButton
                                        children={"Cancel"}
                                        icon="cancel"
                                        theme="secondary"
                                        click={() => setShowModal(false)}
                                    />
                                    <ThemedButton
                                        children={"Accept"}
                                        icon="custom"
                                        iconComponent={<StoreIcon />}
                                        theme="primary"
                                        click={() => handleCookiePolicy()}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
    </>
}
export default CookiePolicy;