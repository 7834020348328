import { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTheme } from "../theme/themeContext";

const RadioButtonsGroup = (props) => {
  const { node, data, change } = props;
  const { theme, getCurrentThemeJSON } = useTheme();
  const [themeJSON, setTheme] = useState({});

  const setChange = (value) => {
    let updateData = [...data];
    let filter;

    filter = updateData.filter((n) => {
      return n.name === node.name;
    });

    if (filter[0]) {
      filter[0].value = value;
    }
    change([...updateData]);
  };

  useEffect(() => {
    getCurrentThemeJSON(theme, setTheme);
  }, [theme]);

  return (
    <div className="row" style={{ display: "flex", width: "100%" }}>
      <FormControl error={node.error}>
        {node.title !== "" && (
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="pl-10 pt-20"
          >
            {node.title}
          </FormLabel>
        )}
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          row
          className="pl-10"
        >
          {node.childrens.map((n, index) => {
            return (
              <FormControlLabel
                key={index}
                value={n.value}
                style={{
                  color: themeJSON.ternaryText,
                }}
                onChange={(e) => setChange(e.target.value)}
                control={
                  <Radio
                    name={node.name}
                    disabled={node.disabled}
                    style={{
                      color: themeJSON.ternaryText,
                    }}
                  />
                }
                checked={node.value === n.value ? true : false}
                label={n.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default RadioButtonsGroup;
