import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const ReccuringTree = (props: any) => {

    const { node, hanldeTreeClick, activeAccount } = props;
    return <>{
        node.map((subNnode: any) => {
            return <div key={subNnode.uuid} className="pt-2 relative">
                <div onClick={() => hanldeTreeClick(subNnode)} className={`${activeAccount === subNnode.name ? 'text-primary' : 'hover:text-primary'} ml-8 capitalize py-1 pb-2 cursor-pointer  border-b border-dotted border-primary-alpha-20`}>
                    <div className='absolute text-primary right-4'>{activeAccount.toLowerCase() === subNnode.name.toLowerCase() ? "Active" : ''}</div>
                    {subNnode.type === 'telco' ? <AdminPanelSettingsIcon /> : <ManageAccountsIcon />} {subNnode.display_name ? subNnode.display_name : subNnode.name}
                </div>
                {subNnode.childs && <ReccuringTree activeAccount={activeAccount} hanldeTreeClick={hanldeTreeClick} node={subNnode.childs} />}
            </div>
        })}
    </>
}
export default ReccuringTree;