
import SearchOffIcon from '@mui/icons-material/SearchOff';

function NoResultFoundSidebar(props: any) {
    const { size } = props
    return (
        <div className={`${size}? p-${size} text-center: p-12 text-center`}>
            <div className="text-primary text-center">
                <SearchOffIcon sx={{ width: "70px", height: "40px" }} />
            </div>
            <h3 className='mt-4'>No Results Found!</h3>
        </div >
    )
}

export default NoResultFoundSidebar