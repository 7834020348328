import { useState } from 'react'
import ComponentMap from '../../../atom/componentmap'
import ThemedButton from '../../../atom/ThemedButton/themedButton'
import Modal from '../../../atom/modal'
import CallIcon from "@mui/icons-material/Call"

const AddCallerId = (props: any) => {
    const { setModal } = props
    const [data, setData] = useState([
      {
        name: "assignedperson",
        title: "Add Caller ID",
        // step: 4,
        type: "dropdown",
        displayProps: { label: "label", value: "id" },
        childrens: [
          {
            id: 123,
            label: "+91 8723939939",
          },
          {
            id: 125,
            label: "+1 273838292",
          },
          {
            id: 126,
            label: "+43 329839989",
          },
        ],
        layout: 1,
        value: "",
        required: true,
        errormessage: "Please Select a Number",
      },
    ])
  return (
    <div>
      <Modal
        icon={<CallIcon />}
        title={"Add Caller ID"}
        closeEvent={() => {
          setModal("")
        }}
      >
        <ComponentMap data={data} setData={setData} />
        <div className="text-right ">
          <ThemedButton
            children={"Cancel"}
            icon="cancel"
            theme="secondary"
            click={() => setModal("")}
          />
          <ThemedButton
            children={"Add"}
            icon="update"
            theme="primary"
            click={() => setModal("")}
          />
        </div>
      </Modal>
    </div>
  )
}

export default AddCallerId