import { useEffect, useState } from 'react'
import Modal from '../../../../atom/modal';
import DevicesIcon from "@mui/icons-material/Devices"
import ComponentMap from '../../../../atom/componentmap';
import ThemedButton from '../../../../atom/ThemedButton/themedButton';
import { addDevice, getUsers } from '../../../../services/api';
import { addAKeyValueFromRefListItems, addChildrensToMapData } from '../../../../services/utilities';
import Notification from "../../../../atom/Notification";
import Devices from '../devices';
import { useTranslation } from 'react-i18next';



function AddSipDevice(props: any) {
    const { t } = useTranslation()

    const { setModal, childStatus, setchildStatus, getUserDetails, activeItem = {}, devices, deviceChange, setDeviceChange } = props;
    const [status, setStatus] = useState<any>('false')
    const [buttonLoader, setButtonloader] = useState<any>(false)
    const [data, setData] = useState([
        {
            title: t("Control.Devices.Name"),
            name: "name",
            layout: 3,
            value: "",
            required: true,
            errormessage: t("Control.Devices.NameIsRequired"),
        },
        {
            name: 'type',
            title: t("Control.Devices.Type"),
            value: 'sip',
            hidden: true
        },

        {
            title: t("Control.Devices.Username"),
            name: "login",
            layout: 3,
            value: "",
            required: true,
            errormessage: t("Control.Devices.UserNameIsRequired"),
        },
        {
            name: "user_id.uuid",
            title: t("Control.Devices.AssignedTo"),
            type: "dropdown",
            displayProps: { "label": "label", "value": "uuid" },
            childrens: [

            ],
            hidden: Object.keys(activeItem).length === 0 ? false : true,
            layout: 3,
            value: "none",
            required: true,
            errormessage: t("Control.Devices.PleaseSelectaPerson"),
        },
        {
            title: t("Control.Devices.NotifyOnUnregister"),
            name: "notify_on_unregister",
            layout: 6,
            type: "checkbox",
            value: false,
        }
    ])






    const onSave = (result: any) => {

        setButtonloader(true)
        result['user_id'] = result['user_id.uuid']
        if (Object.keys(activeItem).length !== 0) {
            result.user_id = activeItem?.uuid
        }

        delete result['user_id.uuid'];
        if (result.login.includes(" ")) {
            Notification(t("Control.Features.Failed"), "Username Should not contain Whitespace", "danger")
            setButtonloader(false)

        } else {
            addDevice(result).then((result: any) => {
                if (result?.data?.parameter || result?.data?.reason) {
                    let msg = result?.data?.reason
                    Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
                    setButtonloader(false)
                    setModal("")
                    setStatus('true')

                } else {
                    Notification("Success", t("Control.Devices.DeviceAddedSuccessfully"), "success");
                    setButtonloader(false)
                    setModal("")
                    setStatus('true')
                }
                setchildStatus(!childStatus)

                if (Object.keys(activeItem).length !== 0 && activeItem?.first_name) {
                    getUserDetails()
                    setButtonloader(false)
                    setModal("")
                    setStatus('true')
                }

            }).catch((error: any) => {
                Notification("Error", "Error", "danger");
                setchildStatus(!childStatus)
                setButtonloader(false)
                setModal("")
                setStatus('true')
            })
        }



    }

    const onCancel = () => {
        setModal("")

    }

    const getAssignedValues = () => {
        getUsers({}).then((result: any) => {
            let UpdatedResult = addAKeyValueFromRefListItems(result, "label", ["first_name", "last_name"])
            UpdatedResult = UpdatedResult.filter((node: any) => { return node.status !== "disabled" })
            UpdatedResult.unshift({ label: 'None', uuid: 'none' })
            // UpdatedResult = updateObjectKeyValue(data, "user_id.uuid", "hidden", location?.pathname.includes("phones") ? false : true)
            setData(addChildrensToMapData([...data], UpdatedResult, "user_id.uuid"));
        })
    }

    useEffect(() => {
        getAssignedValues();

    }, [activeItem])

    return (
        <div>
            <Modal
                icon={<DevicesIcon />}
                title={t("DashBoard.AddSipDevice")}
                closeEvent={() => {
                    setModal("")
                }}
            >
                <div className="w-[700px]">
                    <ComponentMap data={data} setData={setData} />
                    <div className="text-right ">
                        <ThemedButton
                            children={t("Cancel")}
                            icon="cancel"
                            theme="secondary"
                            click={onCancel}
                        />
                        <ThemedButton
                            children={t("Save")}
                            icon="update"
                            theme="primary"
                            valiData={data}
                            change={setData}
                            loading={buttonLoader}
                            click={onSave}
                        />
                    </div>
                </div>
            </Modal>
            {status === 'true' ?
                <Devices status={status} />
                : null
            }

        </div>
    )
}


export default AddSipDevice