import LoginConfig from "../containers/auth/login/login.config";
import ControlManagerConfig from "../containers/controlManager/controlManager.config";
import CarrierManagerConfig from "../containers/carrierManager/carrierManager.config";
import AccountManagerConfig from "../containers/accountManager/accountManager.config";
import ratesManagerConfig from "../containers/ratesManager/ratesManager.config";
import HomeConfig from "../containers/homePage/homePage.config";
import BrandingConfig from "../containers/branding/branding.config";
import ReceptionistConfig from "../containers/receptionist/receptionist.config";
import AddressBookConfig from "../containers/addressBook/addressBook.config";
import SystemManagerConfig from "../containers/systemManager/systemManager.config";
import NumberManagerConfig from "../containers/numberManager/numberManager.config";
import AnalyticsConfig from "../containers/analytics/analytics.config";
import FaxManagerConfig from "../containers/faxManager/faxManagerConfig";
import MessagesConfig from "../containers/messages/messages.config";
import GeneralSettingsConfig from "../containers/generalSettings/generalSettingsConfig";
import ServicePlanConfig from "../containers/servicePlan/servicePlan.config";

export const navigation = [
  {
    title: "Home",
    path: "/home",
    icon: "home",
    transalate: "HOME",
    permissions: ["public"],
    config: HomeConfig,
  },
  {
    title: "Login",
    path: "/auth/login",
    icon: "lock",
    transalate: "Login",
    permissions: ["public"],
    config: LoginConfig,
  },
  {
    title: "Control Manager",
    path: "/control",
    icon: "ssss",
    transalate: "CONTROL",
    permissions: ["private"],
    config: ControlManagerConfig,
  },
  {
    title: "Carrier Manager",
    path: "/carrier",
    icon: "ssss",
    transalate: "CARRIER",
    permissions: ["private"],
    config: CarrierManagerConfig,
  },
  {
    title: "Branding",
    path: "/branding",
    icon: "ssss",
    transalate: "CARRIER",
    permissions: ["private"],
    config: BrandingConfig,
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: "ssss",
    transalate: "CARRIER",
    permissions: ["private"],
    config: CarrierManagerConfig,
  },
  {
    title: "Receptionist",
    path: "/reception",
    icon: "ssss",
    transalate: "RECEPTION",
    permissions: ["private"],
    config: ReceptionistConfig,
  },
  {
    title: "Account Manager",
    path: "/account",
    icon: "ssss",
    transalate: "ACCOUNT",
    permissions: ["super_admin"],
    config: AccountManagerConfig,
  },
  {
    title: "Rates Manager",
    path: "/rates",
    icon: "ssss",
    transalate: "CARRIER",
    permissions: ["private"],
    config: ratesManagerConfig,
  },
  {
    title: "Notifications Manager",
    path: "/notification",
    icon: "ssss",
    transalate: "CARRIER",
    permissions: ["private"],
    config: CarrierManagerConfig,
  },
  {
    title: "System Manager",
    path: "/system",
    icon: "ssss",
    transalate: "SYSTEM",
    permissions: ["private"],
    config: SystemManagerConfig,
  },
  {
    title: "Number Manager",
    path: "/number",
    icon: "ssss",
    transalate: "NUMBER",
    permissions: ["private"],
    config: NumberManagerConfig,
  },
  {
    title: "Address Book",
    path: "/address",
    icon: "ssss",
    transalate: "ADDRESS",
    permissions: ["private"],
    config: AddressBookConfig,
  },
  {
    title: "Analytics",
    path: "/analytics",
    icon: "ssss",
    transalate: "ANALYTICS",
    permissions: ["super_admin"],
    config: AnalyticsConfig,
  },
  {
    title: "Fax Manager",
    path: "/fax",
    icon: "ssss",
    transalate: "FAX",
    permissions: ["private"],
    config: FaxManagerConfig,
  },
  {
    title: "SMS",
    path: "/sms",
    icon: "ssss",
    transalate: "MESSAGES",
    permissions: ["super_admin"],
    config: MessagesConfig,
  },
  {
    title: "Settings",
    path: "/settings",
    icon: "ssss",
    transalate: "SETTINGS",
    permissions: ["private"],
    config: GeneralSettingsConfig,
  },
  {
    title: "Service Plan",
    path: "/service",
    icon: "ssss",
    transalate: "SERVICE",
    permissions: ["super_admin"],
    config: ServicePlanConfig,
  },
];
