import { BrowserRouter } from "react-router-dom";
import './App.css';
import { Provider } from 'react-redux';
import { store } from "./store";
import { ThemeProvider } from './theme/themeContext';
import { AuthProvider } from './navigation/auth/useAuth';
import RouterConfig from './navigation/router.config';
import { AppProvider } from './appContext';
import GetTenant from "./containers/auth/GetTenant";
import AuthUser from "./containers/auth/AuthUser";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

function App() {

  return (
    <div className="App">
      <Provider store={store}>
        <ReactNotifications />
        <BrowserRouter>
          <AuthProvider>
            <ThemeProvider>
              <GetTenant>
                <AuthUser>
                  <AppProvider>
                    <RouterConfig />
                  </AppProvider>
                </AuthUser>
              </GetTenant>
            </ThemeProvider>
          </AuthProvider>
        </BrowserRouter>
      </Provider>

    </div>
  );
}

export default App;
