/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useRef, useState } from 'react'
import ThemedButton from '../../../atom/ThemedButton/themedButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DialerSipOutlinedIcon from '@mui/icons-material/DialerSipOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useTranslation } from "react-i18next";
import AddSipDevice from './modal/addSipDevice';
import AddCellDevice from './modal/addCellDevice';
import EditSipDevices from './modal/editSipDevices';
import { updateDevice, getDevices } from '../../../services/api';
import Notification from "../../../atom/Notification";
import EditCellDevice from './modal/editCellDevice';
import Loader from '../../loader';
import QRCode from "react-qr-code";
import Modal from '../../../atom/modal'
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import ToggleButton from '../../../atom/toggle';
import NoResultFoundSidebar from '../../../atom/noResultFoundSidebar';

function Devices(props: any) {

    const { t } = useTranslation();

    const { setEndNode } = props;
    const [search, setSearch] = useState<string>('')
    const [devices, setDevices] = useState<any>([])
    const [loading, setLoading] = useState<number>(0)
    const [popup, setPopup] = useState<any>("")
    const [searchLoader, setSearchLoader] = useState<boolean>(false);
    const [resultStatus, setResultStatus] = useState<any>(false)
    const [Modal1, setModal] = useState<any>("")
    const [activeItem, setActiveItem] = useState<any>({})
    const [childStatus, setchildStatus] = useState<any>(false)
    const [popupQR, setPopupQR] = useState<any>(false)

    const handleSearchChange = (value: string) => {
        setSearch(value)
        if (value === "")
            onHandleClose()
    }
    const onHandleSearchResult = (e: any) => {

        if (e.key === "Enter") {
            setSearchLoader(true)
            getDevices({ "search": search.trim() }).then((result: any) => {
                if (result.length === 0) {
                    setSearchLoader(false)
                    setResultStatus(true)
                }
                else {
                    setSearchLoader(false)
                    setResultStatus(false)
                    setDevices(result)

                }
            })
        }
    }
    const onHandleClose = () => {

        setSearchLoader(true)
        setSearch("")
        getDeviceDetails()
        setResultStatus(false)
    }
    const getDeviceDetails = () => {

        getDevices({}).then((result: any) => {
            setSearchLoader(false)
            setDevices(result)
            setLoading(1)
        })
    }



    const handleChecked = (node: any, index: number) => {
        let data = [...devices]

        data[index].status = node.status === "enabled" ? "disabled" : "enabled"
        const updateStatus = {
            "status": node.status
        }
        console.log("node.status", node.status);


        setDevices(data)
        updateDevice(updateStatus, node.uuid).then((result: any) => {
        })
            .catch((error: any) => {
                Notification("Error", t("Control.Devices.StatusNotChanged"), "danger");
            })
    }

    const onHandlepopup = () => {
        popup === "device" ? setPopup("") : setPopup("device")

    }


    const wrapperRef = useRef<HTMLInputElement>(null);



    useEffect(() => {

        const handleOutsideClicks = (event: any) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setPopup("");
            }
        };


        document.addEventListener("mousedown", handleOutsideClicks);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClicks);
        };

    }, []);



    useEffect(() => {
        getDeviceDetails()
        setEndNode('')
        setLoading(0)
    }, [childStatus])


    return (
        <div className="flex flex-row h-[calc(100vh-128px)] sm:px-10 my-2">
            {loading === 1 ?
                <>
                    {Modal1 === "deviceSIP" ? (
                        <AddSipDevice setModal={setModal} setchildStatus={setchildStatus} childStatus={childStatus} devices={devices} activeItem={activeItem} />
                    ) : Modal1 === "deviceCell" ? (
                        <AddCellDevice setModal={setModal} setchildStatus={setchildStatus} childStatus={childStatus} />
                    ) : Modal1 === "editSipDevices" ? (
                        <EditSipDevices setModal={setModal} setchildStatus={setchildStatus} childStatus={childStatus} devices={devices} activeItem={activeItem} />
                    ) : Modal1 === "editCellDevices" ? (
                        <EditCellDevice setModal={setModal} setchildStatus={setchildStatus} childStatus={childStatus} devices={devices} activeItem={activeItem} />
                    )
                        : null}
                    {popupQR &&
                        <Modal
                            icon={<QrCode2OutlinedIcon />}
                            title={t("Control.Devices.MobileAppProvisioning")}
                            closeEvent={() => {
                                setPopupQR(false)
                            }}>
                            <div className=' w-[400px]  mb-8'>

                                <div className='text-center content-center'>
                                    {t("Control.Devices.QRCodeText")}
                                </div>
                                <br />
                                <div className="flex justify-center items-center">
                                    <QRCode value="https://netstratum.com" size={170} />
                                </div>

                            </div>
                        </ Modal >
                    }
                    <FadeIn>
                        <div className="flex justify-center lg:w-auto xl:w-[calc(100vw-256px)]">
                            <div className=' mt-4 w-[400px] sm:w-[500px] md:w-[650px] lg:w-auto'>
                                <div className='flex mb-4 '>
                                    <div className="relative mb-2 flex flex-wrap w-[320px]">
                                        <input
                                            type="text"
                                            onChange={(e) => handleSearchChange(e.target.value)}
                                            onKeyDown={(e) => onHandleSearchResult(e)}
                                            value={search}
                                            className="relative m-0 block w-[1px] min-w-0 flex-auto rounded rounded-r-none bg-hover bg-clip-padding px-3 py-[5.5px]  leading-[1.6]  outline-none transition duration-200 ease-in-out focus:z-[3] "
                                            placeholder={t('Search')}
                                        />
                                        {searchLoader ?
                                            <span className="input-group-text flex items-center  transition duration-200  whitespace-nowrap rounded rounded-l-none px-2 py-1 text-center  text-base bg-hover font-normal text-secondary">
                                                <Loader size="sm" />
                                            </span>
                                            :
                                            search === '' ?
                                                <span className="input-group-text flex items-center  transition duration-200  whitespace-nowrap rounded rounded-l-none px-2 py-1 text-center  text-base bg-hover font-normal text-secondary"                                                >
                                                    <SearchIcon />
                                                </span>
                                                : <span onClick={() => onHandleClose()} className="input-group-text flex items-center cursor-pointer transition duration-200  whitespace-nowrap rounded rounded-l-none px-2 py-1 text-center  text-base bg-hover font-normal text-primary"                                                >
                                                    <CloseIcon />
                                                </span>
                                        }
                                    </div>
                                    <div ref={wrapperRef} className='-mt-1 ml-2 relative' onClick={(event) => {
                                        // event.stopPropagation()
                                        // popup === "device" ? setPopup("") : setPopup("device")
                                    }}>
                                        <ThemedButton children={"Add New Phones"} icon="new" theme="primary" click={onHandlepopup} />
                                        {popup === "device" ? (
                                            <div className="w-[200px] bg-secondary z-10 absolute left-50 z-5 rounded-md shadow-lg">
                                                <div
                                                    className="flex w-[200px] h-[50px] hover:bg-primary-alpha-20 rounded-md"
                                                    onClick={() => {
                                                        setModal("deviceSIP")
                                                    }}
                                                >
                                                    <span className="w-[200px]  mx-5 my-auto cursor-pointer">
                                                        {t("DashBoard.AddSipDevice")}
                                                    </span>
                                                </div>
                                                <div
                                                    className="flex w-[200px] h-[50px] hover:bg-primary-alpha-20 rounded-md"
                                                    onClick={() => {
                                                        setModal("deviceCell")
                                                    }}
                                                >
                                                    <span className="w-[150px]  mx-5 my-auto cursor-pointer">
                                                        {t("DashBoard.AddCellDevice")}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>


                                </div>
                                <hr />
                                {resultStatus ?
                                    <div className=' flex justify-center  items-center xl:w-[1000px] h-[calc(100vh-230px)]'>
                                        < NoResultFoundSidebar />
                                    </div>
                                    :
                                    <div className="mt-6 table-fix-head  h-[calc(100vh-230px)] ">
                                        <table className="xl:w-[1000px] overflow-x-auto text-sm text-left text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-ternary-text bg-hover rounded ">
                                                <tr>
                                                    <th scope="col" className="px-6 py-4 text-[15px]">
                                                        {t('Control.Devices.Type')}
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-[15px]">
                                                        <div className="flex items-center">
                                                            {t('Control.Devices.Name')}

                                                            <a href="#"><svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" /></svg></a>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-0 py-3  text-[15px]">
                                                        <div className="flex items-center">
                                                            {t('Control.Devices.User')}

                                                            <a href="#"><svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" /></svg></a>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-[15px]">
                                                        <div className="flex items-center">
                                                            {t('Control.Devices.Enable')}

                                                            <a href="#"><svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" /></svg></a>
                                                        </div>
                                                    </th>

                                                    <th scope="col" className="px-6 py-3 w-max md:w-[100px] text-[15px]">

                                                    </th>

                                                </tr>
                                            </thead>

                                            <tbody >


                                                <>
                                                    {devices?.map((node: any, index: number) => {
                                                        return (
                                                            <tr key={node?.uuid} className="bg-white  bg-white border-b border-hover cursor-pointer hover:bg-hover group group-hover:bg-primary">
                                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                    {node.type === 'sip' ? <DialerSipOutlinedIcon /> : 'cell phone' ? <PhoneIphoneOutlinedIcon /> : ''}
                                                                </th>
                                                                <td className="px-6 py-4">
                                                                    {node?.name ? node?.name : node?.login}

                                                                </td>
                                                                <td className="px-6 py-4">
                                                                    {node?.user_id?.first_name ? node?.user_id?.first_name + " " + node?.user_id?.last_name : "unassigned"}
                                                                </td>
                                                                <td className="px-6 py-4">
                                                                    {/* {console.log("checkvalue", node.status)} */}
                                                                    <ToggleButton click={() => handleChecked(node, index)} checked={node.status === "enabled" ? true : false} />
                                                                </td>

                                                                <td className="pr-4 py-4 text-right  ">
                                                                    {node.type === 'sip' ?
                                                                        <span className='text-primary mr-3 cursor-pointer invisible group-hover:visible' onClick={() => {
                                                                            setModal("editSipDevices");
                                                                            setActiveItem(node)
                                                                        }}><EditIcon /></span>
                                                                        : <span className='text-primary mr-3 cursor-pointer invisible group-hover:visible' onClick={() => {
                                                                            setModal("editCellDevices");
                                                                            setActiveItem(node)
                                                                        }}><EditIcon /></span>}
                                                                    {/* {node.type === 'sip' ?
                                                                        <span className='  cursor-pointer invisible group-hover:visible' onClick={scanQR}><QrCodeScannerOutlinedIcon /></span>
                                                                        : null
                                                                    } */}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>

                                            </tbody>

                                        </table>

                                    </div>
                                }

                            </div>
                        </div>
                    </FadeIn>
                </>
                : loading === 0 ?
                    <div className=" w-[100%] p-4 h-[calc(100vh-112px)] flex justify-center items-center text-center content-center">
                        <Loader />
                    </div>

                    :
                    <div className=" w-[100%] p-4 h-[calc(100vh-112px)] flex justify-center items-center text-center content-center">
                        <div
                            className="mb-4 rounded-lg bg-error px-10 py-5 text-base text-ternary-text"
                            role="alert">
                            <div className='text-bold p-2'>
                                <strong>{t("Account.ErrorMsg")}</strong>
                            </div>
                            <div className='text-bold p-2'>
                                {t("Account.TryAgainMsg")}.
                            </div>
                        </div>
                    </div>

            }
        </div >


    )
}

export default Devices