import Diversity3Icon from '@mui/icons-material/Diversity3';
import EngineeringIcon from '@mui/icons-material/Engineering';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TagIcon from '@mui/icons-material/Tag';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SixtyFpsSelectIcon from '@mui/icons-material/SixtyFpsSelect';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ContactsIcon from '@mui/icons-material/Contacts';
import NotificationsIcon from "@mui/icons-material/Notifications"
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark"
import AnalyticsIcon from '@mui/icons-material/Analytics';
import FaxIcon from '@mui/icons-material/Fax';
import ForumIcon from '@mui/icons-material/Forum';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import voiphawk from "../image/voiphawk.png"

export const DashboardItems = [
  {

    item: "address",
    basePath: "address/edit",
    displayName: "AddressBook",
    primary: "#F39C12",
    bgColor: "bg-[#F39C12]",
    textColor: "text-[#ffffff]",
    icon: <ContactsIcon />,
    // permissions: ["tenant_admin"],
    permissions: [""],


  },
  {
    item: "carrier",
    basePath: "carrier/edit",
    displayName: "CarrierManager",
    primary: "#6695DC",
    bgColor: "bg-[#6695DC]",
    textColor: "text-[#ffffff]",
    icon: <Diversity3Icon />,
    permissions: ["super_admin", "telco_admin"],
  },
  {
    item: "branding",
    displayName: "Branding",
    primary: "#D1913E",
    bgColor: "bg-[#D1913E]",
    textColor: "text-[#ffffff]",
    icon: <BrandingWatermarkIcon />,
    // permissions: ["super_admin", "telco_admin"],
    permissions: [""],


  },
  {
    item: "service",
    displayName: "Service Plan",
    // basePath: "service/edit",
    primary: "#4682A9",
    bgColor: "bg-[#4682A9]",
    textColor: "text-[#ffffff]",
    icon: <LocalAtmOutlinedIcon />,
    permissions: [""],

  },
  {
    item: "control",
    basePath: "control/users",
    displayName: "ControlManager",
    primary: "#1ABC9C",
    bgColor: "bg-[#1ABC9C]",
    textColor: "text-[#ffffff]",
    icon: <EngineeringIcon />,
    permissions: ['tenant_admin'],
  },
  {
    item: "dashboard",
    displayName: "Dashboard",
    primary: "#473337",
    bgColor: "bg-[#473337]",
    textColor: "text-[#ffffff]",
    icon: <DashboardIcon />,
    // permissions: ["tenant_admin"],
    permissions: [""],


  },
  {
    item: "reception",
    displayName: "Receptionist",
    primary: "#70AF85",
    bgColor: "bg-[#70AF85]",
    textColor: "text-[#ffffff]",
    icon: <SupportAgentIcon />,
    permissions: [''],
  },
  {
    item: "analytics",
    displayName: "Analytics",
    primary: "#1E8449",
    bgColor: "bg-[#1E8449]",
    textColor: "text-[#ffffff]",
    icon: <AnalyticsIcon />,
    permissions: [''],

  },
  {
    item: "settings",
    displayName: "Settings",
    primary: "#6F61C0",
    bgColor: "bg-[#6F61C0]",
    textColor: "text-[#ffffff]",
    icon: <SettingsSuggestOutlinedIcon />,
    permissions: ['tenant_admin'],
  },
  {
    item: "account",
    displayName: "AccountManager",
    basePath: "account/edit",
    primary: "#90AB31",
    bgColor: "bg-[#90AB31]",
    textColor: "text-[#ffffff]",
    icon: <AdminPanelSettingsIcon />,
    permissions: ["super_admin", "telco_admin"],
  },
  {
    item: "rates",
    displayName: "RatesManager",
    primary: "#BBA262",
    bgColor: "bg-[#BBA262]",
    textColor: "text-[#ffffff]",
    icon: <SixtyFpsSelectIcon />,
    permissions: ["super_admin", "telco_admin"],
    // permissions: [""],

  },
  {
    item: "system",
    basePath: 'system/server/list',
    displayName: "SystemManager",
    primary: "#466150",
    bgColor: "bg-[#466150]",
    textColor: "text-[#ffffff]",
    icon: <SettingsSuggestIcon />,
    // permissions: [""],
    permissions: [""],

  },
  {
    item: "number",
    basePath: "number/All",
    displayName: "NumberManager",
    primary: "#695E3E",
    bgColor: "bg-[#695E3E]",
    textColor: "text-[#ffffff]",
    icon: <TagIcon />,
    permissions: ["super_admin", "telco_admin"],
  },
  {
    item: "notification",
    displayName: "NotificationManager",
    primary: "#F9795E",
    bgColor: "bg-[#F9795E]",
    textColor: "text-[#ffffff]",
    icon: <NotificationsIcon />,
    // permissions: ["super_admin"],
  },
  {
    item: "fax",
    displayName: "Fax Manager",
    primary: "#227C70",
    bgColor: "bg-[#227C70]",
    textColor: "text-[#ffffff]",
    icon: <FaxIcon />,
    permissions: ['tenant_admin'],
  },
  {
    item: "voiphawk",
    displayName: "Voip Hawk",
    primary: "#222831",
    bgColor: "bg-[#222831]",
    external: "https://voiphawk.com/app/",
    textColor: "text-[#ffffff]",
    icon: <img src={voiphawk} alt="Voiphawk" className='w-5 h-5' />,
    permissions: ['super_admin'],
  }
  , {

    item: "sms",
    displayName: "SMS",
    primary: "#64CCC5",
    bgColor: "bg-[#64CCC5]",
    textColor: "text-[#ffffff]",
    icon: <ForumIcon />,
    // permissions: ["tenant_admin"],
    permissions: [""],

  },

]

