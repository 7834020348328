const transalation = {
  AddressBook: "Address Book",
  CarrierManager: "Carrier Manager",
  ControlManager: "Control Manager",
  Dashboard: "Dashboard",
  Receptionist: "Receptionist",
  SystemManager: "System Manager",
  AccountManager: "Account Manager",
  RatesManager: "Rates Manager",
  NumberManager: "Number Manager",
  Branding: "Branding",
  Users: "Users",
  Groups: "Groups",
  MainNumber: "Numbers",
  CallLogs: "Call Logs",
  Devices: "Devices",
  VoiceMail: "Voice Mail",
  IVR: "IVR",
  NotificationManager: "Notification Manager",
  Settings: "Settings",
  Theme: "Theme",
  Language: "Language",
  SignOut: "Sign Out",
  CompanyName: "Company Name",
  AddressLine1: "Address Line 1",
  AddressLine2: "Address Line 2",
  EMail: "E-mail",
  Cancel: "Cancel",
  Save: "Save",
  Ok: "Ok",
  AddNew_: "Add New {{key}}",
  No_Found: "No {{key}}",
  AccountName: "Account Name",
  Enable: "Enable",
  Disable: "Disable",
  Actions: "Actions",
  Invoices: "Invoices",
  AssignNumber: "Assign Number",
  AddCredit: "Add Credit",
  RemoveUser: "Remove User",
  AddNewExtension: "Add New Extension",
  Unassign: "Unassign",
  Getstartedbycreatinganew: "Get started by creating a new",
  PhoneNumbers: "Phone Numbers",
  Label: "Label",
  No: "No",
  Update: "Update",
  Assign: "Assign",
  Date: "Date",
  Time: "Time",
  From: "From",
  To: "To",
  Duration: "Duration",
  Search: "Search",
  Numbers: "Number",
  UpdatedSuccessfully: "{{key}} Updated Successfully",
  ErrorErrorInUpdating: "Error! Error In Updating {{key}}",
  SomethingWentWrong: "Something went wrong, Please try again later",
  Remove: "Remove",
  Name: "Name",
  Email: "Email",
  Route1: "Route 1*",
  Route2: "Route 2*",

  Control: {
    SomethingWentWrongPleaseTryAgain: "Something went wrong,Please try again",
    Users: {
      FirstName: "First Name",
      LastName: "Last Name",
      Numbers: "Numbers",
      UserRole: "User roles",
      MainExtensionNumber: "Main Extension Number",
      PhoneNumbers: "Phone Numbers",
      AutoProvision: "Auto Provision",
      Devices: "Devices",
      SIPAccounts: "SIP Accounts",
      Features: "Features",
      AddToCompanyDirectory: "Add to company directory",
      CallerIDNumber: "Caller ID Number",
      PleaseSelectCallerId: "Please Select  Caller Id",
      CallForwarding: "Call Forwarding",
      HotDesking: "Hot Desking",
      Voicemails: "Voicemails",
      MusicOnHold: "Music on Hold",
      FindMeFollowMe: "Find Me Follow Me",
      FaxBox: "Fax Box",
      MissedCallAlert: "Missed Call Alert",
      LocalDialing: "Local Dialing",
      SelectCallerId: "Select caller id",
      ActiveFeatures: "Active Features",
      Password: "Password",
      AddNewDevice: "Add New Device",
      Email: "Email",
      PleaseAssignNumberFirst: "Please Assign Number First",
      AddToCountryDirectory: "Add to company directory",
      CreateDeviceAutomatically: "Create device automatically",
      ExtensionAddedSuccessfully: "Extension Added Successfully ",
      ErrorWhileAddingExtensionPleaseTryAgain:
        "Error while Adding  Extension,please try again",
      ExtensionAlreadyExist: "Extension already Exist",
      ExtensionUpdated: "Extension Updated",
      ExtensionRemovedSuccessfully: "Extension Removed Successfully",
      ErrorWhileRemovingExtensionPleaseTryAgain:
        "Error while removing  Extension,please try again",
      ExtensionsMustContainExactlyDigits:
        "Extensions must contain exactly 4 digits.",
      InvalidFormat: "Invalid Format",
    },
    Groups: {
      Members: "Members",
      Extensions: "Extensions",
      PhoneNumbers: "Phone Numbers",
      Features: "Features",
      RingBack: "Ring Back",
      NextCall: "Next call",
      CallerIDPrepend: "Caller ID Prepend",
      AddNewGroup: "Add New Group",
      Distribute: "Distribute",
      ManageUser: "Manage User",
      Group: "Group",
      GroupName: "Group Name",
      Unassign: "Unassign",
      AddfromSpare: "Add From Spare",
      Spare: "Spare",
      RemoveGroup: "Remove Group",
      Search: "Search",
      MembersUpdatedMsg: "Members Updated Successfully",
      NumberAssignedMsg: "Number assigned successfully",
      GroupCreatedMsg: "Group created successfully",
      FourDigitErrorMsg: "Four digit extensions only",
      NameError: "Please enter Name",
      ExtensionNumber: "Extension Number",
      InvalidExtension: "Invalid Extension",
      Users: "Users",
      UsersError: "Users is Required.",
      CallerIdNamePrefix: "Caller id Name Prefix*",
      ErrorCallerId: "Caller id Name Prefix is required",
      CalleridNumberPrefix: "Caller id Number Prefix* ",
      ErrorCallerIdNumber: "Caller id Number is required",
      FillFieldMsg: "Please fill any one of the field",
      PleaseSelectCallerId: "Please Select Caller Id",
      Ifnooneanswers: "If no one answers the call, forward the call to:",
      RingBackMedia: "Ring Back Media",
      UploadMusic: "Upload Music",
      ChooseFile: "Choose File",
      ExtensionUpdate: "Extension Update",
      ExtensionUnassignedSuccesfully: "Extension Unassigned Succesfully",
      ExtensionAlreadyExist: "Extension already Exist",
      AddNewExtension: "Add New Extension",
      FailedtoupdateGroupNames: "Failed to update Group Names",
      GroupNameUpdatedsuccessfully: "Group Name Updated successfully.",
      Groupdeletedsuccessfully: "Group deleted successfully.",
      FailedtodeleteGroup: "Failed to delete Group",
      GroupStatusUpdatedsuccessfully: "Group Status Updated successfully",
      FailedtoupdateGroupStatus: "Failed to update Group Status",
      NoExtensionsFound: "No extensions found",
      GroupFeature: "Group Feature",
      Info: "Info",
      IsDisabledClicktheButtontoenable:
        "is disabled. Click the button to enable",
      AreYouSureWantTo: "Are you sure want to",
      AreYouSureYouWantToRemove: "Are you sure want to remove ",
      MembersUpdatedSuccessfully: "Members Updated Successfully",
      FailedToUpdate: "Failed to update",
      ManageUsers: "Manage Users",
      NumberUnassignedsuccessfully: "Number Unassigned successfully",
      AddFromSpare: "Add from Spare",
      GroupFeatures: {
        callerIdPrependUpdatedSuccesfully:
          "Caller Id Prepend Updated Succesfully",
        ErrorInUpdatingCallerIdPrepend: "Error In Updating Caller Id Prepend",
      },
    },
    Ivr: {
      FallBackUpdated: "Fall Back Updated successfully.",
      IVRRemoved: "IVR Removed Successfully",
      ErrorRemovingIVR: "Error In Removing IVR",
      StatusNotChanged: "Status not changed",
      RemoveIVR: "Remove IVR",
      ConfigureIVR: "Config your IVR",
      VisualGraph: "Visual Graph",
      GraphicalView: "Graphical View",
      FallBack: "Fall Back",
      ConfigureFallback: "Config your fallback",
      EnableMessage: "is disabled. Click the button to enable",
      Confirmation: "Are you sure want to ",
      IVRName: "IVR Name",
      SelectAudioFile: "Select Audio File",
      PleaseSelectAudio: "Please Select Audio",
      PriorityIsRequired: "Priority is required",
      SelectWavFiles: "Please select .wav extension files",
      IVRUpdatedSuccessfully: "IVR Updated successfully",
      IVRAddedSuccessfully: "IVR Added successfully",
      Upload: "Upload",
      FallbackIsRequired: "Fallback is required",
    },
    OfficeHours: {
      NumberUpdatedSuccessfully: "Number updated successfully",
      SomethingWentWrong: "Oops, something went wrong",
      StartHourLessThanEndHour: "Start hour should be less than end hour",
      HoursMissing: "Hours are missing",
      AddOfficeHoursFirst: "Add office hours before adding lunch hours",
      ValuesMissing: "Values are missing",
      NameAlreadyExists: "Name already exists",
      NameIsMissing: "Name is missing",
      HoursRemovedSuccessfully: "Hours removed successfully",
      UserStatusChangedSuccessfully: "User Status Changed Successfully",
      ErrorInChangingUserStatus: "Error In Changing User Status",
      AddNewOfficeHours: "Add New Office Hours",
      OfficeHoursStrategy: "Office Hours Strategy",
      OfficeHolidays: "Office Holidays",
      IncomingCallHandling: "Incoming Call Handling",
      IsDisabledClickToEnable: "is disabled. Click the button to enable",
      NoOfficeHoursStrategyAvailable:
        "There is no office hours strategy available for you",
      GetStartedByAddingNew: "Get started by adding new",
      AddNewOfficeHoursStrategy: "Add new office hours strategy",
      ConfirmDisable: "Are you sure you want to disable?",
      TwentyFourHoursOpenOffice: "24 hours Open Office",
      Monday: "Monday",
      Tuesday: "Tuesday",
      Wednesday: "Wednesday",
      Thursday: "Thursday",
      Friday: "Friday",
      Saturday: "Saturday",
      Sunday: "Sunday",
      ClosedForLunchHours: "Are you closed for lunch hours?",
      OopsMessage:
        "Oops! You can't set office holidays for 24 hours open office strategy",
      ConfirmRemoveHoliday: "Are you sure you want to remove this holiday?",
      AddNewHolidays: "Add New Holidays",
      ClosedForHolidays: "Are you closed for holidays?",
      HolidayName: "Holiday Name",
      Month: "Month",
      Week: "Week",
      Day: "Day",
      DataUpdatedSuccessfully: "Data Updated Successfully",
    },
    CallLogs: {
      All: "All",
      Incoming: "Incoming",
      Outgoing: "Outgoing",
      MissedCall: "Missed",
      Extension: "Internal",
      Status: "Status",
      CallRecordings: "Recordings",
      Start: "Start",
      End: "End",
      Filter: "Filter",
      ErrorEncountered: "Error Encountered",
      NameIsRequired: "Name is required",
      Copied: "copied",
      ClickToCopy: "click to copy",
      ID: "ID",
      CallRecording: "Call Recording",
      Download: "Download",
    },
    Devices: {
      AddCellDevice: "Add Cell Device",
      ErrorInAddingDevice: "Error In Adding Device",
      DeviceAddedSuccessfully: "Device Added Successfully ",
      NumberisRequired: "Number is Required",
      PleaseSelectaPerson: "Please Select a Person",
      DeviceNameisrequired: "SIP Account Name is required",
      DeviceCellNameisrequired: "Cell Phone Name is required",
      UseSystemVoicemail: "Use system voicemail",
      AssignedTo: "Assigned To",
      DeviceName: "SIP Account Name",
      DeviceCellName: "Cell Phone Name",
      Type: "Type",
      Enable: "Enable",
      Name: "Name",
      User: "User",
      Number: "Number",
      AddFromSpare: "Add From Spare",
      ChooseDevice: "Choose Device",
      PleaseSelectADevice: "Please Select a Device",
      DeviceUpdatedSuccessfully: "Device Updated Successfully ",
      ErrorInUpdatingDevice: "Error In Updating Device",
      NameIsRequired: "Name is required",
      Username: "Username",
      UserNameIsRequired: "User Name is required",
      NotifyOnUnregister:
        "Notify on unregister(not recommended for mobile app users)",
      AutoProvision: "Auto provision",
      MacAddress: "Mac Address",
      MacAddressIsRequired: "Mac Address is required",
      DeviceMake: "Device Make",
      PleaseSelectADeviceMake: "Please Select a Device Make",
      DeviceModel: "Device model",
      PleaseSelectADeviceModal: "Please Select a Device Modal",
      Line: "Line",
      PleaseSelectALine: "Please Select a Line",
      AddSIPDevice: "Add SIP Device",
      EditCellDevice: "Edit Cell Phone",
      Password: "Password",
      ErrorPassword: "Password is required",
      Realm: "Realm",
      RealmIsRequired: "Realm is required",
      EditSIPDevice: "Edit SIP Account",
      StatusNotChanged: "Status not changed",
      MobileAppProvisioning: "Mobile App Provisioning",
      QRCodeText:
        "Please use QR code scanner in your mobile app and scan the QR code displayed to provision your softphone.",
    },
    VoiceMail: {
      Name: "Name",
      User: "User",
      Number: "Number",
      Numbers: "Number.Number",
      ExtensionIsRequired: "Extension is required",
      NameIsRequired: "Name is required",
      Pin: "Pin",
      PinIsRequired: " Pin is required",
      VoiceMailUpdatedSuccessfully: "Voice Mail Updated Successfully",
      ErrorInUpdatingVoiceMail: "Error In Updating Voice Mail",
      EditVoicemail: "Edit Voicemail",
    },
    Number: {
      labelUpdateSuccessMsg: "label updated succsessfully",
      TwentyFourHours: "24 hours Open Office",
      CustomHours: "Custom Office Hours",
      SuccsesAssign: "Number assigned succsessfully",
      SuccsesHoursUpdate: "Office Hours Updated succsessfully",
      SuccsesHoursAdd: "Office Hours added succsessfully",
      SuccsesHolidaysUpdate: "Office Holidays updated succsessfully",
      SuccsesHolidaysAdd: "Office Holidays added succsessfully",
      OfficeHours: "Office Hours Strategy",
      OfficeHolidays: "Office Holidays",
      IncomingCallHandling: "Incoming Call Handling",
      Open: "Open",
      Closed: "Closed",
      AddHolidays: "Add Holidays",
      Single: "Single day",
      Range: "Date Range",
      Advanced: "Advanced",
      Month: "Month",
      Day: "Day",
      Week: "Week",
      HolidayName: "Holiday Name",
      From: "From",
      To: "To",
      OpenHours: "Open Hours *",
      AfterHours: "After Hours *",
      HolidayHours: "Holiday Hours *",
      ClosedHours: "Closed Hours *",
      Error: "Error",
      AlreadyExist: "Number Already Exist.",
      NumberAssignedSuccsessfully: "Number assigned succsessfully",
      Assign: "Assign",
      AssignIsRequired: "Assign is required",
      Label: "Label",
      NumberRemovedSuccessfully: "Number removed successfully",
      ThereIsNoNumberAvailable: "Oops! There is no number available for you.",
    },
    PhoneNumbers: {
      AddFromSpare: "Add From Spare",
    },
    Features: {
      RequireKeyPress: "Require key press",
      ForwardDirectCallOnly: "Forward direct call only",
      KeepYourCallerId: "Keep your caller-id",
      HotdeskId: "Hotdesk Id",
      DoesItRequireAPin: "Does it require a PIN",
      AllowLoginToMultipleDevices: "Allow login to multiple devices",
      PleaseAssignNumberFirst: "Please assign number first",
      EnableVoiceMailToEmail: "Enable Voice mail to email",
      CallerIdNumberUpdatedSuccessfully:
        "Caller Id Number Updated Successfully",
      ErrorInUpdatingCallerIdNumber: "Error In Updating Caller Id Number",
      Failed: "Failed",
      UpdatedSuccessfully: "Updated Successfully",
      OnOff: "On/Off",
      FaxToEmail: "Fax To Email",
      EmailToFax: "Email to Fax",
      FaxToEmailIsRequired: "Fax To Email is required",
      EmailToFaxIsRequired: "Email to Fax is required",
      CallForwardingUpdatedSuccessfully: "Call Forwarding Updated Successfully",
    },
    Queue: {
      Agents: "Agents",
      Distribution: "Distribution",
      MusicOnHold: "Music on Hold",
      Name: "Name",
      Extension: "Extension",
      Skill: "Skill",
      Priority: "Priority",
      Number: "Number",
    },
  },
  Number: {
    NoNumberAvailable: "There is no number available for you",
    StartByAddingNew: "Get started by adding new",
    UnassignedSuccessfully: "Number unassigned successfully.",
    AlreadyAssigned: "Did already assigned to another",
    NumberRemovedSuccessfully: "Number removed successfully.",
    DidRemovedSuccessfully: "Did already assigned to another",
    All: "All",
    Number: "Number",
    Country: "Country",
    Resellers: "Reseller(s)",
    Client: "Client",
    Status: "Status",
    AddNewNumber: "Add New Number",
    Search: "Search",
    Unassign: "UNASSIGN",
    Remove: "REMOVE",
    AvailableNumber: "Available Number",
    SpareNumber: "Spare Number",
    SelectCountry: "Select Country",
    Assign: "ASSIGN",
    Cancel: "CANCEL",
    Add: "ADD",
    Buy: "BUY",
    Error: "Error",
    EnterNumber: " Enter Number",
    ErrorCountryRequired: "Country is required",
    ErrorNumberRequired: "Number is Required.",
    Success: "Success",
    NotificationNumberAdd: "Number Added Successfully",
    NotifiactionNumberbought: "Number Bought Succesfully",
    AssignNumber: "Assign Number",
    UnassignModalData: "Are you sure want to Unassign Number ?",
    RemoveModalData: "Are you sure want to Remove Number ?",
  },
  Brand: {
    CompanyName: "Company Name",
    AddressLine1: "Address Line 1",
    AddressLine2: "Address Line 2",
    Email: "E-mail",
    Cancel: "Cancel",
    Save: "Save",
    Logo: "Logo",
    FileTooltip: "File size should be less than 5MB.",
    ErrorUpload: "Please upload an Image.",
    ErrorCompanyName: "Please enter Company Name.",
    ErrorAddress: "Please enter Address.",
    ErrorEmail: "Please Enter Email.",
  },
  Notification: {
    Subject: "Subject",
    Message: "Message",
    Clear: "Clear",
    Submit: "Submit",
  },
  Rates: {
    UploadRates: "Upload Rates",
    AddNewCountry: "ADD NEW COUNTRY",
    CountryName: "Country Name",
    CountryCode: "Country Code",
    Rate: "Rate",
    Actions: "Actions",
    ADD: "Add",
    SelectCountry: "Select Country",
    Uploadordropafilerighthere: "Upload or drop a file right here",
    Successfullyuploaded: " Successfully Uploaded",
    UnsupportedFileError:
      "Unsupported file, Please upload a file with .CSV extension",
    DownloadsampleSheet: "Download sample Sheet",
    AddRateSheet: "Add new Ratesheet",
    EnterNewRatesheet: "Enter Rate Sheet Name",
    InputKeyword: "Input keyword and press enter",
    RateDelete: "Rate has been deleted Successfully",
    AddRate: "Rate has been added Successfully",
    UpdateRate: "Rate has been updated Successfully",
    AddedRateSheet: "Ratesheet has been added Successfully",
    DownloadRateSheet: "Download Ratesheet",
    DeleteRateSheet: " Delete Ratesheet",
    RateSheetDeleted: "Ratesheet has been deleted Successfully",
    DeleteRate: "Delete Rate",
    DeleteRateModalData: "Are you sure you want to delete Rate?",
    DeleteRatesheetModalData: "Are you sure you want to delete Ratesheet?",
    Delete: "Delete",
  },
  Fax: {
    RecipientNumber: "Recipient Number",
    RecipientNumberIsRequired: "Recipient Number is required",
    PleaseUploadAFileWithPdfExtension:
      "Please upload a file with .pdf extension",
    SelectPerson: "Please Select a Person",
    SearchInboundFaxes: "Search Inbound Faxes",
    Received: "Received",
    ReceivingNumber: "Receiving Number",
    Pages: "Pages",
    SendingNumber: "Sending Number",
    SearchOutboundFaxes: "Search Outbound Faxes",
  },
  Carrier: {
    Name: "Name",
    IPAddressDomain: "IP Address / Domain",
    Prefix: "Prefix",
    Port: "Port",
    Priority: "Priority",
    UserName: "User Name",
    Password: "Password",
    ChooseCountries: "Choose Countries",
    AllCountries: "All Countries",
    EnableFax: "Enable Fax",
    SetSIPCustomHeaders: "Set SIP Custom Headers",
    CallerIdType: "Caller ID Type",
    ErrorPriority: "Priority is required",
    ErrorPassword: "Password is required",
    ErrorUserName: "User Name is required",
    ErrorPort: "Port is required",
    ErrorPrefix: "Prefix is required",
    ErrorIPAddressDomain: "Valid IP Address / Domain is required",
    ErrorName: "Name is required",
    SuccessfullMsg: "Carrier Added successfully",
    ErrorMsg: "Oops , something went wrong !",
    UpdateMsg: "Carrier Updated successfully",
    Add: "Add New Carrier",
    Remove: "Remove",
    Search: "Search",
    Cancel: "Cancel",
    Update: "Update",
    Save: "Save",
    EmptyMsg: "No Carriers",
    GetStartedMsg: "Get started by creating a new Carrier.",
    TryAgainMsg: "Please try again later.",
    RemoveMsg: "Are you sure want to remove",
    NameExistMsg: "Name already exist",
    Inbound: "Inbound Carrier",
    Outbound: "Outbound Carrier",
  },
  DashBoard: {
    Users: "Users",
    Devices: "Devices",
    Numbers: "Numbers",
    Credits: "Credits",
    TotalCalls: "Total Calls",
    ActivityLog: "Activity Log",
    Number: "Number",
    Who: "Who",
    Where: "Where",
    CallCharges: "Total Call Charges",
    MinutesUsed: "Total Minutes Used",
    IncomingCalls: "Incoming Calls",
    MissedCalls: "Missed Calls",
    OutgoingCalls: "Outgoing Calls",
    Calls: " Calls",
    Addusers: "Add Users",
    FirstName: "First Name",
    LastName: "Last Name",
    Email: "E-mail",
    Password: "Password",
    MainExtensionNumber: "Main Extension Number",
    CreateDeviceAutomatically: "Create Device Automatically",
    AddSipDevice: "Add SIP Account",
    AddCellDevice: "Add Cell Phone",
    Name: "Name",
    AssignedTo: "Assigned to",
    notifyUnregister:
      "Notify on unregister(not recommended for mobile app users).",
    autoProvision: "Auto provision",
    DeviceName: "Device Name",
    useSystemVoicemail: "Use System Voicemail.",
    BuyNumbers: "Buy Numbers",
    addCredit: "Add Credit",
    Amount: "Amount",
  },
  Account: {
    AccountName: "Account Name",
    workspaceURL: "Workspace URL",
    workspaceInfo:
      "You can change your workspace's URL (web address), but out of courtesy to your fellow Voifinity users, please don't do this often",
    workspaceNote:
      "If you change your workspace URL, Voifinity will automatically redirect from the old to the new address. However, you should still make sure everyone in your workspace knows about the change because the old name will be placed back into the pool and could be used by some other workspace in the future.",
    ErrorName: "Please Enter Name",
    Email: "Email",
    ErrorEmail: "Please Enter Valid Email",
    FirstName: "First Name",
    ErrorFirstName: "Please Enter First Name",
    LastName: "Last Name",
    ErrorLastName: "Please Enter Last Name",
    ContactNumber: "Contact Number",
    ErrorContact: "Please Enter Valid Contact Number",
    ChooseTimezone: "Choose Time zone",
    ErrorTimeZone: "Please Choose a Time zone",
    Address: "Address.",
    ErrorAddress: "Please Enter Address",
    SuccessfullMsg: "Account Added successfully",
    ErrorMsg: "Oops , something went wrong !",
    UpdateMsg: "Account Updated successfully",
    AddNewAccount: "Add New Account",
    Actions: "Actions",
    RemoveAccount: "Remove Account",
    Reseller: "Reseller",
    Account: "Account",
    Plan: "Plan",
    Features: "Features",
    GetStartedMsg: "Get started by creating a new Account.",
    TryAgainMsg: "Please try again later.",
    EmptyMsg: "No Accounts",
    ErrorCarrier: "Please Select a Carrier",
    HelperText: "Click here to update workspace",
    Carriers: "Carriers",
    ErrorCarriers: "Please select a Carrier",
    EmailExists: "Email already exist",
    URLExists: "Workspace URL already exist",
    AccountExists: "Account name already in use",
    DeleteMsg: "Account has been succssesfully deleted",
    Disable: "Are you sure want to disable",
    UrlUpdateMsg: "Workspace url is updated.",
    GoMsg: "Go to new Work Space",
  },
  Address: {
    EnterAddressBookName: "Enter address book name",
    NameIsRequired: "Name is required",
    Company: "Company",
    SpeedDial: "Speed Dial",
    Add: "Add",
    AddNew: "Add new",
    ADD: "ADD",
    NumbersIsRequired: "Numbers is required",
    EmailIsRequired: "Email is required",
    Number: "Number",
    NumberAddedSuccsesfully: "Number added succsesfully",
    NumberEditedSuccsesfully: "Number edited succsesfully",
    NumberDeletedSuccsesfully: "Number deleted succsesfully",
    AddressBookUploadedSuccessfully: "Address book uploaded Successfully",
    AddContact: "Add Contact",
    Import: "Import",
    UploadBook: "Upload Book",
    Extension: "Extension",
    NoCompanyContactsAvailable: "No company contacts available",
    PhoneNumber: "Phone Number",
    SpeedDialIsRequired: "Speed Dial is required",
    SpeedDialAddedSuccsesfully: "Speed Dial added succsesfully",
    SpeedDialEditedSuccsesfully: "Speed Dial edited succsesfully",
    SpeedDialDeletedSuccsesfully: "Speed Dial deleted succsesfully",
    SpeedDialNumber: "Speed Dial Number",
    NoSpeedDialsAvailable: "No speed dials available",
    GetStartedByCreatingANewSpeedDial:
      "Get started by creating a new speed dial.",
  },
};
export default transalation;
