const transalation = {
  AddressBook: "Directorio",
  CarrierManager: "Gerente de transporte",
  ControlManager: "Gerente de control",
  Dashboard: "Panel",
  Receptionist: "Recepcionista",
  SystemManager: "Administrador de sistemas",
  AccountManager: "Gerente de cuentas",
  RatesManager: "Gerente de Tarifas",
  NumberManager: "Administrador de números",
  Branding: "Marca",
  Users: "Usuarias",
  Groups: "Grupos",
  MainNumber: "Números",
  CallLogs: "Registros de llamadas",
  Devices: "Dispositivos",
  VoiceMail: "Mensaje de vozl",
  IVR: "IVR",
  NotificationManager: "Administrador de notificaciones",
  Settings: "Ajustes",
  Theme: "Tema",
  Language: "Idioma",
  SignOut: "desconectar",
  CompanyName: "nombre de empresa",
  AddressLine1: "Dirección Línea 1",
  AddressLine2: "Dirección Línea 2",
  EMail: "Correo electrónico",
  Cancel: "Cancelar",
  Save: "Ahorrar",
  Ok: "OK",
  AddNew_: "Agregar nueva {{key}}",
  No_Found: "No {{key}}",
  AccountName: "Account Name",
  Enable: "Permitir",
  Disable: "Desactivar",
  Actions: "Comportamiento",
  Invoices: "Facturas",
  AssignNumber: "Asignar número",
  AddCredit: "Añadir crédito",
  RemoveUser: "Quitar Usar",
  AddNewExtension: "Agregar nueva extensión",
  Unassign: "Desasignar",
  Getstartedbycreatinganew: "Comienza creando una nueva",
  PhoneNumbers: "Números de teléfono",
  No: "No",
  Label: "Etiqueta",
  search: "buscar",
  Update: "Actualizar",
  Assign: "Asignar",
  Date: "Fecha",
  Time: "Tiempo",
  From: "De",
  To: "A",
  Search: "Buscar",
  Numbers: "número",
  UpdatedSuccessfully: "{{key}} Updated Successfully",
  SomethingWentWrong: "Algo salió mal. Por favor, vuelva a intentarlo",
  Remove: "Eliminar",
  Name: "Nombre",
  Email: "Correo electrónico",
  Route1: "Ruta 1*",
  Route2: "Ruta 2*",

  Control: {
    SomethingWentWrongPleaseTryAgain:
      "Algo salió mal. Por favor, vuelva a intentarlo",

    Users: {
      FirstName: "Nombre de pila",
      LastName: "Apellido",
      UserRole: "Roles del usuario",
      MainExtensionNumber: "Número de extensión principal",
      Number: "número",
      PhoneNumbers: "Números de teléfono",
      AutoProvision: "Provisión automática",
      Devices: "Dispositivos",
      SIPAccounts: "Cuentas sip",
      Features: "Características",
      AddToCompanyDirectory: "Agregar al directorio de la empresa",
      CallerIDNumber: "Número de identificación de llamadas",
      PleaseSelectCallerId: "Seleccione el identificador de llamadas",
      CallForwarding: "Reenvío de llamadas",
      HotDesking: "Escritorio compartido",
      Voicemails: "Correos de voz",
      MusicOnHold: "Música en espera",
      FindMeFollowMe: "Encuéntrame Sígueme",
      FaxBox: "Buzón de fax",
      MissedCallAlert: "Alerta de llamada perdida",
      LocalDialing: "Marcación local",
      SelectCallerId: "Seleccionar identificador de llamadas",
      ActiveFeatures: "Funciones activas",
      Password: "Contraseña",
      AddNewDevice: "Agrega un nuevo dispositivo",
      Email: "Correo electrónico",
      PleaseAssignNumberFirst: "PAsigne el número primero",
      AddToCountryDirectory: "Agregar al directorio de la empresa",
      CreateDeviceAutomatically: "Crear dispositivo automáticamente",
      ExtensionAddedSuccessfully: "Extensión agregada con éxito",
      ErrorWhileAddingExtensionPleaseTryAgain:
        "Error al agregar la extensión, intente nuevamente",
      ExtensionAlreadyExist: "La extensión ya existe",
      ExtensionUpdated: "Extensión actualizada",
      ExtensionRemovedSuccessfully: "Extensión eliminada con éxito",
      ErrorWhileRemovingExtensionPleaseTryAgain:
        "Error al eliminar la extensión, inténtalo de nuevo",
      ExtensionsMustContainExactlyDigits:
        "Las extensiones deben contener exactamente 4 dígitos.",
      InvalidFormat: "Formato inválido",
    },
    Groups: {
      Members: "miembros",
      Extensions: "Extensiones",
      PhoneNumbers: "Números de teléfono",
      Features: "Características",
      RingBack: "Volver a llamar",
      NextCall: "Próxima llamada",
      CallerIDPrepend: "Prefijo de identificador de llamadas",
      AddNewGroup: "Agregar nuevo grupo",
      Distribute: "Distribuir",
      ManageUser: "Administrar usuario",
      Group: "Grupo",
      GroupName: "Nombre del grupo",
      Unassign: "Desasignar",
      AddfromSpare: "Agregar de repuesto",
      Spare: "Repuesto",
      RemoveGroup: "Eliminar grupo",
      Search: "Buscar",
      MembersUpdatedMsg: "Miembros actualizados con éxito",
      NumberAssignedMsg: "Número asignado correctamente",
      GroupCreatedMsg: "Grupo creado con éxito",
      FourDigitErrorMsg: "Solo extensiones de cuatro dígitos",
      NameError: "Por favor ingrese el nombre",
      ExtensionNumber: "Número de extensión",
      InvalidExtension: "Extensión no válida",
      Users: "Usuarias",
      UsersError: "Se requiere usuarios.",
      CallerIdNamePrefix: "Nombre de identificación de llamadas prefijo*",
      ErrorCallerId:
        "Se requiere prefijo de nombre de identificación de llamadas",
      CalleridNumberPrefix: "Prefijo de número de identificación de llamadas* ",
      ErrorCallerIdNumber:
        "Se requiere un número de identificación de llamadas",
      FillFieldMsg: "Llene cualquiera de los campos",
      PleaseSelectCallerId: "Seleccione la identificación de llamadas",
      Ifnooneanswers: "Si nadie responde a la llamada, reenvíe la llamada a:",
      RingBackMedia: "Anillo hacia atrás Medios de comunicación",
      UploadMusic: "Subir música",
      ChooseFile: "Elija el archivo",
      ExtensionUpdate: "Actualización de extensión",
      ExtensionUnassignedSuccesfully: "Extensión asignada con éxito",
      ExtensionAlreadyExist: "Ya existe una extensión",
      AddNewExtension: "Agregar nueva extensión",
      FailedtoupdateGroupNames:
        "No se pudo actualizar los nombres de los grupos",
      GroupNameUpdatedsuccessfully: "Nombre del grupo actualizado con éxito.",
      Groupdeletedsuccessfully: "Grupo eliminado con éxito.",
      FailedtodeleteGroup: "No se pudo eliminar el grupo",
      GroupStatusUpdatedsuccessfully:
        "Estado de grupo actualizado correctamente",
      FailedtoupdateGroupStatus: "No se pudo actualizar el estado del grupo",
      NoExtensionsFound: "No se encontraron extensiones",
      GroupFeature: "Característica grupal",
      Info: "Información",
      IsDisabledClicktheButtontoenable:
        "está desactivado.Haga clic en el botón para habilitar",
      AreYouSureWantTo: "¿Estás seguro de que quieres",
      AreYouSureYouWantToRemove: "Estás seguro de que desea eliminar ",
      MembersUpdatedSuccessfully: "Miembros actualizados con éxito",
      FailedToUpdate: "No se pudo actualizar",
      ManageUsers: "Administrar usuarios",
      NumberUnassignedsuccessfully: "Número sin asignado con éxito",
      AddFromSpare: "Agregar de repuesto",
      GroupFeatures: {
        callerIdPrependUpdatedSuccesfully:
          "Prefijo de identificador de llamadas actualizado con éxito",
        ErrorInUpdatingCallerIdPrepend:
          "Error al actualizar el prefijo del identificador de llamadas",
      },
    },
    Ivr: {
      FallBackUpdated: "Fall Back actualizado con éxito.",
      IVRRemoved: "IVR eliminado con éxito",
      ErrorRemovingIVR: "Error al eliminar IVR",
      StatusNotChanged: "Estado no cambiado",
      RemoveIVR: "Eliminar IVR",
      ConfigureIVR: "Configure su IVR",
      VisualGraph: "Gráfico Visual",
      GraphicalView: "Vista Gráfica",
      FallBack: "Respaldo",
      ConfigureFallback: "Configure su respaldo",
      EnableMessage: "está desactivado. Haga clic en el botón para habilitar",
      Confirmation: "¿Estás seguro de querer",
      IVRName: "Nombre del IVR",
      SelectAudioFile: "Seleccionar archivo de audio",
      PleaseSelectAudio: "Por favor, seleccione audio",
      PriorityIsRequired: "Se requiere prioridad",
      SelectWavFiles: "Por favor, seleccione archivos con extensión .wav",
      IVRUpdatedSuccessfully: "IVR actualizado exitosamente",
      IVRAddedSuccessfully: "IVR añadido exitosamente",
      Upload: "Cargar",
      FallbackIsRequired: "Se requiere Fallback",
    },
    OfficeHours: {
      NumberUpdatedSuccessfully: "Número actualizado exitosamente",
      SomethingWentWrong: "Oops, algo salió mal",
      StartHourLessThanEndHour:
        "La hora de inicio debe ser menor que la hora de fin",
      HoursMissing: "Faltan horas",
      AddOfficeHoursFirst:
        "Agregue horas de oficina antes de agregar horas de almuerzo",
      ValuesMissing: "Faltan valores",
      NameAlreadyExists: "El nombre ya existe",
      NameIsMissing: "Falta el nombre",
      HoursRemovedSuccessfully: "Horas eliminadas exitosamente",
      UserStatusChangedSuccessfully: "Estado de usuario cambiado exitosamente",
      ErrorInChangingUserStatus: "Error al cambiar el estado del usuario",
      AddNewOfficeHours: "Agregar nuevas horas de oficina",
      OfficeHoursStrategy: "Estrategia de Horario de Oficina",
      OfficeHolidays: "Días festivos de la oficina",
      IncomingCallHandling: "Gestión de llamadas entrantes",
      IsDisabledClickToEnable:
        "está deshabilitado. Haz clic en el botón para habilitarlo",
      NoOfficeHoursStrategyAvailable:
        "No hay estrategia de horario de oficina disponible para ti",
      GetStartedByAddingNew: "Comience añadiendo nuevo en Español",
      AddNewOfficeHoursStrategy:
        "Agregar nueva estrategia de horario de oficina",
      ConfirmDisable: "¿Estás seguro de que quieres deshabilitar?",
      TwentyFourHoursOpenOffice: "Oficina abierta las 24 horas",
      Monday: "lunes",
      Tuesday: "martes",
      Wednesday: "miércoles",
      Thursday: "jueves",
      Friday: "viernes",
      Saturday: "sábado",
      Sunday: "domingo",
      ClosedForLunchHours: "¿Están cerrados durante la hora del almuerzo?",
      OopsMessage:
        "¡Vaya! No puedes establecer días festivos para la estrategia de oficina abierta las 24 horas",
      ConfirmRemoveHoliday:
        "¿Estás seguro de que quieres eliminar este día festivo?",
      AddNewHolidays: "Agregar nuevos días festivos",
      ClosedForHolidays: "¿Están cerrados por vacaciones?",
      HolidayName: "Nombre del día festivo",
      Month: "Mes",
      Week: "Semana",
      Day: "Día",
    },
    CallLogs: {
      All: "Toda",
      Incoming: "Entrante",
      Outgoing: "Extrovertida",
      MissedCall: "Llamada perdida",
      Extension: "Interno",
      Duration: "Duración",
      Status: "Estado",
      CallRecordings: "Grabaciones",
      Start: "Comenzar",
      End: "Fin",
      Filter: "Filtrar",
      ErrorEncountered: "Error encontrado",
      NameIsRequired: "Se requiere el nombre",
      Copied: "copiada",
      ClickToCopy: "Haga clic para copiar",
      ID: "IDENTIFICACIÓN",
      CallRecording: "Grabacion de llamada",
      Download: "Descargar",
    },
    Devices: {
      AddCellDevice: "Agregar dispositivo celular",
      ErrorInAddingDevice: "Error al agregar dispositivo",
      DeviceAddedSuccessfully: "Dispositivo agregado con éxito ",
      NumberisRequired: "Se requiere número",
      PleaseSelectaPerson: "Seleccione una persona",
      DeviceNameisrequired: "Se requiere el nombre de la cuenta SIP",
      DeviceCellNameisrequired: "Se requiere el nombre del celular",
      UseSystemVoicemail: "Utilice el correo de voz del sistema",
      AssignedTo: "Asignado a",
      DeviceName: "Nombre de cuenta SIP",
      DeviceCellName: "Nombre del teléfono celular",
      Type: "Tipo",
      Enable: "Permitir",
      Name: "Nombre",
      User: "Usuaria",
      Number: "Número",
      AddFromSpare: "Agregar de repuesto",
      ChooseDevice: "Elija el dispositivo",
      PleaseSelectADevice: "Seleccione un dispositivo",
      DeviceUpdatedSuccessfully: "Dispositivo actualizado con éxito",
      ErrorInUpdatingDevice: "Error en el dispositivo de actualización",
      NameIsRequired: "Se requiere el nombre",
      Username: "Nombre de usuario",
      UserNameIsRequired: "Se requiere nombre de usuario",
      NotifyOnUnregister:
        "Notifique en Unregister (no recomendado para usuarios de aplicaciones móviles)",
      AutoProvision: "Provisión automática",
      MacAddress: "Dirección MAC",
      MacAddressIsRequired: "Se requiere la dirección MAC",
      DeviceMake: "Dispositivo de dispositivos",
      PleaseSelectADeviceMake: "Seleccione un dispositivo.",
      DeviceModel: "Modelo",
      PleaseSelectADeviceModal: "Seleccione un modal de dispositivo",
      Line: "Línea",
      PleaseSelectALine: "Seleccione una línea",
      AddSIPDevice: "Agregar dispositivo SIP",
      EditCellDevice: "Editar dispositivo celular",
      Password: "Contraseña",
      ErrorPassword: "Se requiere contraseña",
      Realm: "Reino",
      RealmIsRequired: "Se requiere reino",
      EditSIPDevice: "Editar dispositivo SIP",
      StatusNotChanged: "Estado no cambiado",
      MobileAppProvisioning: "Aprovisionamiento de aplicaciones móviles",
      QRCodeText:
        "Utilice el escáner de código QR en su aplicación móvil y escanee el código QR que se muestra para aprovisionar su softphone",
    },
    VoiceMail: {
      Name: "Nombre",
      User: "Usuaria",
      Number: "Número",
      Numbers: "Número de número",
      ExtensionIsRequired: "Se requiere una extensión",
      NameIsRequired: "Se requiere el nombre",
      Pin: "Alfiler",
      PinIsRequired: " Se requiere un pin",
      VoiceMailUpdatedSuccessfully: "Correo de voz actualizado correctamente",
      ErrorInUpdatingVoiceMail: "Error al actualizar el correo de voz",
      EditVoicemail: "Editar correo de voz",
    },
    Number: {
      labelUpdateSuccessMsg: "etiqueta actualizada con éxito",
      TwentyFourHours: "24 horas de oficina abierta",
      CustomHours: "Horario de oficina personalizado",
      SuccsesAssign: "Número asignado correctamente",
      SuccsesHoursUpdate: "Horario de oficina actualizado con éxito",
      SuccsesHoursAdd: "Horario de oficina agregado con éxito",
      SuccsesHolidaysUpdate: "Vacaciones de oficina actualizadas con éxito",
      SuccsesHolidaysAdd: "Vacaciones de oficina agregadas con éxito",
      OfficeHours: "Estrategia de horas de oficina",
      OfficeHolidays: "Vacaciones de oficina",
      IncomingCallHandling: "Manejo de llamadas entrantes",
      Open: "Abierto",
      Closed: "Cerrada",
      AddHolidays: "Agregar vacaciones",
      Single: "Día único",
      Range: "Rango de fechas",
      Advanced: "Avanzada",
      Month: "Mes",
      Day: "Día",
      Week: "Semana",
      HolidayName: "Nombre de vacaciones",
      From: "De",
      To: "A",
      OpenHours: "Horarios de apertura *",
      AfterHours: "Fuera de horas *",
      HolidayHours: "Horas de vacaciones *",
      ClosedHours: "Horas cerradas *",
      Error: "Error",
      AlreadyExist: "Number Already Exist.",
      NumberAssignedSuccsessfully: "Número asignado correctamente",
      Assign: "Asignar",
      AssignIsRequired: "Se requiere asignación",
      Label: "Etiqueta",
      NumberRemovedSuccessfully: "Número eliminado con éxito",
      ThereIsNoNumberAvailable: "¡Ups!No hay número disponible para usted.",
    },
    PhoneNumbers: {
      AddFromSpare: "Agregar de repuesto",
    },
    Features: {
      RequireKeyPress: "Requerir pulsación de tecla",
      ForwardDirectCallOnly: "Reenviar solo llamada directa",
      KeepYourCallerId: "Mantenga su identificador de llamadas",
      HotdeskId: "Identificación de escritorio compartido",
      DoesItRequireAPin: "Requiere un PIN?",
      AllowLoginToMultipleDevices:
        "Permitir iniciar sesión en múltiples dispositivos",
      PleaseAssignNumberFirst: "Por favor asigne el número primero",
      EnableVoiceMailToEmail: "Habilitar correo de voz a correo electrónico",
      CallerIdNumberUpdatedSuccessfully:
        "Caller Id Number Updated Successfully",
      ErrorInUpdatingCallerIdNumber:
        "Error al actualizar el número de identificación de llamadas",
      Failed: "Fallido",
      UpdatedSuccessfully: "Actualizado con éxito",
      OnOff: "Encendido / apagado",
      FaxToEmail: "Fax a correo electrónico",
      EmailToFax: "Correo electrónico a fax",
      FaxToEmailIsRequired: "Se requiere fax a correo electrónico",
      EmailToFaxIsRequired: "Se requiere correo electrónico a fax",
      CallForwardingUpdatedSuccessfully:
        "Desvío de llamadas actualizado con éxito",
    },
  },
  Number: {
    NoNumberAvailable: "No hay ningún número disponible para ti",
    StartByAddingNew: "Comience agregando nuevos",
    UnassignedSuccessfully: "Número no asignado con éxito.",
    AlreadyAssigned: "Ya fue asignado a otro.",
    NumberRemovedSuccessfully: "Número eliminado correctamente.",
    DidRemovedSuccessfully: "Ya fue asignado a otro.",

    All: "Todo",
    Number: "número",
    Country: "País",
    Resellers: "Revendedores",
    Client: "Cliente",
    Status: "Estado",
    AddNewNumber: "Agregar nuevo número",
    Search: "Buscar",
    Unassign: "desasignar",
    Remove: "eliminar",
    AvailableNumber: "Número disponible",
    SpareNumber: "Número de repuesto",
    SelectCountry: "Seleccionar país",
    Assign: "Asignar",
    Cancel: "Cancelar",
    Add: "Agregar",
    Buy: "Comprar",
    Error: "Error",

    EnterNumber: "Ingresar número",
    ErrorCountryRequired: "El país es obligatorio.",
    ErrorNumberRequired: "El número es obligatorio.",
    Success: "Éxito",
    NotificationNumberAdd: "Número agregado con éxito",
    NotifiactionNumberbought: "Número comprado con éxito",
    AssignNumber: "Asignar número",
    UnassignModalData: "¿Estás seguro de desasignar número?",
    RemoveModalData: "¿Estás seguro de que quieres eliminar el número?",
  },
  Brand: {
    CompanyName: "nombre de empresa",
    AddressLine1: "Dirección Línea 1",
    AddressLine2: "Línea de dirección 2",
    Email: "Correo electrónico",
    Cancel: "Cancelar",
    Save: "Ahorrar",
    Logo: "Logo",
    FileTooltip: "El tamaño del archivo debe ser inferior a 5 MB.",
    ErrorUpload: "Cargue una imagen",
    ErrorCompanyName: "Introduzca el nombre de la empresa.",
    ErrorAddress: "Ingrese la dirección.",
    ErrorEmail: "Ingrese el correo electrónico.",
  },
  Notification: {
    Subject: "Sujeto",
    Message: "Mensaje",
    Clear: "Claro",
    Submit: "Entregar",
  },
  Rates: {
    UploadRates: "Cargar tarifas",
    AddNewCountry: "AÑADIR NUEVO PAÍS",
    CountryName: "Nombre del país",
    CountryCode: "Código de país",
    Rate: "Tasa",
    Actions: "Comportamiento",
    ADD: "AGREGAR",
    SelectCountry: "Seleccionar país",
    Uploadordropafilerighthere: "Cargue o suelte un archivo aquí",
    Successfullyuploaded: "Cargado con éxito",
    UnsupportedFileError:
      "Archivo no admitido, cargue un archivo con extensión .CSV",
    DownloadsampleSheet: "Descargar hoja de muestra",
    AddRateSheet: "Agregar nueva hoja de tarifas",
    EnterNewRatesheet: "Introduzca el nombre de la hoja de tarifas",
    InputKeyword: "Ingrese la palabra clave y presione enter",
    RateDelete: "La tarifa ha sido eliminada con éxito",
    AddRate: "La tarifa ha sido añadida con éxito",
    UpdateRate: "La tarifa ha sido actualizada con éxito",
    AddedRateSheet: "La hoja de tarifas ha sido añadida con éxito",
    DownloadRateSheet: "Descargar hoja de tarifas",
    DeleteRateSheet: "Eliminar hoja de tarifas",
    RateSheetDeleted: "La hoja de tarifas ha sido eliminada con éxito",
    DeleteRate: "Tasa de eliminación",
    deleteRateModalData: "¿Estás seguro de que quieres eliminar Rate?",
    DeleteRatesheetModalData: "¿Estás seguro de que quieres eliminar Rateheet?",
    Delete: "Borrar",
  },
  Fax: {
    RecipientNumber: "Numéro du destinataire",
    RecipientNumberIsRequired: "Recipient Number is required",
    PleaseUploadAFileWithPdfExtension:
      "Veuillez télécharger un fichier avec l'extension .pdf",
    SelectPerson: "Please Select a Person",
    SearchInboundFaxes: "Rechercher les fax entrants",
    Received: "Reçu",
    ReceivingNumber: "Numéro de réception",
    Pages: "Pages",
    SendingNumber: "Numéro d'envoi",
    SearchOutboundFaxes: "Rechercher les télécopies sortantes",
  },
  Carrier: {
    Name: "Nombre",
    IPAddressDomain: "Dirección IP / dominio",
    Prefix: "Prefijo",
    Port: "Puerto",
    Priority: "Prioridad",
    UserName: "Nombre de usuario",
    Password: "Contraseña",
    ChooseCountries: "Elija países",
    AllCountries: "Todos los países",
    EnableFax: "Habilitar fax",
    SetSIPCustomHeaders: "Establecer encabezados SIP personalizados",
    CallerIdType: "Tipo de identificación de llamadas",
    ErrorPriority: "Se requiere prioridad",
    ErrorPassword: "se requiere contraseña",
    ErrorUserName: "Se requiere nombre de usuario",
    ErrorPort: "Se requiere puerto",
    ErrorPrefix: "Se requiere prefijo",
    ErrorIPAddressDomain: "Se requiere dirección IP / dominio válido",
    ErrorName: "Se requiere el nombre",
    SuccessfullMsg: "Portador agregado con éxito",
    ErrorMsg: "Huy! Algo salió mal !",
    UpdateMsg: "Transportista actualizado con éxito",
    Add: "Agregar nuevo portador",
    Remove: "eliminar",
    Search: "Buscar",
    Cancel: "Cancelar",
    Update: "Actualizar",
    Save: "Ahorrar",
    EmptyMsg: "No hay portadores",
    GetStartedMsg: "Comience creando un nuevo operador.",
    TryAgainMsg: "Por favor, inténtelo de nuevo más tarde.",
    RemoveMsg: "¿Estás seguro de que quieres eliminar",
    NameExistMsg: "El nombre ya existe",
    Inbound: "Portador entrante",
    Outbound: "Portadora",
  },
  DashBoard: {
    Users: "Usuarios",
    Devices: "Dispositivos",
    Numbers: "Números",
    Credits: "Créditos",
    TotalCalls: "Llamadas totales",
    ActivityLog: "Registro de actividades",
    Number: "Número",
    Who: "OMS",
    Where: "Dónde",
    CallCharges: "Cargos totales de llamadas",
    MinutesUsed: "Total de minutos utilizados",
    IncomingCalls: "Llamadas entrantes",
    MissedCalls: "Llamadas perdidas",
    OutgoingCalls: "Llamadas salientes",
    Calls: "Llamadas",
    Addusers: "Agregar usuarios",
    FirstName: "Nombre de pila",
    LastName: "Apellido",
    Email: "Correo electrónico",
    Password: "Contraseña",
    MainExtensionNumber: "Número de extensión principal",
    CreateDeviceAutomatically: "Crear dispositivo automáticamente",
    AddSipDevice: "Agregar cuenta SIP",
    AddCellDevice: "Agregar teléfono celular",
    Name: "Nombre",
    AssignedTo: "Asignado a",
    notifyUnregister:
      "Notificar al darse de baja (no recomendado para usuarios de aplicaciones móviles).",
    autoProvision: "Aprovisionamiento automático",
    DeviceName: "Nombre del dispositivo",
    useSystemVoicemail: "Usar el correo de voz del sistema",
    BuyNumbers: "Comprar números",
    addCredit: "Añadir crédito",
    Amount: "Cantidad",
  },

  Account: {
    AccountName: "Nombre de la cuenta",
    workspaceURL: "URL del espacio de trabajo",
    workspaceInfo:
      "Puede cambiar la URL (dirección web) de su espacio de trabajo, pero por cortesía a sus compañeros usuarios de Voifinity, no haga esto a menudo",
    workspaceNote:
      "Si cambia la URL de su espacio de trabajo, Voifinity redirigirá automáticamente de la dirección anterior a la nueva.Sin embargo, aún debe asegurarse de que todos en su espacio de trabajo conozcan el cambio porque el antiguo nombre se volverá a colocar en la piscina y podría ser utilizado por algún otro espacio de trabajo en el futuro.",
    ErrorName: "Por favor ingrese el nombre",
    Email: "Correo electrónico",
    ErrorEmail: "Por favor ingrese el correo electrónico",
    FirstName: "Nombre de pila",
    ErrorFirstName: "Por favor ingrese el primer nombre",
    LastName: "Apellido",
    ErrorLastName: "Por favor ingrese el apellido",
    ContactNumber: "Número de contacto",
    ErrorContact: "Ingrese el número de contacto",
    ChooseTimezone: "Elija TimeZone",
    ErrorTimeZone: "Elija una zona horaria",
    Address: "Dirección",
    ErrorAddress: "Ingrese la dirección",
    SuccessfullMsg: "Cuenta agregada con éxito",
    ErrorMsg: "Huy! Algo salió mal !",
    UpdateMsg: "Cuenta actualizada con éxito",
    AddNewAccount: "Agregar nueva cuenta",
    Actions: "Comportamiento",
    RemoveAccount: "Eliminar cuenta",
    Reseller: "Revendedoras",
    Account: "Cuenta",
    Plan: "Plan",
    Features: "Características",
    GetStartedMsg: "Comience creando una nueva cuenta.",
    TryAgainMsg: "Por favor, inténtelo de nuevo más tarde.",
    EmptyMsg: "Sin cuentas",
    ErrorCarrier: "Seleccione un transportista",
    HelperText: "Haga clic aquí para actualizar el espacio de trabajo",
    Carriers: "Operador",
    ErrorCarriers: "Seleccione un operador",
    EmailExists: "Ya existe el correo electrónico",
    URLExists: "La URL del espacio de trabajo ya existe",
    AccountExists: "Nombre de la cuenta ya en uso",
    DeleteMsg: "La cuenta se ha eliminado sucesivamente",
    Disable: "¿Estás seguro de que quieres deshabilitar?",
    UrlUpdateMsg: "Se actualiza la URL del espacio de trabajo.",
    GoMsg: "Ir a un nuevo espacio de trabajo",
  },

  Address: {
    EnterAddressBookName: "Ingrese el nombre de la libreta de direcciones",
    NameIsRequired: "Se requiere el nombre",
    Company: "Compañía",
    SpeedDial: "Dial rápido",
    Add: "Agregar",
    AddNew: "Agregar nuevo",
    ADD: "AGREGAR",
    NumbersIsRequired: "Se requieren números",
    EmailIsRequired: "Se requiere correo electrónico",
    Number: "Número",
    NumberAddedSuccsesfully: "Número agregado con éxito",
    NumberEditedSuccsesfully: "Número editado con éxito",
    NumberDeletedSuccsesfully: "Número eliminado con éxito",
    AddressBookUploadedSuccessfully: "Libra de direcciones cargada con éxito",
    AddContact: "Agregar contacto",
    Import: "importar",
    UploadBook: "Subir libro",
    Extension: "Extensión",
    NoCompanyContactsAvailable: "No hay contactos de la empresa disponibles",
    PhoneNumber: "Número de teléfono",
    SpeedDialIsRequired: "Se requiere la esfera rápida",
    SpeedDialAddedSuccsesfully: "Se agregó con éxito la esfera rápida",
    SpeedDialEditedSuccsesfully: "Speed dial editado con éxito",
    SpeedDialDeletedSuccsesfully: "Dial rápido eliminado con éxito",
    SpeedDialNumber: "Número de dial rápido",
    NoSpeedDialsAvailable: "No diales rápidos disponibles",
    GetStartedByCreatingANewSpeedDial:
      "Comience creando una nueva esfera rápida.",
  },
};
export default transalation;
