import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Login from "./login/login";
import axios from "axios";
import { actionCreators } from "../../store";

function GetTenant(props: any) {
    const [keyCloak, setKeyCloak] = useState<any>({});
    const [authState, setAuthState] = useState("init");
    const [children, setChildren] = useState(props.children);
    const [keyCloakState, setKeyCloakState] = useState<any>(false);
    const dispatch = useDispatch();

    //@ts-ignore
    const eventLogger = (event: any, error: any) => {
        setChildren(
            React.Children.map(props.children, (child) => {
                return React.cloneElement(child, {
                    authState: false,
                    setAuthState
                });
            })
        );
    };

    const tokenLogger = async (tokens: any) => {
        
        let state = "loading";
        if (tokens.token) {
            state = "success";
            localStorage.setItem("ACCESSKEY", tokens.token);
            dispatch(actionCreators.setUser(tokens.token))
            axios.defaults.headers.post["X-Auth-Token"] = tokens.token;
            axios.defaults.headers.put["X-Auth-Token"] = tokens.token;
            axios.defaults.headers.delete["X-Auth-Token"] = tokens.token;
            axios.defaults.headers.get["X-Auth-Token"] = tokens.token;
            
        } else {
            const keycloak_config = window.localStorage.getItem("keycloak_config");
            if (keycloak_config) {
                state = "loading";
            } else {
                state = "failed";
            }
        }
        setAuthState(state);
        setChildren(
            React.Children.map(props.children, (child) => {
                return React.cloneElement(child, {
                    authState: state,
                    setAuthState
                });
            })
        );
    };
    useEffect(() => {
        setChildren(
            React.Children.map(props.children, (child) => {
                return React.cloneElement(child, {
                    authState: 'init',
                    setAuthState
                });
            })
        );

    }, [])
    
    return <>{(authState === "success" || authState === "loading") ?
        <ReactKeycloakProvider
            authClient={keyCloak}
            onEvent={eventLogger}
            onTokens={tokenLogger}>
            {children}
        </ReactKeycloakProvider>
        : <Login setKeyCloak={setKeyCloak} setAuthState={setAuthState} setKeyCloakState={setKeyCloakState} />}</>;
}
export default GetTenant;
