import { useEffect, useState } from 'react'
import ComponentMap from '../../../../atom/componentmap';
import ThemedButton from '../../../../atom/ThemedButton/themedButton';
import { addChildrensToMapData } from '../../../../services/utilities';
import Notification from "../../../../atom/Notification";
import DevicesIcon from "@mui/icons-material/Devices"
import Modal from '../../../../atom/modal';
import { updateDevice, getSpareDevices } from '../../../../services/api';
import { useTranslation } from 'react-i18next';



function AddSpareDevice(props: any) {
    const { t } = useTranslation()
    const { getUserDetails, activeItem, setModal, childStatus, setchildStatus, deviceChange, setDeviceChange } = props;
    const [buttonLoader, setButtonLoader] = useState<any>(false)

    const onCancel = () => {
        setModal("")

    }

    const [data, setData] = useState([

        {
            name: "name",
            title: t("Control.Devices.ChooseDevice"),
            type: "dropdown",
            displayProps: { label: "name", value: "uuid" },
            childrens: [

            ],
            value: "",
            required: true,
            errormessage: t("Control.Devices.PleaseSelectADevice"),
        },

    ])


    const getDeviceDetails = () => {

        getSpareDevices({}).then((result: any) => {
            if (result?.data?.parameter || result?.data?.reason) {
                let msg = result?.data?.reason
                Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
            }
            else {
                console.log("devioces", result)
                result = result?.filter((node: any) => {
                    return (node?.name !== null && node?.type === "sip")
                })
                console.log("resultt", result)
                setData(addChildrensToMapData([...data], result, "name"))

                getUserDetails()
            }
        }).catch((error: any) => {
            Notification(t("Number.Error"), "Oops! Something Went Wrong", "danger");

        })
    }


    const onSave = (result: any) => {

        setButtonLoader(true)
        updateDevice({ user_id: activeItem?.uuid }, result?.name).then((result: any) => {
            if (result?.data?.parameter || result?.data?.reason) {
                let msg = result?.data?.reason
                Notification(t("Control.Features.Failed"), msg?.charAt(0).toUpperCase() + msg?.slice(1), "danger")
            } else {
                Notification(t("Number.Success"), t("Control.Devices.DeviceUpdatedSuccessfully"), "success");
                getUserDetails()
                setchildStatus(!childStatus)
                setButtonLoader(false)
                setModal("")
                setDeviceChange(!deviceChange)
            }

        })
            .catch((error: any) => {
                Notification(t("Number.Error"), t("Control.Devices.ErrorInUpdatingDevice"), "danger");
                setButtonLoader(false)
                setModal("")
            })
    }

    useEffect(() => {
        getDeviceDetails()
    }, [])

    return (
        <div>
            <Modal
                icon={<DevicesIcon />}
                title={t("Control.Groups.AddfromSpare")}
                closeEvent={onCancel}
            >

                <div className="w-[500px]">
                    <ComponentMap data={data} setData={setData} />
                    <div className="text-right ">
                        <ThemedButton
                            children={"Cancel"}
                            icon="cancel"
                            theme="secondary"
                            click={onCancel}
                        />
                        <ThemedButton
                            children={"Save"}
                            icon="update"
                            theme="primary"
                            valiData={data}
                            change={setData}
                            loading={buttonLoader}
                            click={onSave}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AddSpareDevice