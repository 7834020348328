import { remove, put, post } from "./api.service";

export async function getChildrens(payload: any, skip = false) {
  const url = `getChildrens`;
  const result = post(url, payload, { skipHeader: skip });
  return result;
}
export async function getTelco(uuid: string) {
  const url = `getTelco/${uuid}`;
  const result = post(url, {});
  return result;
}
export async function verifyClient(payload: any) {
  const url = `verifyClient/${payload}`;
  const result = post(url, {});
  return result;
}
export async function deleteAccount(uuid: string) {
  const url = `deleteTelco/${uuid}`;
  const result = remove(url);
  return result;
}
export async function updateAccount(payload: any) {
  const url = `updateTelco/${payload.uuid}`;
  delete payload.uuid;
  const result = post(url, payload);
  return result;
}
export async function putAccount(payload: any) {
  const url = `putTelco`;
  delete payload.uuid;
  const result = put(url, payload);
  return result;
}
export async function addAccount(payload: any) {
  const url = `addTelco`;
  const result = put(url, payload);
  return result;
}
export async function getTenant(uuid: string) {
  const url = `getTenant/${uuid}`;
  const result = post(url, {});
  return result;
}
export async function deleteTenant(uuid: string) {
  const url = `deleteTenant/${uuid}`;
  const result = remove(url);
  return result;
}
export async function updateTenant(
  payload: any,
  options: any = { skipHeader: false }
) {
  const url = `updateTenant/${payload.uuid}`;
  delete payload.uuid;
  const result = post(url, payload, options);
  return result;
}
export async function putTenant(payload: any) {
  const url = `putTenant`;
  delete payload.uuid;
  const result = put(url, payload);
  return result;
}

export async function getCarriers(payload: any = {}) {
  const url = `getCarriers`;
  const result = post(url, payload);
  return result;
}
export async function putCarriers(payload: any) {
  const url = `putCarrier`;
  delete payload.uuid;
  const result = put(url, payload);
  return result;
}
export async function updateCarrier(payload: any) {
  const url = `updateCarrier/${payload.uuid}`;
  delete payload.uuid;
  const result = post(url, payload);
  return result;
}
export async function deleteCarrier(uuid: string) {
  const url = `deleteCarrier/${uuid}`;
  const result = remove(url);
  return result;
}
export async function getUsers(payload: any = {}) {
  const url = `getUsers`;
  const result = post(url, payload);
  return result;
}
export async function getUserSettings(uuid: string) {
  const url = `getUserSettings/${uuid}`;
  const result = post(url);
  return result;
}
export async function updateUserSettings(uuid: string, payload: any) {
  const url = `updateUserSettings/${uuid}`;
  const result = post(url, payload);
  return result;
}

export async function updateUser(payload: any, uuid: any) {
  const url = `updateUser/${uuid}`;
  const result = post(url, payload);
  return result;
}
export async function getDevices(payload: any) {
  const url = `getDevices`;
  const result = post(url, payload);
  return result;
}
export async function deleteDevice(uuid: string) {
  const url = `deleteDevice/${uuid}`;
  const result = remove(url);
  return result;
}
export async function getFeatures(payload: any) {
  const url = `getUserOptions`;
  const result = post(url, payload);
  return result;
}
export async function getDidList(payload: any) {
  const url = `getDidList`;
  const result = post(url, payload);
  return result;
}
export async function getLiveDid(payload: any) {
  const url = `getLiveDid`;
  const result = post(url, payload);
  return result;
}
export async function getGroups(payload: any) {
  const url = `getGroups`;
  const result = post(url, payload);
  return result;
}
export async function getRoles(payload: any, options: any = {}) {
  const url = `getRoles`;
  const result = post(url, payload, options);
  return result;
}

export async function getRateSheets(payload: any = {}) {
  const url = `getRateSheets`;
  const result = post(url, payload);
  return result;
}
export async function getRates(payload: any) {
  const url = `getRates`;
  const result = post(url, payload);
  return result;
}
export async function deleteRate(uuid: string) {
  const url = `deleteRate/${uuid}`;
  const result = remove(url);
  return result;
}
export async function deleteRateSheet(uuid: string) {
  const url = `deleteRateSheet/${uuid}`;
  const result = remove(url);
  return result;
}

export async function getCountries(payload: any = {}) {
  const url = `getCountries`;
  const result = post(url, payload);
  return result;
}

export async function putRate(payload: any) {
  const url = `putRate`;
  const result = put(url, payload);
  console.log("here");
  return result;
}
export async function updateRate(uuid: string, payload: any) {
  const url = `updateRate/${uuid}`;
  const result = post(url, payload);
  return result;
}

export async function updateRateSheet(uuid: string, payload: any) {
  const url = `updateRateSheet/${uuid}`;
  const result = post(url, payload);
  return result;
}
export async function putRateSheet(payload: any) {
  const url = `putRateSheet`;
  const result = put(url, payload);
  return result;
}

export async function updateDid(payload: any, options: any = {}) {
  const url = `updateDid`;
  const result = post(url, payload, options);
  return result;
}
export async function unassignNumber(payload: any, options: any = {}) {
  const url = `unassignNumber`;
  const result = post(url, payload, options);
  return result;
}

export async function updateUserOption(payload: any, uuid: any) {
  const url = `updateUserOption/${uuid}`;
  const result = post(url, payload);
  return result;
}

export async function getSpareDids(payload: any) {
  const url = `getSpareDids`;
  const result = post(url, payload);
  return result;
}

export async function getPhoneNumber(payload: any) {
  const url = `getDids`;
  const result = post(url, payload);
  return result;
}

export async function getAssignedNumbers(payload: any) {
  const url = `getAssignedNumbers`;
  const result = post(url, payload);
  return result;
}
export async function assignNumber(payload: any) {
  const url = `assignNumber`;
  const result = post(url, payload);
  return result;
}
export async function updateAssignedNumber(payload: any) {
  const url = `updateAssignedNumber/${payload.uuid}`;
  const result = put(url, payload);
  return result;
}
export async function putDid(payload: any, options: any = {}) {
  const url = `putDid`;
  const result = put(url, payload, options);
  return result;
}
export async function buyLiveDid(payload: any) {
  const url = `buyLiveDid`;
  const result = put(url, payload);
  return result;
}
export async function assignList() {
  const url = "getAssignList";
  const result = post(url, {});
  return result;
}

export async function getDidRouting(payload: any = {}, uuid: string = "") {
  const url = `getDidRouting/${uuid}`;
  const result = post(url, payload);
  return result;
}

export async function putDidRouting(payload: any) {
  const url = `putDidRouting`;
  const result = post(url, payload);
  return result;
}

export async function deleteDidRouting(uuid: string) {
  const url = `deleteDidRouting/${uuid}`;
  const result = remove(url);
  return result;
}

export async function updateDidRouting(payload: any) {
  const url = `updateDidRouting/${payload.uuid}`;
  const result = put(url, payload);
  return result;
}

export async function getAudioLibraries(payload: any) {
  const url = `getAudioLibraries`;
  const result = post(url, payload);
  return result;
}

export async function getIvr(payload: any) {
  const url = `getIvrs`;
  const result = post(url, payload);
  return result;
}

export async function putIvr(payload: any) {
  const url = `putIvr`;
  const result = put(url, payload);
  return result;
}
export async function updateIvr(payload: any, uuid: string) {
  const url = `updateIvr/${uuid}`;
  const result = post(url, payload);
  return result;
}
export async function uploadfile(payload: any, options: any = {}) {
  const url = `uploadfile`;
  const result = post(url, payload, options);
  return result;
}
export async function putAudioLibrary(payload: any) {
  const url = `putAudioLibrary`;
  const result = put(url, payload);
  return result;
}

export async function deleteDid(uuid: string) {
  const url = `deleteDid/${uuid}`;
  const result = remove(url);
  return result;
}
export async function updateFallBack(payload: any, uuid: string) {
  const url = `updateIvr/${uuid}`;
  //const id = { "fallthrough": payload?.uuid }
  const result = post(url, payload);
  return result;
}
export async function getVoiceMail(payload: any) {
  const url = `getVoiceMailSettings`;
  const result = post(url, payload);
  return result;
}
export async function UpdateVoiceMail(payload: any, uuid: string) {
  const url = `updateVoiceMailSetting/${uuid}`;
  const result = post(url, payload);
  return result;
}
export async function updateGroup(payload: any, uuid: string) {
  const url = `updateGroup/${uuid}`;
  const result = post(url, payload);
  return result;
}
export async function updateDevice(payload: any, uuid: string) {
  const url = `updateDevice/${uuid}`;
  const result = post(url, payload);
  return result;
}

export async function putGroup(payload: any) {
  const url = `putGroup`;
  const result = put(url, payload);
  return result;
}

export async function deleteGroup(uuid: string) {
  const url = `deleteGroup/${uuid}`;
  const result = remove(url);
  return result;
}
export async function addDevice(payload: any) {
  const url = `putDevice`;
  const result = put(url, payload);
  return result;
}
export async function addUser(payload: any) {
  const url = `putUser`;
  const result = put(url, payload);
  return result;
}

export async function getDid(payload: any) {
  const url = `getDid`;
  const result = post(url, payload);
  return result;
}
export async function deleteIvr(uuid: string) {
  const url = `deleteIvr/${uuid}`;
  const result = remove(url);
  return result;
}
export async function getDeviceMake(payload: any) {
  const url = `getAutoProvisionDeviceMakes`;
  const result = post(url, payload);
  return result;
}
export async function getDeviceModel(payload: any) {
  const url = `getAutoProvisionDeviceModels`;
  const result = post(url, payload);
  return result;
}
export async function getCallLogs(payload: any) {
  const url = `calls`;
  const result = post(url, payload);
  return result;
}
export async function getGroupMembers(payload: any) {
  const url = `getGroupMembers`;
  const result = post(url, payload);
  return result;
}
export async function putGroupMembers(payload: any) {
  const url = `putGroupMembers`;
  const result = put(url, payload);
  return result;
}
export async function deleteMember(payload: any) {
  const url = `deleteGroupMembers`;
  const result = remove(url, payload);
  return result;
}
export async function updateGroupMembers(payload: any) {
  const url = `updateGroupMembers`;
  const result = post(url, payload);
  return result;
}
export async function assignGrpExtension(payload: any) {
  const url = `assignGrpExtension`;
  const result = post(url, payload);
  return result;
}
export async function unassignGrpExtension(payload: any, uuid: string) {
  const url = `unassignGrpExtension/${uuid}`;
  const result = post(url, payload);
  return result;
}

export async function getNewUserExtension(payload: any) {
  console.log("userescxtel", payload);
  const url = `assignUserExtension`;
  const result = post(url, payload);
  return result;
}

export async function unassignUserExtension(payload: any, uuid: string) {
  const url = `unassignUserExtension/${uuid}`;
  const result = post(url, payload);
  return result;
}
export async function getVoiceQueues(payload: any) {
  const url = `getVoiceQueues`;
  const result = post(url, payload);
  return result;
}
export async function putVoiceQueue(payload: any) {
  const url = `putVoiceQueue`;
  const result = put(url, payload);
  return result;
}
export async function updateVoiceQueue(payload: any, uuid: string) {
  const url = `updateVoiceQueue/${uuid}`;
  const result = post(url, payload);
  return result;
}
export async function deleteVoiceQueue(uuid: any) {
  const url = `deleteVoiceQueue/${uuid}`;
  const result = remove(url);
  return result;
}
export async function getVoiceQueueMembers(payload: any) {
  const url = `getVoiceQueueMembers`;
  const result = post(url, payload);
  return result;
}
export async function updateVoiceQueueMember(payload: any, uuid: string) {
  const url = `updateVoiceQueueMember/${uuid}`;
  const result = post(url, payload);
  return result;
}
export async function putVoiceQueueMember(payload: any) {
  const url = `putVoiceQueueMember`;
  const result = post(url, payload);
  return result;
}
export async function assignVoiceQueueExtension(payload: any) {
  const url = `assignVoiceQueueExtension`;
  const result = post(url, payload);
  return result;
}
export async function unassignnVoiceQueueExtension(payload: any, uuid: string) {
  const url = `unassignnVoiceQueueExtension/${uuid}`;
  const result = post(url, payload);
  return result;
}

export async function sendFax(payload: any) {
  const url = `sendFax`;
  const result = post(url, payload);
  return result;
}

export async function getFaxes(payload: any) {
  const url = `getFaxes`;
  const result = post(url, payload);
  return result;
}

export async function getGeneralSettings(payload: any) {
  const url = `getGeneralSettings`;
  const result = post(url, payload);
  return result;
}
export async function updateGeneralSetting(payload: any) {
  const url = `updateGeneralSetting`;
  const result = put(url, payload);
  return result;
}

export async function getFeatureCodes(payload: any) {
  const url = `getFeatureCodes`;
  const result = post(url, payload);
  return result;
}

export async function updateFeatureCodes(payload: any) {
  const url = `updateFeatureCodes`;
  const result = put(url, payload);
  return result;
}

export async function getAvailableFunctionKeys(payload: any) {
  const url = `getAvailableFunctionKeys`;
  const result = post(url, payload);
  return result;
}

//

export async function getNotificationSettings(payload: any) {
  const url = `getNotificationSettings`;
  const result = post(url, payload);
  return result;
}

export async function updateNotificationSetting(payload: any) {
  const url = `updateNotificationSetting`;
  const result = put(url, payload);
  return result;
}
export async function getBlockedNumbers(payload: any) {
  const url = `getBlockedNumbers`;
  const result = post(url, payload);
  return result;
}

export async function putBlockedNumber(payload: any) {
  const url = `putBlockedNumber`;
  const result = post(url, payload);
  return result;
}

export async function deleteBlockedNumber(uuid: string) {
  const url = `deleteBlockedNumber/${uuid}`;
  const result = remove(url);
  return result;
}

export async function getRecordings(payload: any) {
  const url = `getRecordings`;
  const result = post(url, payload);
  return result;
}

export async function deleteRecording(uuid: string) {
  const url = `deleteRecording/${uuid}`;
  const result = remove(url);
  return result;
}

export async function getSpareDevices(payload: any) {
  const url = `getSpareDevices`;
  const result = post(url, payload);
  return result;
}

export async function getVoicemails(payload: any) {
  const url = `getVoicemails`;
  const result = post(url, payload);
  return result;
}

export async function addAutoProvisionBasic(payload: any) {
  const url = `putAutoProvisionBasic`;
  delete payload.uuid;
  const result = post(url, payload);
  return result;
}

export async function getAutoProvisionBasic(payload: any) {
  const url = `getAutoProvisionBasic`;
  delete payload.uuid;
  const result = post(url, payload);
  return result;
}
export async function updateAutoProvisionBasic(payload: any, uuid: string) {
  const url = `updateAutoProvisionBasic/${uuid}`;
  const result = put(url, payload);
  return result;
}

export async function deleteAutoProvision(payload: any, uuid: string) {
  const url = `deleteAutoProvision/${uuid}`;
  delete payload.uuid;
  const result = remove(url);
  return result;
}

export async function getAutoProvisionPreferences(payload: any) {
  const url = `getAutoProvisionPreferences`;
  const result = post(url, payload);
  return result;
}

export async function updateAutoProvisionPreference(
  payload: any,
  uuid: string
) {
  const url = `updateAutoProvisionPreference/${uuid}`;
  const result = put(url, payload);
  console.log("here");
  return result;
}

export async function getsms(payload: any = {}) {
  const url = `getSms`;
  const result = post(url, payload);
  return result;
}

export async function sendsms(payload: any = {}) {
  const url = `sendSms`;
  const result = post(url, payload);
  return result;
}

export async function getSmsSenders(payload: any = {}) {
  const url = `getSmsSenders`;
  const result = post(url, payload);
  return result;
}

export async function getFunctionKeys(payload: any) {
  const url = `getFunctionKeys`;
  const result = post(url, payload);
  return result;
}

export async function AddFunctionKeys(payload: any) {
  const url = `putFunctionKeys`;
  const result = post(url, payload);
  return result;
}

export async function updateFunctionKeys(payload: any) {
  const url = `updateFunctionKeys`;
  const result = put(url, payload);
  return result;
}

export async function restartPhone(payload: any) {
  const url = `restartPhone`;
  const result = post(url, payload);
  return result;
}

export async function putPhonebook(payload: any) {
  const url = `putPhonebook`;
  const result = put(url, payload);
  return result;
}

export async function getPhonebook() {
  const url = "getPhonebook";
  const result = post(url, {});
  return result;
}

export async function getaddressbookDetails(payload: any) {
  const url = `getaddressbookDetails`;
  const result = post(url, payload);
  return result;
}

export async function putPhoneBookDetails(payload: any) {
  const url = `putPhoneBookDetails`;
  const result = put(url, payload);
  return result;
}

export async function updatePhoneBookDetails(uuid: string, payload: any) {
  const url = `updatePhoneBookDetails/${uuid}`;
  const result = post(url, payload);
  return result;
}

export async function deletePhoneBookDetails(uuid: string, payload: any) {
  const url = `deletePhoneBookDetails/${uuid}`;
  const result = remove(url, payload);
  return result;
}

export async function getSpeedDial() {
  const url = "getSpeedDial";
  const result = post(url, {});
  return result;
}

export async function putSpeedDial(payload: any) {
  const url = `putSpeedDial`;
  const result = put(url, payload);
  return result;
}

export async function updateSpeedDial(uuid: string, payload: any) {
  const url = `updateSpeedDial/${uuid}`;
  const result = post(url, payload);
  return result;
}

export async function deleteSpeedDial(uuid: string, payload: any) {
  const url = `deleteSpeedDial/${uuid}`;
  const result = remove(url, payload);
  return result;
}

export async function UploadPhonebookDetails(uuid: string, payload: any) {
  const url = `UploadPhonebookDetails/${uuid}`;
  const result = post(url, payload);
  return result;
}

export async function getCompanyContacts() {
  const url = "getCompanyContacts";
  const result = post(url, {});
  return result;
}
