import { NumberManagerActionType } from "../action-types";

export const setUpdateList = (data: boolean) => {
    return {
        type: NumberManagerActionType.SET_UPDATE_LIST,
        payload: data
    }
}

export const setTelcoData = (data: boolean) => {
    return {
        type: NumberManagerActionType.SET_TELCO_DATA,
        payload: data
    }
}
export const setTelcoId = (data: any) => {
    return {
        type: NumberManagerActionType.SET_TELCO_ID,
        payload: data
    }
}
export const setClientData = (data: any) => {
    return {
        type: NumberManagerActionType.SET_CLIENT_DATA,
        payload: data
    }
}