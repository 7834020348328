import { ActionType } from "../action-types";

export const setUserRoles = (payload: any) => {
    return {
        type: ActionType.SET_USER_ROLE,
        payload: payload
    }
}
export const setUser = (user: any) => {
    return {
        type: ActionType.SET_USER,
        payload: user
    }
}
export const SetAccountActiveStep = (number: number) => {
    return {
        type: ActionType.SET_ACCOUNT_ACTIVE_STATE,
        payload: number
    }
}
export const SetAuthState = (boolean: number) => {
    return {
        type: ActionType.SET_AUTH_STATE,
        payload: boolean
    }
}