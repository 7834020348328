import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { Store } from 'react-notifications-component';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import regEx from '../../config/regex';



interface ThemedButtonType {
    children: any
    theme: "primary" | "secondary" | "error"
    icon?: "save" | "update" | "cancel" | "custom" | "new" | "remove" | "unassign" | "download" | "upload" | "null" | "disable" | "distribute"
    rest?: any
    iconComponent?: JSX.Element
    loading?: true | false
    disabled?: true | false
    valiData?: any
    change?: any
    classname?: any
    click?: any
}
const Icons: any = {
    save: <SaveIcon />,
    cancel: <CloseIcon />,
    update: <UpdateIcon />,
    remove: <DeleteIcon />,
    new: <AddIcon />,
    unassign: <RemoveCircleOutlineIcon />,
    download: <DownloadOutlinedIcon />,
    upload: <FileUploadOutlinedIcon />,
    disable: <NotInterestedOutlinedIcon />,
    distribute: <ClearAllOutlinedIcon />
}
const ThemedButton = ({ children, theme, valiData = [], change, iconComponent, click, icon = 'null', loading = false, disabled = false, classname, ...rest }: ThemedButtonType) => {
    const handleClick = () => {
        console.log("changelogs", valiData)
        if (valiData) {
            let valiState = true;
            let msg = ''
            let dummy = [...valiData];
            let ObjectData: any = {};
            dummy.forEach((node: any) => {
                let regExTest = false;
                // debugger
                if (node?.regEx) {
                    let res = node.regEx;
                    regExTest = !res.test(node.value)
                } else {
                    if (node?.type === "password") {
                        let res = regEx.password;
                        regExTest = !res.test(node.value)
                    } else if (node?.type === 'email') {
                        let res = regEx.email;
                        regExTest = !res.test(node.value)
                    }
                }
                // eslint-disable-next-line no-mixed-operators
                if ((node?.textValidation && node?.value?.length !== 0 && node?.value?.length < node?.minLength) || node.required === true && ((node?.value?.length < node?.minLength)) && (node.value !== '' || regExTest || (Array.isArray(node.value) && node.value.length !== 0)) && (!node.hidden || node.hidden === false) && (!node?.disabled || node.disabled === false)) {
                    if (valiState) {
                        msg = `Minimum ${node?.minLength} Characters Required`
                        valiState = false;
                        node.error = true
                    }
                }
                if (node.required === true && (node.value === '' || regExTest || (Array.isArray(node.value) && node.value.length === 0)) && (!node.hidden || node.hidden === false) && (!node?.disabled || node.disabled === false)) {
                    if (valiState) {
                        msg = msg + ' ' + node?.errormessage ? node.errormessage : node.title;
                    }

                    console.log("regExTest", regExTest, node.type)
                    if (node.type === 'password' && regExTest) {
                        node.showHint = true;
                    }
                    valiState = false;
                    node.error = true
                } else {
                    node.error = false
                }
                // Fix for adding extension while creating group 
                if (node.name === "extensions_l") {
                    let validExtensionFlag = node?.value?.some((item: string) => {
                        return !node?.regEx?.test(item)
                    })
                    let emptyCheck = node?.value?.length === 0 ? true : false
                    console.log("faalseCheck", validExtensionFlag, emptyCheck)
                    if (emptyCheck) {
                        node.error = true
                        valiState = false
                    } else if (!validExtensionFlag) {
                        node.error = false
                        valiState = true
                    }
                }
                // end of fix
                if (node?.result?.key && Array.isArray(node.value)) {
                    let val: any = [];
                    node.value.forEach((n: any) => {
                        if (n[node?.result.key]) {
                            val.push(n[node?.result.key])
                        } else {
                            val.push(n)
                        }
                    })
                    ObjectData[node.name] = val
                } else if (node?.result?.key && node.value[node?.result?.key]) {
                    ObjectData[node.name] = node.value[node?.result?.key]
                } else {
                    ObjectData[node.name] = node.value
                }
                change([...dummy]);

                if (node?.type === "phonenumber" && node?.value === "+") {
                    valiState = false
                    msg = msg + ' ' + node?.errormessage ? node.errormessage : node.title;

                }
            })

            // Controlling Main Click 
            if (valiState) {
                console.log("msg", valiState)
                click(ObjectData);
            } else {
                Store.addNotification({
                    title: "Validation Error",
                    message: msg,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: false
                    }
                });
            }

        } else {
            click();
        }
    }
    const { t } = useTranslation()
    let startIcon = loading ? (
        <CircularProgress color={"success"} size={20} />
    ) : icon === "custom" ? (
        iconComponent
    ) : (
        Icons[icon] || ""
    );
    return (
        <LoadingButton
            disabled={disabled || loading}
            startIcon={startIcon}
            onClick={() => handleClick()}
            className={classname}
            color={disabled ? "info" : theme}
            {...rest}
        >
            {t(children)}
        </LoadingButton>
    );

}
export default ThemedButton;