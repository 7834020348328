import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { getChildrens, getRoles } from '../../../../services/api';
import { useEffect, useState } from 'react';
import ReccuringTree from './recurringTree';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { actionCreators } from '../../../../store'
import { t } from 'i18next';
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../../../containers/loader"


const TenantTree = (props: any) => {

    const { setShowModal, user, setActiveAccount, activeAccount } = props;
    const [data, setData] = useState<any>([])
    const [searchData, setSearchData] = useState({ search: "", loader: false });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleTenantData = (payload: any) => {
        getChildrens(payload, true).then((result: any) => {
            setData([...result])
        }).catch((error: any) => {
        })
    }

    const hanldeTreeClick = (node: any, mode: string = '') => {
        console.log("nodenodenodenode", node)
        let payload = { name: `${node.type}_admin` }
        setActiveAccount(node?.account_type === "super_telco" ? node.azp : node.display_name ? node.display_name : node.name)
        if (node?.email === "voifinity@netstratum.com") {
            payload = { name: `super_admin` }
        }
        localStorage.setItem("currentAccount", JSON.stringify(node));
        getRoles(payload).then((result) => {
            dispatch(actionCreators.setUserRoles(result))
        })
        navigate(`/app/home`, { replace: true });
        setShowModal(false)
    }
    useEffect(() => {
        handleTenantData({})
    }, [])

    const handleSearchChange = (value: string) => {
        let copySearchData = { ...searchData }
        copySearchData.search = value
        setSearchData(copySearchData)


    }

    const onHandleClose = () => {
        let copySearchData = { ...searchData }
        copySearchData.search = ""
        setSearchData(copySearchData)
    }
    return <div className="bg-primary-alpha-5 bg-opacity-100  backdrop-blur fixed inset-0 z-50">
        <div className="w-full h-full">
            <FadeIn >
                <div className="absolute  top-0 right-0   mx-auto max-w-3xl w-[440px] h-[100vh] bg-ternary text-ternary-text border-0 rounded-sm shadow-lg">
                    <button
                        className="p-1 ml-auto mr-5 border-0 text-[#000000] float-right text-3xl leading-none mt-4"
                        onClick={() => setShowModal(false)}
                    >
                        <svg width="13" height="13" viewBox="0 0 10 10" fill="none">
                            <path
                                d="M0.803125 9.98672L0.015625 9.19922L4.21562 4.99922L0.015625 0.799219L0.803125 0.0117188L5.00313 4.21172L9.20312 0.0117188L9.99062 0.799219L5.79063 4.99922L9.99062 9.19922L9.20312 9.98672L5.00313 5.78672L0.803125 9.98672Z"
                                fill="#A7A9AB"
                            />
                        </svg>
                    </button>
                    <FadeIn>
                        <div className="flex items-center justify-between px-5 py-3 rounded-t">
                            Accounts List
                            <div className="relative flex w-2/3 flex-wrap items-stretch">
                                <input
                                    type="text"
                                    onChange={(e) => handleSearchChange(e.target.value)}
                                    // onKeyDown={(e) => onHandleSearchResult(e)}
                                    value={searchData.search}
                                    autoFocus={true}
                                    className="relative m-0 block w-[1px] min-w-0 flex-auto rounded rounded-r-none  bg-clip-padding px-3 py-[0.25rem]  leading-[1.6]  outline-none transition duration-200 ease-in-out focus:z-[3] "
                                    placeholder={t("Search")}
                                />
                                {searchData.loader ? (
                                    <span className="input-group-text flex items-center  transition duration-200  whitespace-nowrap bg-[white] rounded rounded-l-none px-0 py-1 text-center  text-base  font-normal text-secondary">
                                        <Loader size="sm" />
                                    </span>
                                ) : searchData.search === "" ? (
                                    <span className="input-group-text flex items-center  transition duration-200  whitespace-nowrap rounded rounded-l-none px-2 py-1 text-center  text-base bg-primary-text font-normal text-primary-alpha-40">
                                        <SearchIcon />
                                    </span>
                                ) : (
                                    <span
                                        onClick={() => onHandleClose()}
                                        className="input-group-text flex items-center cursor-pointer transition duration-200  whitespace-nowrap rounded rounded-l-none px-1 py-0 text-center  text-base bg-[white] font-normal text-primary-alpha-40"
                                    >
                                        <CloseIcon sx={{ height: "20px", width: "20px" }} />
                                    </span>
                                )}
                            </div>
                        </div>
                    </FadeIn>
                    <hr />
                    <div className="pt-2 relative pl-4 overflow-y-auto h-[calc(100vh-48px)]">
                        <div onClick={() => hanldeTreeClick(user, "root")} className={`${activeAccount === user.azp ? 'text-primary' : 'hover:text-primary'} py-1 pb-2 capitalize cursor-pointer  border-b  border-dotted border-primary-alpha-20 `}>
                            <div className='absolute right-4'>{activeAccount === user.azp ? "Active" : ''}</div>
                            <AdminPanelSettingsIcon /> {user.azp}
                        </div>
                        {data?.filter((node: any, index: number) => (node?.display_name && node?.display_name.trim().toLowerCase().includes(searchData?.search)) || (node?.name && node?.name.trim().toLowerCase().includes(searchData?.search)) || node?.childs?.filter((child: any) => child?.display_name?.trim().toLowerCase().includes(searchData?.search) || child?.name?.trim().toLowerCase().includes(searchData?.search))?.length !== 0).map((node: any, index: number) => {
                            console.log("nodeokokokko", node)
                            return <div key={node.uuid} className="pt-2 pl-8">
                                <div onClick={() => hanldeTreeClick(node)} className={`${activeAccount === node.name ? 'text-primary' : 'hover:text-primary'} py-1 pb-2 capitalize cursor-pointer  border-b  border-dotted border-primary-alpha-20`}>
                                    <div className='absolute text-primary right-4'>{activeAccount.toLowerCase() === node.name.toLowerCase() ? "Active" : ''}</div>
                                    {node.type === 'telco' ? <AdminPanelSettingsIcon /> : <ManageAccountsIcon />} {node.display_name ? node.display_name : node.name}
                                </div>
                                {node?.childs && <ReccuringTree activeAccount={activeAccount} hanldeTreeClick={hanldeTreeClick} node={node?.childs?.filter((child: any) => child?.display_name?.toLowerCase().includes(searchData?.search) || child?.name?.toLowerCase().includes(searchData?.search))} />}
                            </div>
                        })}
                    </div>
                </div>
            </FadeIn>
        </div>
    </div>

}
export default TenantTree;